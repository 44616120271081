import { IThemeContext, ThemeContext } from '@components/Theme'
import { AppTheme } from '@components/Theme/createTheme'
import { useTheme as useMuiTheme } from '@mui/material'
import { useContext } from 'react'

function useTheme(): IThemeContext & AppTheme {
  const muiTheme = useMuiTheme<AppTheme>()
  const appTheme = useContext(ThemeContext)
  return {
    ...muiTheme,
    ...appTheme,
  }
}

export default useTheme
