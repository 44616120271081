import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

const MenuCustomerIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    name="MenuCustomerIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5007 6.6875C14.5007 8.17177 13.2975 9.375 11.8132 9.375C10.329 9.375 9.12573 8.17177 9.12573 6.6875C9.12573 5.20323 10.329 4 11.8132 4C13.2975 4 14.5007 5.20323 14.5007 6.6875ZM11.8132 11.375C14.4021 11.375 16.5007 9.27633 16.5007 6.6875C16.5007 4.09867 14.4021 2 11.8132 2C9.2244 2 7.12573 4.09867 7.12573 6.6875C7.12573 9.27633 9.2244 11.375 11.8132 11.375ZM4.00267 19.7447C4.39159 17.0222 6.72322 15 9.47335 15H14.153C16.9031 15 19.2348 17.0222 19.6237 19.7447C19.643 19.8794 19.5384 20 19.4023 20H4.22408C4.08797 20 3.98342 19.8794 4.00267 19.7447ZM9.47335 13C5.72792 13 2.55245 15.7541 2.02277 19.4619C1.83139 20.8015 2.87087 22 4.22408 22H19.4023C20.7555 22 21.795 20.8015 21.6036 19.4619C21.0739 15.7541 17.8984 13 14.153 13H9.47335Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default MenuCustomerIcon
