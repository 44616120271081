import React from 'react'

import * as S from './styles'

export interface SkeletonProps {
  color?: 'default' | 'inverted' | 'primary'
  style?: React.CSSProperties
}

const Skeleton: React.FC<SkeletonProps> = ({ color, style }) => (
  <S.Skeleton color={color} style={style}>
    &zwnj;
  </S.Skeleton>
)

Skeleton.displayName = 'Skeleton'
export default Skeleton
