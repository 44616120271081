import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  fromPromise,
  NormalizedCacheObject,
  Observable,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import {
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
} from '@dolpheen/apollo'
import { shouldPersistToken } from '@dolpheen/auth/hooks/useAuthProvider'
import { tokenRefreshMutation } from '@dolpheen/auth/mutations'

import { shouldRefreshJwtToken } from './errors'
import { getTokens, removeTokens, setAuthToken } from './utils'

function headersWithAuthorization(
  otherHeaders: Record<string, string>,
  token?: string,
) {
  return {
    ...otherHeaders,
    'Apollo-Require-Preflight': 'true',
    authorization: token ? `Bearer ${token}` : null,
  }
}

const tokenLink = setContext((_, context) => {
  const authToken = getTokens().auth

  return {
    ...context,
    headers: headersWithAuthorization(context.header, authToken ?? undefined),
  }
})

export default (refreshClient: ApolloClient<NormalizedCacheObject>) => {
  let newAccessTokenObservable: Observable<string> | null = null

  function refreshToken(refreshToken: string): Observable<string> {
    if (!newAccessTokenObservable) {
      newAccessTokenObservable = fromPromise(
        refreshClient
          .mutate<RefreshTokenMutation, RefreshTokenMutationVariables>({
            mutation: tokenRefreshMutation,
            variables: {
              token: refreshToken,
            },
          })
          .then((result: FetchResult<RefreshTokenMutation>) => {
            const accessToken = result.data!.tokenRefresh.token!
            setAuthToken(accessToken, shouldPersistToken)
            newAccessTokenObservable = null
            return accessToken
          })
          .catch((error) => {
            removeTokens()
            return error
          }),
      )
    }

    return newAccessTokenObservable
  }

  const tokenRefreshLink = onError(({ graphQLErrors, operation, forward }) => {
    if (shouldRefreshJwtToken({ graphQLErrors }) && getTokens().refresh) {
      return refreshToken(getTokens().refresh!).flatMap(
        (accessToken: string) => {
          const variableName = operation.getContext().tokenInVariable
          if (variableName) {
            operation.variables[variableName] = accessToken
          } else {
            const oldHeaders = operation.getContext().headers
            operation.setContext({
              headers: headersWithAuthorization(oldHeaders, accessToken),
            })
          }

          return forward(operation)
        },
      )
    }
  })

  return ApolloLink.from([tokenLink, tokenRefreshLink])
}
