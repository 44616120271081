import { useAppHeaderAnchor } from '@components/AppLayout/AppHeaderContext'
import { Portal } from '@mui/material'
import { isNil } from 'lodash-es'
import React from 'react'

import ExtendedPageHeader from '../ExtendedPageHeader'
import Skeleton from '../Skeleton'
import * as S from './styles'

export interface PageHeaderProps {
  inline?: boolean
  className?: string
  title?: React.ReactNode
}

const PageHeader: React.FC<PageHeaderProps> = ({
  inline,
  title,
  className,
}) => {
  const anchor = useAppHeaderAnchor()

  if (!anchor) {
    return null
  }

  return (
    <Portal container={anchor.current}>
      <ExtendedPageHeader
        inline={inline}
        className={className}
        title={
          <S.Title variant="h3">
            {!isNil(title) ? title : <Skeleton style={{ width: '10em' }} />}
          </S.Title>
        }
      />
    </Portal>
  )
}

PageHeader.displayName = 'PageHeader'
export default PageHeader
