import { ApolloProvider } from '@apollo/client'
import { LocaleProvider } from '@components/Locale'
import MessageManagerProvider from '@components/messages'
import ThemeProvider from '@components/Theme'
import { apolloClient } from '@dolpheen/apollo'
import AppStateProvider from '@dolpheen/containers/AppState'
import errorTracker from '@utils/errorTracking'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'

import AppRoutes from './AppRoutes'
import AuthProvider from './auth/AuthProvider'

errorTracker.init()

const App: React.FC = () => (
  <HelmetProvider>
    <ApolloProvider client={apolloClient}>
      <ThemeProvider>
        <LocaleProvider>
          <MessageManagerProvider>
            <AppStateProvider>
              <AuthProvider>
                <AppRoutes />
              </AuthProvider>
            </AppStateProvider>
          </MessageManagerProvider>
        </LocaleProvider>
      </ThemeProvider>
    </ApolloProvider>
  </HelmetProvider>
)

export default App
