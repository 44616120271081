import { UserFragment } from '@dolpheen/apollo'

export function formatUserName(
  user?: Pick<UserFragment, 'email' | 'firstName' | 'lastName'>,
  returnEmail?: boolean,
) {
  return user && (user.email || (user.firstName && user.lastName))
    ? user.firstName && user.lastName
      ? [user.firstName, user.lastName].join(' ')
      : returnEmail
      ? user.email
      : user.email.split('@')[0]
    : undefined
}

export function formatUserInitials(
  user?: Pick<UserFragment, 'email' | 'firstName' | 'lastName'>,
) {
  return user && (user.email || (user.firstName && user.lastName))
    ? (user.firstName && user.lastName
        ? user.firstName[0] + user.lastName[0]
        : user.email.slice(0, 2)
      ).toUpperCase()
    : undefined
}
