import { ThemeOptions } from '@mui/material/styles'

function getInputBoxShadow(color: string) {
  return `0 0 0 3px ${color}`
}

import { ThemeColors } from '../types'

export const inputComponents = (
  colors: ThemeColors,
): ThemeOptions['components'] => ({
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          padding: 0,
        },
        '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
          padding: '23px 12px 10px 12px',
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        '&.Mui-error': {
          color: colors.fail.dark,
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      input: {
        '&:-webkit-autofill': {
          WebkitTextFillColor: colors.main[1],
        },
        '&::placeholder': {
          opacity: '1 !important' as any,
        },
        color: colors.main[1],
      },
      underline: {
        '&:after': {
          borderBottomColor: colors.active[1],
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        '&.Mui-disabled': {
          color: colors.disabled,
        },
        '&::placeholder': {
          color: colors.main[3],
          opacity: '1 !important' as any,
        },
        borderRadius: '4px',
      },
      root: {
        borderRadius: '4px',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      formControl: {
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left',
        width: '100%',
      },
      outlined: {
        '&.MuiInputLabel-shrink': {
          transform: 'translate(12px, 9px) scale(0.75)',
        },
        transform: 'translate(14px, 18px) scale(1)',
      },
      root: {
        '&&.Mui-disabled': {
          color: colors.main[4],
        },
        '&&.Mui-focused': {
          '&:not(.Mui-error)': {
            color: colors.active[1],
          },
        },
        '&.Mui-error': {
          '&.Mui-focused': {
            color: colors.fail.dark,
          },
          color: colors.fail.dark,
        },
        '&:not(.Mui-error):hover label': {
          color: colors.main[4],
        },
        color: colors.main[3],
      },
      shrink: {
        // Negates x0.75 scale
        transition: 'width 200ms',
        width: '133%',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        '&&.Mui-disabled': {
          backgroundColor: undefined,
        },
        '&:-webkit-autofill': {
          borderRadius: 4,
          boxShadow: '0 0 0px 1000px rgba(19, 190, 187, 0.1) inset',
        },
        color: colors.main[1],
        padding: '23px 12px 10px 12px',
      },
      inputMultiline: {
        left: -2,
        padding: '10px 0',
        position: 'relative',
      },
      multiline: {
        '&.Mui-disabled': {
          background: colors.background.default,
        },
      },
      notchedOutline: {
        // It's so much easier to put it here with important tag rather than
        // override in multiple places using cascade composition
        borderWidth: '1px !important',
      },
      root: {
        '& fieldset': {
          top: 0,
        },
        '& legend': {
          display: 'none',
        },
        '&.Mui-disabled': {
          '& fieldset': {
            borderColor: `${colors.disabled} !important`,
          },
          '& input, & textarea': {
            backgroundColor: colors.background.default,
            color: colors.main[3],
          },
          backgroundColor: colors.background.default,
          boxShadow: '0 0 0 0 transparent !important',
        },
        '&.Mui-error': {
          '&.Mui-focused': {
            '& fieldset': {
              borderColor: colors.fail.dark,
            },
            '&:hover': {
              boxShadow: getInputBoxShadow(colors.fail.mid),
            },
            boxShadow: getInputBoxShadow(colors.fail.mid),
          },
          '&:hover': {
            '&& fieldset': {
              borderColor: colors.fail.dark,
            },
            boxShadow: getInputBoxShadow(colors.fail.light),
          },
          boxShadow: getInputBoxShadow(colors.fail.light),
        },
        '&.Mui-focused': {
          '& input': {
            '& fieldset': {
              borderColor: colors.active[1],
            },
            '&::placeholder': {
              opacity: [[1], '!important'] as any,
            },
            color: colors.main[1],
          },
          '&, &:hover': {
            boxShadow: getInputBoxShadow(colors.active[3]),
          },
        },
        '&:hover': {
          '& input': {
            color: colors.main[1],
          },
          '&&&': {
            '& fieldset': {
              borderColor: colors.active[1],
            },
          },
          boxShadow: getInputBoxShadow(colors.active[5]),
        },
        fontWeight: 400,
        top: 0,
        transition: 'box-shadow 200ms',
      },
    },
  },
})
