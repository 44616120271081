import React, { PropsWithChildren } from 'react'

import * as S from './styles'

interface ContainerProps {
  className?: string
}

export const Container: React.FC<PropsWithChildren<ContainerProps>> = (
  props,
) => <S.Root {...props} />

Container.displayName = 'Container'
export default Container
