import { AppActionProvider } from '@components/AppLayout/AppActionContext'
import { AppHeaderProvider } from '@components/AppLayout/AppHeaderContext'
import { ThemeType } from '@components/Theme/createTheme'
import { changeColorMeta } from '@components/Theme/createTheme/utils'
import {
  ExtensionMessageType,
  sendMessageToExtension,
  ThemeChangeMessage,
} from '@components/Theme/extensions'
import useLocalStorage from '@hooks/useLocalStorage'
import { ThemeProvider as MuiThemeProvider } from '@mui/material'
import React, { PropsWithChildren, useEffect } from 'react'

import { Baseline } from './Baseline'
import { createTheme } from './createTheme'
import { dark, light } from './themePallete'

export interface IThemeContext {
  themeType: ThemeType
  setTheme: (theme: ThemeType) => void
}
export const ThemeContext = React.createContext<IThemeContext>({
  setTheme: () => undefined,
  themeType: 'light',
})

interface ThemeProviderProps {
  defaultTheme?: ThemeType
}

const ThemeProvider: React.FC<PropsWithChildren<ThemeProviderProps>> = ({
  children,
  defaultTheme = 'light',
}) => {
  const [themeTypeName, setThemeType] = useLocalStorage('theme', defaultTheme)
  const themeType = themeTypeName as ThemeType
  const themes = {
    dark,
    light,
  }
  const theme = createTheme(themes[themeType])
  const sendThemeToExtension = () =>
    sendMessageToExtension<ThemeChangeMessage>(
      {
        theme: themeType,
        type: ExtensionMessageType.THEME,
      },
      '*',
    )

  useEffect(() => {
    sendThemeToExtension()
    changeColorMeta(theme.palette.background.default)
  }, [themeType])

  return (
    <ThemeContext.Provider
      value={{
        setTheme: setThemeType,
        themeType,
      }}
    >
      <MuiThemeProvider theme={theme}>
        <AppActionProvider>
          <AppHeaderProvider>
            <Baseline />
            {children}
          </AppHeaderProvider>
        </AppActionProvider>
      </MuiThemeProvider>
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
