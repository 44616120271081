import React from 'react'

import * as S from './styles'

interface ExtendedPageHeaderProps {
  inline?: boolean
  className?: string
  title?: React.ReactNode
}

const ExtendedPageHeader: React.FC<ExtendedPageHeaderProps> = ({
  inline,
  title,
  className,
}) => (
  <S.Root isBlock={!inline} className={className}>
    {title}
  </S.Root>
)

ExtendedPageHeader.displayName = 'ExtendedPageHeader'
export default ExtendedPageHeader
