import { AppPaletteOptions, ThemeColors } from './types'

export const createPalette = (colors: ThemeColors): AppPaletteOptions => ({
  action: {
    active: colors.checkbox.default,
  },
  alert: colors.alert,
  app: colors,
  background: colors.background,
  divider: colors.divider,
  error: {
    main: colors.errorAction[1],
  },
  mode: colors.theme,
  primary: {
    contrastText: '#ffffff',
    dark: colors.main[4],
    main: colors.active[1],
  },
  secondary: {
    contrastText: '#ffffff',
    main: colors.background.paper,
  },
  success: {
    main: colors.success.mid,
  },
  text: {
    disabled: colors.main[4],
    primary: colors.main[1],
    secondary: colors.main[3],
  },
  textHighlighted: {
    active: colors.active[1],
    inactive: colors.highlightInactive.default,
  },
  textHint: colors.main[3],
})
