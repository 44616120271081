import useAppLoader from '@hooks/useAppLoader'
import React from 'react'

const AppLoader: React.FC<unknown> = () => {
  useAppLoader()
  return null
}

AppLoader.displayName = 'AppLoader'
export default AppLoader
