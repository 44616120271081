import { Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

import * as S from './styles'

interface ContactOptionProps {
  label: string
  note: string | null
  isDefault: boolean
}

const ContactOption: React.FC<ContactOptionProps> = ({
  label,
  note,
  isDefault,
}) => {
  const intl = useIntl()
  const captions = [
    note,
    isDefault
      ? intl.formatMessage({
          defaultMessage: 'default',
          description: 'contact option default',
        })
      : null,
  ].filter((i) => !!i)

  return (
    <div>
      <Typography variant="body1" component="span">
        {label}
      </Typography>
      {captions.length ? (
        <S.Caption variant="caption">({captions.join(', ')})</S.Caption>
      ) : null}
    </div>
  )
}

ContactOption.displayName = 'ContactOption'
export default ContactOption
