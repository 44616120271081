import { createContext, ReactNode } from 'react'

export type NotificationType = 'error' | 'info' | 'success' | 'warning'
export interface IMessage {
  actionBtn?: {
    label: string
    action: () => void
  }
  autohide?: number
  expandText?: string
  title?: string
  text?: React.ReactNode
  onUndo?: () => void
  status?: NotificationType
  apiMessage?: string
}

export interface INotification {
  id: number
  message: IMessage
  timeout: number
  close: () => void
}

export interface ITimer {
  id: number
  notification: INotification
  remaining: number
  start: number
  timeoutId: number
}

export interface INotificationContext {
  show: (message: IMessage, timeout?: number | null) => void
  remove: (notificationId: number) => void
  clearErrorNotifications: () => void
}

export interface NotificationAction {
  label: string
  onClick: () => void
}

export interface ApiMessageData {
  apiMessageContent: ReactNode
  showApiLabel: ReactNode
  hideApiLabel: ReactNode
}

export interface NotificationData {
  content?: ReactNode
  title: string | undefined
  action?: NotificationAction
  type: NotificationType
  apiMessage?: ApiMessageData
}

export interface NotificationProps extends NotificationData {
  className?: string
  onClose: () => void
}
export type IMessageContext = (message: IMessage) => void
export const MessageContext = createContext<INotificationContext | null>(null)

export * from './MessageManagerProvider'
export { default } from './MessageManagerProvider'
