import { styled } from '@mui/material'

export const Root = styled('div', {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'isBlock',
})<{ isBlock?: boolean }>(({ theme, isBlock }) => ({
  alignItems: 'center',
  display: 'flex',
  gridArea: 'headerContent',

  ...(isBlock
    ? {
        [theme.breakpoints.down('sm')]: {
          '&&': {
            display: 'block',
          },
        },
      }
    : {}),
}))
