import { SentryAdapter } from './adapters'
import { ErrorTrackerFactory } from './trackerFactory'

const errorTracker = ErrorTrackerFactory(
  SentryAdapter({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENVIRONMENT,
  }),
)

export default errorTracker
