import { CardProps } from '@mui/material/Card'
import React from 'react'

import * as S from './styles'

export interface NavigationCardBaseProps extends Omit<CardProps, 'classes'> {
  classes?: Record<'root' | 'content', string>
}

export const NavigationCardBase: React.FC<NavigationCardBaseProps> = ({
  children,
  ...rest
}) => (
  <S.Card {...rest}>
    <S.CardContent>{children}</S.CardContent>
  </S.Card>
)
