import { styled } from '@mui/material'

export const Skeleton = styled('span', {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'primary',
})<{ color?: 'default' | 'primary' | 'inverted' }>(({ theme, color }) => ({
  '@keyframes skeleton-animation': {
    '0%': {
      opacity: 0.6,
    },
    '100%': {
      opacity: 1,
    },
  },
  animation: 'skeleton-animation .75s linear infinite forwards alternate',
  background:
    color === 'primary'
      ? theme.palette.primary.main
      : color === 'inverted'
      ? theme.palette.background.default
      : theme.palette.background.paper,
  borderRadius: 4,
  display: 'block',
  height: '0.8em',
  margin: '0.2em 0',
}))
