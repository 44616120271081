import CheckboxIcon from '@icons/Checkbox'
import CheckboxCheckedIcon from '@icons/CheckboxChecked'
import CheckboxIndeterminateIcon from '@icons/CheckboxIndeterminate'
import RadioCheckedIcon from '@icons/RadioCheckedIcon'
import RadioIcon from '@icons/RadioIcon'
import type { ThemeOptions } from '@mui/material/styles'
import { createElement } from 'react'

import { ThemeColors } from '../types'

export const controlComponents = (
  colors: ThemeColors,
): ThemeOptions['components'] => ({
  MuiCheckbox: {
    defaultProps: {
      checkedIcon: createElement(CheckboxCheckedIcon),
      color: 'primary',
      disableRipple: true,
      icon: createElement(CheckboxIcon),
      indeterminateIcon: createElement(CheckboxIndeterminateIcon),
    },
    styleOverrides: {
      colorPrimary: {
        '&&.Mui-checked:hover': {
          backgroundColor: 'transparent',
        },
      },
      indeterminate: {
        color: colors.active[1],
      },
      root: {
        '& > span': {
          '&::before': {
            background: colors.active[5],
            borderRadius: 2,
            content: "''",
            height: 20,
            left: 0,
            position: 'absolute',
            top: 0,
            transform: 'scale(0)',
            width: 20,
          },
          position: 'relative',
        },
        '& input': {
          height: 48,
          left: -14,
          top: -14,
          width: 48,
        },
        '& svg': {
          height: 20,
          width: 20,
          zIndex: 0,
        },
        '&:hover, &.Mui-focusVisible': {
          '& > span::before': {
            animation: `200ms alternate hoverControlStart, 
          600ms infinite alternate 200ms hoverControl`,
          },
          background: 'transparent',
          color: colors.active[1],
        },
        background: 'transparent',
        border: 'transparent',
        borderRadius: 3,
        padding: 14,
      },
    },
  },
  MuiRadio: {
    defaultProps: {
      checkedIcon: createElement(RadioCheckedIcon),
      color: 'primary',
      disableRipple: true,
      icon: createElement(RadioIcon),
    },
    styleOverrides: {
      colorPrimary: {
        '&&.Mui-checked:hover': {
          backgroundColor: 'transparent',
        },
      },
      root: {
        '& > span': {
          '&::before': {
            background: colors.active[5],
            borderRadius: '100%',
            content: "''",
            height: 20,
            left: 0,
            position: 'absolute',
            top: 0,
            transform: 'scale(0)',
            width: 20,
          },
          position: 'relative',
        },
        '& input': {
          height: 48,
          left: -14,
          top: -14,
          width: 48,
        },
        '& svg': {
          height: 20,
          width: 20,
          zIndex: 0,
        },
        '&:hover, &.Mui-focusVisible': {
          '& > span::before': {
            animation: `200ms alternate hoverControlStart, 
          600ms infinite alternate 200ms hoverControl`,
          },
          background: 'transparent',
          color: colors.active[1],
        },
        background: 'transparent',
        border: 'transparent',
        borderRadius: '100%',
        padding: 14,
      },
    },
  },
  MuiSwitch: {
    defaultProps: {
      color: 'primary',
      disableRipple: true,
    },
    styleOverrides: {
      colorPrimary: {
        '&.Mui-checked': {
          '& + $track': {
            borderColor: colors.active[1],
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
          color: colors.background.paper,
        },
        '&.Mui-disabled': {
          '& + $track': {
            backgroundColor: 'transparent',
            border: `2px solid ${colors.disabled}`,
          },
          '&.Mui-checked': {
            color: colors.background.paper,
          },
          '&.Mui-checked + $track': {
            backgroundColor: colors.disabled,
            borderColor: colors.disabled,
          },
          color: colors.disabled,
        },
        color: colors.main[3],
      },
      root: {
        '&:hover, &:focus-visible, &.Mui-focusVisible': {
          '& $switchBase:not(.Mui-disabled)': {
            '& + $track': {
              animation: `200ms alternate hoverSwitchStart, 
              600ms infinite alternate 200ms hoverSwitch`,
            },
          },
        },
        height: 48,
        padding: '12px 16px',
        width: 80,
      },
      switchBase: {
        '&.Mui-checked': {
          transform: 'translateX(23px)',
        },
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: 'transparent',
        },
        background: 'transparent',
        border: 'none',
        boxShadow: 'none',
        color: colors.background.paper,
        left: 4,
        marginLeft: 4,
        top: 8,
      },
      thumb: {
        boxShadow: 'none',
        height: 14,
        left: 4,
        position: 'relative',
        width: 14,
      },
      track: {
        backgroundColor: 'transparent',
        border: `2px solid ${colors.main[3]}`,
        borderRadius: 12,
        height: 24,
        opacity: [['1'], '!important'] as any,
        transition: '200ms',
        width: 48,
      },
    },
  },
})
