import React from 'react'

import * as S from './styles'

interface FormSpacerProps {
  children?: React.ReactNode
}

export const FormSpacer: React.FC<FormSpacerProps> = (props) => {
  const { children } = props

  return <S.Spacer>{children}</S.Spacer>
}

FormSpacer.displayName = 'FormSpacer'
export default FormSpacer
