import UserChip from '@components/UserChip'
import useLocalStorage from '@hooks/useLocalStorage'
import useNavigator from '@hooks/useNavigator'
import useTheme from '@hooks/useTheme'
import useUser from '@hooks/useUser'
import { Logo } from '@icons/Logo'
import React from 'react'

import { MenuItem } from './MenuItem'
import * as S from './Sidebar.styles'
import { BaseSidebarProps } from './types'

export type SidebarProps = BaseSidebarProps

export const Sidebar: React.FC<SidebarProps> = ({
  menuItems,
  toolbar,
  logoHref,
  linkComponent,
}) => {
  const navigate = useNavigator()

  const [isShrunk, setShrink] = useLocalStorage<boolean>('menuShrink', false)

  const Link = S.StyledLogoLink(linkComponent ?? 'a')

  const { themeType, setTheme } = useTheme()

  const { logout, user } = useUser()

  const onLogOut = () => {
    logout!()
    navigate('/')
  }

  return (
    <S.RootWrapper isShrunk={isShrunk}>
      <S.Root isShrunk={isShrunk}>
        <div>
          <Link href={logoHref}>
            <Logo />
          </Link>
          <S.MenuList>
            {menuItems.map((menuItem) => (
              <MenuItem
                isMenuShrunk={isShrunk}
                menuItem={menuItem}
                key={menuItem.ariaLabel}
                linkComponent={linkComponent}
                setMenuShrunk={setShrink}
              />
            ))}
          </S.MenuList>
          {toolbar && <S.ToolbarContainer>{toolbar}</S.ToolbarContainer>}
        </div>
        <div>
          <UserChip
            themeType={themeType}
            isShrunk={isShrunk}
            onMenuShrunkToggle={() => setShrink(!isShrunk)}
            user={user!}
            onLogout={onLogOut}
            onThemeToggle={() =>
              setTheme(themeType === 'dark' ? 'light' : 'dark')
            }
          />
        </div>
      </S.Root>
    </S.RootWrapper>
  )
}

Sidebar.displayName = 'SideBar'
