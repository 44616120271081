import { styled } from '@mui/material'

import { menuWidth, shrunkMenuWidth } from './const'

export const RootWrapper = styled('div', {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'isShrunk',
})<{
  isShrunk: boolean
}>(({ isShrunk }) => ({
  height: '100vh',
  transition: 'width 0.5s ease',
  width: isShrunk ? shrunkMenuWidth : menuWidth,
}))

export const Root = styled('div', {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'isShrunk',
})<{
  isShrunk: boolean
}>(({ theme, isShrunk }) => ({
  borderRight: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  justifyContent: 'space-between',
  position: 'fixed',
  transition: 'width 0.5s ease',
  width: isShrunk ? shrunkMenuWidth : menuWidth,
}))

export const StyledLogoLink = (component: any) =>
  styled(component)(({ theme }) => ({
    color: 'inherit',
    display: 'block',

    margin: `36px 0 ${theme.spacing(3)} ${theme.spacing(2.5)}`,
  }))

export const ToolbarContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(1, 0, 1, 1.5),
}))

export const Float = styled('div')({})

export const MenuList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
}))
