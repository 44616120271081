import React, { useContext, useEffect } from 'react'

import { UserContext } from './context'
import { useAuthProvider } from './hooks/useAuthProvider'

interface AuthProviderProps {
  children: React.ReactNode
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const authProvider = useAuthProvider()

  return (
    <UserContext.Provider value={authProvider}>{children}</UserContext.Provider>
  )
}

export const useAuth = () => {
  const user = useContext(UserContext)
  const isAuthenticated = !!user.user

  return {
    isAuthenticated,
    isTokenAuthLoading: user.isTokenAuthLoading,
    isTokenVerifyLoading: user.isTokenVerifyLoading,
    user: user.user,
  }
}

export const useIsOnLogin = (callback: () => void) => {
  const { setOnLogIn } = useContext(UserContext)
  useEffect(() => {
    if (setOnLogIn) {
      setOnLogIn(callback)
      return () => setOnLogIn(undefined)
    }
  }, [setOnLogIn])
}

export const useTokenAuthError = () => {
  const user = useContext(UserContext)
  return user.tokenAuthError
}

export default AuthProvider
