import { LayoutButton } from '@components/LayoutButton'
import { menuWidth, shrunkMenuWidth } from '@components/Sidebar/const'
import { Avatar as MuiAvatar, styled, Typography } from '@mui/material'

const avatarSize = 32

export const Avatar = styled(MuiAvatar)(({ theme }) => ({
  '&&': {
    height: avatarSize,
    width: avatarSize,
  },
  backgroundColor: theme.palette.background.paper,
}))

export const AvatarInitials = styled('div')(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  height: avatarSize,
  lineHeight: avatarSize + 'px',
  textAlign: 'center',
  width: avatarSize,
}))

export const AvatarPlaceholder = styled('div')(({ theme }) => ({
  alignItems: 'center',
  background: theme.palette.primary.main,
  borderRadius: '100%',
  display: 'flex',
  justifyContent: 'center',
}))

export const Label = styled(Typography)({
  lineHeight: 1.2,
  textAlign: 'left',
})

export const LabelContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'inline-flex',
  justifyContent: 'space-between',
  marginLeft: theme.spacing(1),
  overflow: 'hidden',
  transition: 'width ' + theme.transitions.duration.shortest + 'ms',
  whiteSpace: 'nowrap',
  width: '100%',
}))

export const UserChip = styled(LayoutButton, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'isExpanded',
})<{ isExpanded: boolean }>(({ theme, isExpanded }) => ({
  '&&': {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
    },
    padding: theme.spacing(2, 3, 2, 3),
    transition: 'width 0.5s ease',
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
  ...theme.typography.body1,
  backgroundColor: 'transparent',
  borderTop: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  height: 56,
  justifyContent: 'flex-start',
  marginBottom: 8,
  paddingTop: theme.spacing(1.5),
  textTransform: 'unset',
  width: isExpanded ? menuWidth : shrunkMenuWidth,
}))
