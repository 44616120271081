import { stringify as stringifyQs } from 'qs'
import urlJoin from 'url-join'

export const reportSection = '/reports/'

export const dateUrlFormat = 'YYYY-MM-DD'

export const reportAssignedLeadsPath = 'assigned-leads/'
export enum ReportAssignedLeadsType {
  daily = 'daily',
  hourly = 'hourly',
}

export const reportAssignedLeadsByTypePath = (
  type?: ReportAssignedLeadsType,
) =>
  type
    ? urlJoin(reportSection, reportAssignedLeadsPath, type)
    : urlJoin(reportSection, reportAssignedLeadsPath)

export interface ReportAssignedLeadsUrlQueryParams {
  since?: string
  until?: string
}

export const reportAssignedLeadsUrl = (
  type?: ReportAssignedLeadsType,
  params?: ReportAssignedLeadsUrlQueryParams,
) => reportAssignedLeadsByTypePath(type) + '?' + stringifyQs(params)

export const reportNewLeadsPath = 'new-leads/'
export enum ReportNewLeadsType {
  daily = 'daily',
  hourly = 'hourly',
}

export const reportNewLeadsByTypePath = (type?: ReportNewLeadsType) =>
  type
    ? urlJoin(reportSection, reportNewLeadsPath, type)
    : urlJoin(reportSection, reportNewLeadsPath)

export interface ReportNewLeadsUrlQueryParams {
  since?: string
  until?: string
}

export const reportNewLeadsUrl = (
  type?: ReportNewLeadsType,
  params?: ReportNewLeadsUrlQueryParams,
) => reportNewLeadsByTypePath(type) + '?' + stringifyQs(params)
