import NotFound404 from '@dolpheen/images/NotFound404'
import { Typography } from '@mui/material'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import * as S from './styles'

type NotFoundPageProps =
  | {
      onBack: () => void
      backHref?: never
    }
  | {
      onBack?: never
      backHref: string
    }

const NotFoundPage: React.FC<NotFoundPageProps> = ({ onBack, backHref }) => (
  <S.Root>
    <S.Container>
      <S.InnerContainer>
        <div>
          <S.Header variant="h3">
            <FormattedMessage defaultMessage="Ooops!..." />
          </S.Header>
          <S.Header variant="h4">
            <FormattedMessage defaultMessage="Something's missing" />
          </S.Header>
          <Typography>
            <FormattedMessage defaultMessage="Sorry, the page was not found" />
          </Typography>
        </div>
        <div>
          <S.Button
            color="primary"
            variant="contained"
            onClick={onBack}
            href={backHref}
          >
            <FormattedMessage
              defaultMessage="Go back to dashboard"
              description="button"
            />
          </S.Button>
        </div>
      </S.InnerContainer>
      <div>
        <NotFound404 />
      </div>
    </S.Container>
  </S.Root>
)
NotFoundPage.displayName = 'NotFoundPage'
export default NotFoundPage
