import { createSvgIcon } from '@mui/material/utils'
import React from 'react'

const Checkbox = createSvgIcon(
  <>
    <rect
      x="5"
      y="5"
      width="14"
      height="14"
      stroke="currentColor"
      fill="none"
    />
  </>,
  'Checkbox',
)

export default Checkbox
