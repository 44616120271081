import { Button as MuiButton, styled, Typography } from '@mui/material'

export const Root = styled('div')({
  alignItems: 'center',
  display: 'flex',
  height: 'calc(100vh - 180px)',
})

export const Container = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    padding: theme.spacing(3),
    width: '100%',
  },
  display: 'grid',
  gridTemplateColumns: '1fr 487px',
  margin: '0 auto',
  width: 830,
}))

export const InnerContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    order: 1,
    textAlign: 'center',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}))

export const Header = styled(Typography)({
  fontWeight: 600 as 600,
})

export const Button = styled(MuiButton)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: 20,
}))
