import What from '@icons/What'
import { styled, Typography } from '@mui/material'

export const Root = styled('div')({
  alignItems: 'center',
  display: 'flex',
  height: 'calc(100vh - 180px)',
})

export const Container = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    padding: theme.spacing(3),
    width: '100%',
  },
  display: 'grid',
  gridTemplateColumns: '1fr 487px',
  margin: '0 auto',
  width: 830,
}))

export const InnerContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    order: 1,
    textAlign: 'center',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}))

export const UpperHeader = styled(Typography)({
  fontWeight: 600 as 600,
})

export const BottomHeader = styled(Typography)({
  fontWeight: 600 as 600,
  textTransform: 'uppercase',
})

export const ButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

export const Conjunction = styled('div')(({ theme }) => ({
  display: 'inline-block',
  margin: theme.spacing(0, 1),
}))

export const NotFoundImage = styled(What)({
  '& svg': {
    width: '100%',
  },
})
