import { CircularProgress } from '@mui/material';
import React from 'react'

import * as S from './styles'

const FullscreenLoader: React.FC = () => (
  <S.Root>
    <CircularProgress size={128} />
  </S.Root>
)
FullscreenLoader.displayName = 'FullscreenLoader'
export default FullscreenLoader
