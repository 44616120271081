import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  hideError: {
    defaultMessage: 'Hide log',
    description: 'hide error log label in notification',
  },
  seeError: {
    defaultMessage: 'See error log',
    description: 'see error log label in notification',
  },
})
