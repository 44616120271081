import { AppError, IInternalAppState } from './state'

export type AppStateReducerActionType = 'displayError' | 'displayLoader'

export interface AppStateReducerAction {
  payload: Partial<{
    error: AppError | null | undefined
    value: boolean
  }>
  type: AppStateReducerActionType
}

function displayError(
  prevState: IInternalAppState,
  error: AppError | null | undefined,
): IInternalAppState {
  return {
    ...prevState,
    error,
    loading: false,
  }
}

function displayLoader(
  prevState: IInternalAppState,
  value: boolean | null | undefined,
): IInternalAppState {
  let loadingCount = prevState.loadingCount
  if (value) {
    loadingCount++
  } else {
    loadingCount--
  }

  return {
    ...prevState,
    loading: !!loadingCount,
    loadingCount,
  }
}

function reduceAppState(
  prevState: IInternalAppState,
  action: AppStateReducerAction,
): IInternalAppState {
  switch (action.type) {
    case 'displayError':
      return displayError(prevState, action.payload.error!)
    case 'displayLoader':
      return displayLoader(prevState, action.payload.value!)
    default:
      return prevState
  }
}

export default reduceAppState
