import { CommonScopeCode, Scope } from '@dolpheen/apollo'
import { GraphQLErrorCode } from '@dolpheen/auth/errors'
import { findValueInEnum } from '@utils/enum'
import { GraphQLFormattedError } from 'graphql'
import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form/dist/types'

interface Error<TScopeCode> {
  fields: string[]
  message: string
  scope: Scope
  scopeCode: keyof TScopeCode | keyof CommonScopeCode
}

export function getValidationErrors<TScopeCode extends Record<string, any>>(
  errors: readonly GraphQLFormattedError[],
  type: TScopeCode | null,
): Array<Error<TScopeCode>> {
  return errors
    .map((err) => {
      if (err.extensions?.code !== GraphQLErrorCode.InputValidationFailed) {
        return null
      }

      return {
        fields: err.extensions.fields,
        message: err.message,
        scope: err.extensions.scope as Scope,
        scopeCode:
          err.extensions.scopeCode && type !== null
            ? findValueInEnumOrCommon(err.extensions.scopeCode as string, type)
            : CommonScopeCode.INVALID,
      }
    })
    .filter(Boolean) as Array<Error<TScopeCode>>
}

function useServerFormHooks<TScopeCode extends Record<string, any>>(
  codes: TScopeCode | null,
  errors: readonly GraphQLFormattedError[] | null,
  formMethods: Pick<UseFormReturn<any>, 'setError'>,
) {
  useEffect(() => {
    if (errors) {
      const errs = getValidationErrors(errors, codes)

      errs.forEach((err) => {
        err.fields.map((field) => {
          if (field) {
            formMethods.setError(
              field,
              {
                message: err.message,
                type: 'server',
              },
              {
                shouldFocus: true,
              },
            )
          }
        })
      })
    }
  }, [errors, codes])
}

function findValueInEnumOrCommon<TEnum extends Record<string, any>>(
  scopeCode: string,
  type: TEnum,
): TEnum[keyof TEnum] | CommonScopeCode[keyof CommonScopeCode] {
  try {
    return findValueInEnum(scopeCode as string, type)
  } catch (_) {
    try {
      return findValueInEnum(scopeCode as string, CommonScopeCode)
    } catch (__) {
      return CommonScopeCode.INVALID
    }
  }
}

export default useServerFormHooks
