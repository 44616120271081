import type { ButtonBaseProps } from '@mui/material/ButtonBase'
import React from 'react'

import * as S from './styles'

export type LayoutButtonProps<T extends React.ElementType> = ButtonBaseProps<
  T,
  { component?: T }
> & {
  state?: 'active' | 'hover' | 'default'
}

export const LayoutButtonInner = <T extends React.ElementType = 'button'>(
  { children, state = 'default', component, ...rest }: LayoutButtonProps<T>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) => (
  <S.ButtonBase
    active={state === 'active'}
    hover={state === 'hover'}
    component={component}
    disableRipple
    ref={ref}
    {...rest}
  >
    {children}
  </S.ButtonBase>
)
LayoutButtonInner.displayName = 'LayoutButton'

export const LayoutButton = React.forwardRef(LayoutButtonInner) as <
  T extends React.ElementType,
>(
  props: LayoutButtonProps<T> & { ref?: React.ForwardedRef<unknown> },
) => ReturnType<typeof LayoutButtonInner>
