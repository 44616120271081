import NotFound from '@common/NotFound'
import { Permission } from '@dolpheen/apollo'
import useUser from '@hooks/useUser'
import React from 'react'

import { hasPermissions } from '../permission'

type MatchPermissionType = 'all' | 'any'

interface SectionRouteProps {
  permissions?: Permission[]
  matchPermission?: MatchPermissionType
  children: React.ReactElement
}

export const SectionRoute: React.FC<SectionRouteProps> = ({
  permissions,
  matchPermission = 'all',
  children,
}) => {
  const { user } = useUser()

  const canAccess =
    !permissions || hasPermissions(permissions, user, matchPermission)
  return canAccess ? children : <NotFound />
}

SectionRoute.displayName = 'Route'
export default SectionRoute
