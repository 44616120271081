import { ApolloError } from '@apollo/client'
import { commonMessages } from '@common/intl'
import { AuthScopeCode } from '@dolpheen/apollo'
import { getValidationErrors } from '@hooks/userServerFormHooks'
import { GraphQLFormattedError } from 'graphql'
import { IntlShape } from 'react-intl'

export function isJwtError(error: GraphQLFormattedError): boolean {
  const JWTErrors = [AuthScopeCode.JWT_EXPIRED, AuthScopeCode.JWT_INVALID]
  return JWTErrors.includes(error.extensions?.scopeCode as AuthScopeCode)
}

export function isTokenExpired(error: GraphQLFormattedError): boolean {
  return error.extensions?.scopeCode === AuthScopeCode.JWT_EXPIRED
}

export function shouldRefreshJwtToken({
  graphQLErrors,
}: {
  graphQLErrors?: readonly GraphQLFormattedError[]
}) {
  if (graphQLErrors?.some(isJwtError)) {
    if (graphQLErrors?.every(isTokenExpired)) {
      return true
    }
  }

  return false
}

export enum GraphQLErrorCode {
  InputValidationFailed = 'INPUT_VALIDATION_FAILED',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
}

export function getErrorMessage(intl: IntlShape, error: ApolloError): string {
  if (error.networkError) {
    return intl.formatMessage(commonMessages.noConnectionToServerError)
  }

  const errorMessage = getValidationErrors(error.graphQLErrors, null)[0]
    ?.message

  if (errorMessage) {
    return errorMessage
  }

  if (
    error.graphQLErrors.some(
      (e) => e.extensions?.code === GraphQLErrorCode.InternalServerError,
    )
  ) {
    return intl.formatMessage(commonMessages.internalServerError)
  }

  return intl.formatMessage(commonMessages.somethingWentWrong)
}
