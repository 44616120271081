import { createSvgIcon } from '@mui/material/utils'
import React from 'react'

const RadioIcon = createSvgIcon(
  <>
    <circle
      cx="12"
      cy="12"
      r="11"
      fill="var(--background-paper)"
      stroke="currentColor"
      strokeWidth="2"
    />
  </>,
  'RadioIcon',
)

export default RadioIcon
