import { styled, Typography } from '@mui/material'

export const Root = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  [theme.breakpoints.down('xs')]: {
    '& > *': {
      width: '100%',
    },
    '& > *:not(first-child)': {
      marginTop: theme.spacing(2),
    },
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
}))

export const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
    padding: 0,
  },
  flex: 1,
  fontSize: '2.5rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))
