import React from 'react'

import {
  NavigationCardBase,
  NavigationCardBaseProps,
} from './NavigationCardBase'
import * as S from './styles'

export interface NavigationCardProps extends NavigationCardBaseProps {
  title: string
  description: string
  icon: React.ReactNode
}

export const NavigationCard: React.FC<NavigationCardProps> = ({
  icon,
  title,
  description,
  ...rest
}) => (
  <NavigationCardBase {...rest}>
    <S.Content>
      <S.Icon>{icon}</S.Icon>
      <div>
        <S.BoxLinkTitle variant="subtitle1">{title}</S.BoxLinkTitle>
        <S.BoxLinkText variant="body2" color="textSecondary">
          {description}
        </S.BoxLinkText>
      </div>
    </S.Content>
  </NavigationCardBase>
)
