import React from 'react'

export const Logo: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 294.868 294.868"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M284.395,115.13c-3.853,4.085-9.53,4.085-11.396,4.085c-3.46,0-7.738-0.573-13.868-1.859
	c-4.815-1.01-8.961-2.017-12.617-2.904c-7.192-1.747-12.39-3.01-17.283-3.01c-2.758,0-7.79,1.195-13.119,2.461
	c-8.297,1.971-18.622,4.423-28.609,4.423c-10.083,0-20.696-0.784-27.383-1.389c-2.221,8.326-7.505,22.056-19.499,31.884
	c-16.307,13.36-28.397,19.32-28.904,19.567c-3.097,1.51-6.821,0.719-9.034-1.922c-2.213-2.639-2.345-6.445-0.319-9.23
	c0.067-0.095,8.217-11.616,7.904-24.564c-0.124-5.151-0.311-9.187-0.502-12.261c-10.157,2.994-27.451,9.687-36.372,22.286
	C59.88,161.78,54.336,191.16,55.821,200.798c1.546,10.042,1.731,10.042,6.496,10.042c10.382,0,19.104,0.425,28.928,5.773
	c8.69,4.729,13.476,9.903,13.992,10.478c1.949,2.165,2.468,5.263,1.331,7.944c-1.137,2.683-3.724,4.464-6.635,4.568
	c-2.808,0.105-11.305,0.813-16.766,2.795c-2.9,1.053-5.398,2.285-7.814,3.477c-3.865,1.907-7.516,3.708-11.775,4.211
	c-1.385,0.164-2.807,0.244-4.153,0.219c-1.57,3.647-4.242,7.994-8.736,11.443c-3.44,2.641-6.94,4.27-10.028,5.706
	c-3.792,1.765-6.788,3.159-9.088,6.011c-6.028,7.475-8.051,15.58-8.07,15.661c-0.788,3.237-3.622,5.583-6.95,5.734
	c-0.115,0.006-0.231,0.008-0.346,0.008c-3.191,0-6.054-2.012-7.095-5.056c-0.469-1.367-4.422-13.896,0.839-32.235
	c1.881-6.556,4.795-12.231,7.612-17.721c4.584-8.929,8.543-16.64,6.882-26.089c-7.808-44.411-7.269-80.521,1.601-107.323
	C39.838,64.76,73.226,41.535,79.779,37.304c3.251-2.101,6.177-3.949,8.643-5.485c-2.287-1.822-5.194-3.736-8.65-5.205
	c-7.866-3.344-11.172-4.258-12.088-4.476c-0.059,0.002-0.116,0.002-0.173,0.002c-3.265,0.001-5.775-2.155-6.73-5.339
	c-0.992-3.306,0.851-6.855,3.822-8.61C65.168,7.856,78.681,0,96.49,0c3.675,0,7.326,0.337,10.851,1.002
	c10.983,2.073,18.745,5.17,26.251,8.164c7.329,2.924,14.252,5.686,24.588,7.863c5.021,1.058,9.645,1.874,14.116,2.664
	c15.383,2.716,28.668,5.062,48.531,15.205c25.924,13.24,40.501,27.726,41.047,40.787c0.115,2.753,0.055,5.213-0.181,7.375
	c2.426,1.213,5.374,2.746,8.352,4.44c12.123,6.895,17.207,12.059,17.546,17.822C287.826,109.332,286.751,112.631,284.395,115.13z"
      fill="currentColor"
    />
  </svg>
)

Logo.displayName = 'Logo'
