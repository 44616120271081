import {
  ActiveTab,
  BulkAction,
  Dialog,
  Filters,
  Pagination,
  SingleAction,
  Sort,
  TabActionDialog,
} from '@common/types'
import { stringify as stringifyQs } from 'qs'
import urlJoin from 'url-join'

export const customerSection = '/customer/'

export enum CustomersUrlFiltersEnum {
  name = 'name',
  query = 'query',
}

export enum CustomersUrlSortField {
  created = 'created',
  name = 'name',
}

export type CustomersUrlFilters = Filters<CustomersUrlFiltersEnum>
export type CustomersUrlDialog = 'cancel' | TabActionDialog

export type CustomersUrlSort = Sort<CustomersUrlSortField>
export type CustomersUrlQueryParams = BulkAction &
  Dialog<CustomersUrlDialog> &
  CustomersUrlFilters &
  CustomersUrlSort &
  Pagination &
  ActiveTab

export const customerListUrl = (params?: CustomersUrlQueryParams): string => {
  const customerList = customerSection
  if (params === undefined) {
    return customerList
  } else {
    return urlJoin(customerList, '?' + stringifyQs(params))
  }
}

export const customerDetailPath = (id: string) => urlJoin(customerSection, id)
export type CustomerDetailUrlDialog =
  | 'cancel'
  | 'remove'
  | 'set-default-email'
  | 'set-default-phone'
  | 'set-default-address'
  | 'new-email'
  | 'new-phone'
  | 'new-address'
  | 'delete-email'
  | 'delete-phone'
  | 'delete-address'

export type CustomerDetailUrlQueryParams = Dialog<CustomerDetailUrlDialog> &
  Pagination &
  SingleAction
export const customerDetailUrl = (
  id: string,
  params?: CustomerDetailUrlQueryParams,
) => customerDetailPath(encodeURIComponent(id)) + '?' + stringifyQs(params)
