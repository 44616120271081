import { UserFragment } from '@dolpheen/apollo'

const requiredKeys = ['PasswordCredential']

export const isSupported: boolean = !!(
  navigator.credentials &&
  requiredKeys.every((k) => window[k as keyof Window]) &&
  navigator.credentials.preventSilentAccess
)

export function login<T>(
  loginFn: (id: string, password?: string) => T,
): T | null {
  if (isSupported) {
    navigator.credentials.get({ password: true }).then((credential) => {
      if (credential instanceof PasswordCredential) {
        return loginFn(credential.id, credential.password)
      }
    })
  }

  return null
}

export function saveCredentials(user: UserFragment, password: string) {
  if (isSupported) {
    const nameParts = [user.firstName, user.lastName]
      .map((i) => i?.trim())
      .filter(Boolean)
    const cred = new PasswordCredential({
      iconURL: user.avatar?.url,
      id: user.email,
      name: nameParts.length > 0 ? nameParts.join(' ') : undefined,
      password,
    })
    navigator.credentials.store(cred)
  }
}
