import { AppTheme } from '@components/Theme/createTheme'
import { alpha, ButtonBase as MuiButtonBase, styled } from '@mui/material'

interface ButtonBaseProps {
  hover: boolean
  active: boolean
}
export const ButtonBase = styled(MuiButtonBase, {
  shouldForwardProp: (propName: PropertyKey) =>
    !(['hover', 'active'] as PropertyKey[]).includes(propName),
})<ButtonBaseProps>(
  ({ theme, hover, active }: ButtonBaseProps & { theme: AppTheme }) => ({
    '&:hover, &:focus-visible': {
      background: theme.palette.background.paper,
      color: theme.palette.primary.main,
    },
    ...(hover
      ? {
          background: theme.palette.background.paper,
          color: theme.palette.primary.main,
        }
      : {}),

    '&:active': {
      background: theme.palette.app.active[4],
      color: theme.palette.primary.main,
    },
    ...(active
      ? {
          background: theme.palette.app.active[4],
          color: theme.palette.primary.main,
        }
      : {}),

    ...theme.typography.body1,
    background: alpha(theme.palette.background.paper, 0.4),
    borderColor: theme.palette.divider,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 500,
    height: 40,
    padding: theme.spacing(0.5, 2),
    textAlign: 'center',
    transition: theme.transitions.duration.shorter + 'ms',
  }),
)
