import { CustomLinkComponent, SidebarMenuItem } from './types'

export const getLinkProps = (menuItem: SidebarMenuItem) => {
  if (menuItem.url) {
    return {
      href: menuItem.url,
    }
  }
  return {}
}

export const getLinkComponent = (customComponent?: CustomLinkComponent) =>
  customComponent ?? 'button'
