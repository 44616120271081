import {
  ActiveTab,
  Dialog,
  Filters,
  Pagination,
  SingleAction,
  Sort,
  TabActionDialog,
} from '@common/types'
import { stringify as stringifyQs } from 'qs'
import urlJoin from 'url-join'

export const appSection = '/apps/'

export const appListPath = '/'
export type AppListUrlDialog = 'remove' | TabActionDialog

export enum AppListUrlFiltersEnum {
  isActive = 'isActive',
  query = 'query',
}
export type AppListUrlFilters = Filters<AppListUrlFiltersEnum>

export enum AppListUrlSortField {
  name = 'name',
  active = 'active',
  createdAt = 'createdAt',
}
export type AppListUrlSort = Sort<AppListUrlSortField>

export type AppListUrlQueryParams = ActiveTab &
  Dialog<AppListUrlDialog> &
  SingleAction &
  Pagination &
  AppListUrlFilters &
  AppListUrlSort

export type AppDetailUrlDialog = 'create-token' | 'remove-token'
export type AppDetailUrlQueryParams = Dialog<AppDetailUrlDialog> & SingleAction

export const appListUrl = (params?: AppListUrlQueryParams) =>
  urlJoin(appSection, appListPath) + '?' + stringifyQs(params)

export const appAddPath = 'new'
export const appDetailNewUrl = urlJoin(appSection, appAddPath)

export const appDetailPath = (id: string) => urlJoin(appSection, id)

export const appDetailUrl = (id: string, params?: AppDetailUrlQueryParams) =>
  appDetailPath(encodeURIComponent(id)) + '?' + stringifyQs(params)
