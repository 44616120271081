import NotFoundPage from '@components/NotFoundPage'
import useNavigator from '@hooks/useNavigator'
import React from 'react'

export const NotFound: React.FC = () => {
  const navigate = useNavigator()

  return <NotFoundPage onBack={() => navigate('/')} />
}
export default NotFound
