import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

const MenuCaseIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    name="MenuCaseIcon"
    width="24"
    height="24"
    viewBox="0 0 42 42"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6.5V33L20 40L40 33V6.5L20 0L0 6.5ZM2.5 8.69935V31.2263L18.75 36.9138V14.3869L2.5 8.69935ZM21.25 14.3869V36.9138L37.5 31.2263V8.69935L21.25 14.3869ZM34.1436 7.22539L20 2.62872L5.8564 7.22539L20 12.1756L34.1436 7.22539Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default MenuCaseIcon
