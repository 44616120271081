import { commonMessages } from '@common/intl'
import NotFound from '@common/NotFound'
import AppLayout from '@components/AppLayout'
import AppLoader from '@components/AppLoader'
import ErrorPage from '@components/ErrorPage'
import { WindowTitle } from '@components/WindowTitle'
import { Permission } from '@dolpheen/apollo'
import { appSection } from '@dolpheen/apps/urls'
import { caseSection } from '@dolpheen/cases/urls'
import { caseSettingsSection } from '@dolpheen/caseSettings/urls'
import ConfigurationSection, {
  configurationMenuUrl,
} from '@dolpheen/configuration'
import { getConfigMenuItemsPermissions } from '@dolpheen/configuration/utils'
import { customerSection } from '@dolpheen/customers/urls'
import { reportSection } from '@dolpheen/reports/urls'
import { serviceProviderSection } from '@dolpheen/serviceProviders/urls'
import { userSection } from '@dolpheen/users/urls'
import useAppState from '@hooks/useAppState'
import { matchAnyPath } from '@utils/urls'
import React, { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useIntl } from 'react-intl'
import { Route, Routes } from 'react-router-dom'

import { useAuth } from './auth/AuthProvider'
import SectionRoute from './auth/components/SectionRoute'
import FullscreenLoader from './components/FullscreenLoader'

const AuthSection = React.lazy(() => import('./auth'))
const AppSection = React.lazy(() => import('./apps'))
const CaseSection = React.lazy(() => import('./cases'))
const CaseSettingsSection = React.lazy(() => import('./caseSettings'))
const CustomerSection = React.lazy(() => import('./customers'))
const HomeSection = React.lazy(() => import('./home'))
const UserSection = React.lazy(() => import('./users'))
const ServiceProviderSection = React.lazy(() => import('./serviceProviders'))
const ReportsSection = React.lazy(() => import('./reports'))

const AppRoutes: React.FC = () => {
  const intl = useIntl()
  const [appState, dispatchAppState] = useAppState()
  const { isAuthenticated, isTokenAuthLoading, isTokenVerifyLoading } =
    useAuth()

  const isAuthenticating = isTokenAuthLoading || isTokenVerifyLoading
  return (
    <>
      <WindowTitle title={intl.formatMessage(commonMessages.homeTitle)} />
      {isAuthenticating ? (
        <FullscreenLoader />
      ) : isAuthenticated ? (
        <AppLayout>
          <ErrorBoundary
            onError={() => {
              dispatchAppState({
                payload: {
                  error: 'unhandled',
                },
                type: 'displayError',
              })
            }}
            fallbackRender={() => <AppLoader />}
          >
            <Suspense fallback={<AppLoader />}>
              <Routes>
                <Route
                  index
                  element={
                    <SectionRoute>
                      <HomeSection />
                    </SectionRoute>
                  }
                />
                <Route
                  path={matchAnyPath(caseSection)}
                  element={
                    <SectionRoute>
                      <CaseSection />
                    </SectionRoute>
                  }
                />
                <Route
                  path={matchAnyPath(customerSection)}
                  element={
                    <SectionRoute>
                      <CustomerSection />
                    </SectionRoute>
                  }
                />
                <Route
                  path={matchAnyPath(userSection)}
                  element={<UserSection />}
                />
                <Route
                  path={matchAnyPath(appSection)}
                  element={
                    <SectionRoute permissions={[Permission.MANAGE_APPS]}>
                      <AppSection />
                    </SectionRoute>
                  }
                />
                <Route
                  path={matchAnyPath(caseSettingsSection)}
                  element={
                    <SectionRoute permissions={[Permission.MANAGE_SETTINGS]}>
                      <CaseSettingsSection />
                    </SectionRoute>
                  }
                />
                <Route
                  path={matchAnyPath(serviceProviderSection)}
                  element={
                    <SectionRoute
                      permissions={[Permission.MANAGE_SERVICE_PROVIDERS]}
                    >
                      <ServiceProviderSection />
                    </SectionRoute>
                  }
                />
                <Route
                  path={configurationMenuUrl}
                  element={
                    <SectionRoute
                      matchPermission="any"
                      permissions={getConfigMenuItemsPermissions(intl)}
                    >
                      <ConfigurationSection />
                    </SectionRoute>
                  }
                />
                <Route
                  path={matchAnyPath(reportSection)}
                  element={
                    <SectionRoute permissions={[Permission.MANAGE_CASES]}>
                      <ReportsSection />
                    </SectionRoute>
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </AppLayout>
      ) : appState.error ? (
        appState.error === 'unhandled' && (
          <ErrorPage onRefresh={() => window.location.reload()} />
        )
      ) : (
        <Suspense fallback={<FullscreenLoader />}>
          <AuthSection />
        </Suspense>
      )}
    </>
  )
}

AppRoutes.displayName = 'AppRoutes'
export default AppRoutes
