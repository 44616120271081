import { sectionNames } from '@common/intl'
import { WindowTitle } from '@components/WindowTitle'
import { Permission } from '@dolpheen/apollo'
import { appListUrl } from '@dolpheen/apps/urls'
import {
  caseActionResultListPath,
  caseActionTypeListPath,
  caseCloseReasonListPath,
  caseSettingsListUrl,
  serviceTypeListPath,
} from '@dolpheen/caseSettings/urls'
import { userListUrl } from '@dolpheen/users/urls'
import useUser from '@hooks/useUser'
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined'
import BlindsClosedOutlinedIcon from '@mui/icons-material/BlindsClosedOutlined'
import CallEndOutlinedIcon from '@mui/icons-material/CallEndOutlined'
import LanOutlinedIcon from '@mui/icons-material/LanOutlined'
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined'
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined'
import React from 'react'
import { IntlShape, useIntl } from 'react-intl'

import ConfigurationPage, { MenuSection } from './ConfigurationPage'

export function createConfigurationMenu(intl: IntlShape): MenuSection[] {
  return [
    {
      label: intl.formatMessage({
        defaultMessage: 'User Settings',
      }),
      menuItems: [
        {
          description: intl.formatMessage({
            defaultMessage: 'Manage your users and their permissions',
            id: 'configurationMenuUsers',
          }),
          icon: <PeopleOutlinedIcon fontSize="inherit" />,
          permissions: [Permission.MANAGE_USERS],
          testId: 'configuration-menu-user',
          title: intl.formatMessage(sectionNames.users),
          url: userListUrl(),
        },
      ],
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Apps',
      }),
      menuItems: [
        {
          description: intl.formatMessage({
            defaultMessage: 'Manage your apps and their permissions',
            id: 'configurationMenuApps',
          }),
          icon: <AppsOutlinedIcon fontSize="inherit" />,
          permissions: [Permission.MANAGE_APPS],
          testId: 'configuration-menu-apps',
          title: intl.formatMessage(sectionNames.apps),
          url: appListUrl(),
        },
      ],
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Case settings',
      }),
      menuItems: [
        {
          description: intl.formatMessage({
            defaultMessage: 'Define and manage case action types',
            id: 'configurationMenuCaseActionTypes',
          }),
          icon: <ReceiptOutlinedIcon />,
          permissions: [Permission.MANAGE_SETTINGS],
          testId: 'configuration-menu-case-action-types',
          title: intl.formatMessage(sectionNames.caseActionTypes),
          url: caseSettingsListUrl(caseActionTypeListPath)(),
        },
        {
          description: intl.formatMessage({
            defaultMessage: 'Define and manage case action result',
            id: 'configurationMenuCaseActionResults',
          }),
          icon: <CallEndOutlinedIcon />,
          permissions: [Permission.MANAGE_SETTINGS],
          testId: 'configuration-menu-case-action-results',
          title: intl.formatMessage(sectionNames.caseActionResults),
          url: caseSettingsListUrl(caseActionResultListPath)(),
        },
        {
          description: intl.formatMessage({
            defaultMessage: 'Define and manage case close reasons',
            id: 'configurationMenuCaseCloseReason',
          }),
          icon: <BlindsClosedOutlinedIcon />,
          permissions: [Permission.MANAGE_SETTINGS],
          testId: 'configuration-menu-case-close-reasons',
          title: intl.formatMessage(sectionNames.caseCloseReasons),
          url: caseSettingsListUrl(caseCloseReasonListPath)(),
        },
        {
          description: intl.formatMessage({
            defaultMessage: 'Define and manage service types',
            id: 'configurationMenuServiceTypes',
          }),
          icon: <LanOutlinedIcon />,
          permissions: [Permission.MANAGE_SETTINGS],
          testId: 'configuration-menu-service-types',
          title: intl.formatMessage(sectionNames.serviceTypes),
          url: caseSettingsListUrl(serviceTypeListPath)(),
        },
      ],
    },
  ]
}

export const configurationMenuUrl = '/configuration/'

export const ConfigurationSection: React.FC = () => {
  const user = useUser()
  const intl = useIntl()

  return (
    <>
      <WindowTitle title={intl.formatMessage(sectionNames.configuration)} />
      <ConfigurationPage
        menu={createConfigurationMenu(intl)}
        user={user.user!}
      />
    </>
  )
}
export default ConfigurationSection
