import { buttonMessages } from '@common/intl'
import { ButtonProps } from '@mui/material'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { DEFAULT_NOTIFICATION_SHOW_TIME } from '../../config'
import * as S from './styles'

export type ConfirmButtonTransitionState =
  | 'loading'
  | 'success'
  | 'error'
  | 'default'

export interface ConfirmButtonProps extends ButtonProps {
  transitionState: ConfirmButtonTransitionState
  onTransitionToDefault?: () => void
}

interface ConfirmButtonState {
  isDisplayCompletedActionState: boolean
}

class ConfirmButton extends React.Component<
  ConfirmButtonProps,
  ConfirmButtonState
> {
  state: ConfirmButtonState = {
    isDisplayCompletedActionState: false,
  }
  timeout: number | null = null

  static getDerivedStateFromProps(
    nextProps: ConfirmButtonProps,
    prevState: ConfirmButtonState,
  ): ConfirmButtonState {
    if (nextProps.transitionState === 'loading') {
      return {
        isDisplayCompletedActionState: true,
      }
    }
    return prevState
  }

  componentDidUpdate(prevProps: ConfirmButtonProps) {
    const { transitionState, onTransitionToDefault } = this.props
    if (prevProps.transitionState !== transitionState) {
      if (
        (['error', 'success'] as ConfirmButtonTransitionState[]).includes(
          transitionState,
        )
      ) {
        this.timeout = setTimeout(() => {
          this.setState({
            isDisplayCompletedActionState: false,
          })
          if (onTransitionToDefault) {
            onTransitionToDefault()
          }
        }, DEFAULT_NOTIFICATION_SHOW_TIME)
      } else if (transitionState === 'loading') {
        if (this.timeout) {
          clearTimeout(this.timeout)
          this.timeout = null
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
  }

  render() {
    const {
      children,
      sx,
      disabled: isDisabled,
      transitionState,
      onClick,
      onTransitionToDefault: _,
      ...props
    } = this.props
    const { isDisplayCompletedActionState } = this.state

    return (
      <S.Button
        variant="contained"
        onClick={transitionState === 'loading' ? undefined : onClick}
        color="primary"
        success={transitionState === 'success' && isDisplayCompletedActionState}
        error={transitionState === 'error' && isDisplayCompletedActionState}
        sx={sx}
        disabled={!isDisplayCompletedActionState && isDisabled}
        {...props}
      >
        <S.Progress
          size={24}
          color="inherit"
          invisible={transitionState !== 'loading'}
        />
        <S.IconCheck
          invisible={
            !(transitionState === 'success' && isDisplayCompletedActionState)
          }
        />
        <S.Label
          invisible={
            (transitionState === 'loading' || transitionState === 'success') &&
            isDisplayCompletedActionState
          }
        >
          {transitionState === 'error' && isDisplayCompletedActionState ? (
            <FormattedMessage defaultMessage="Error" description="button" />
          ) : (
            children || <FormattedMessage {...buttonMessages.confirm} />
          )}
        </S.Label>
      </S.Button>
    )
  }
}

export default ConfirmButton
