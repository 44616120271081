import { Button, Typography } from '@mui/material'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import * as S from './styles'

export interface ErrorPageProps {
  onBack?: () => void
  onRefresh: () => void
}

const ErrorPage: React.FC<ErrorPageProps> = ({ onBack, onRefresh }) => (
  <S.Root>
    <S.Container>
      <S.InnerContainer>
        <div>
          <S.UpperHeader variant="h4">
            <FormattedMessage defaultMessage="Ooops!..." />
          </S.UpperHeader>
          <S.BottomHeader variant="h3">
            <FormattedMessage defaultMessage="Error" />
          </S.BottomHeader>
          <Typography>
            <FormattedMessage defaultMessage="We've encountered a problem..." />
          </Typography>
          <Typography>
            <FormattedMessage defaultMessage="Don't worry, everything is gonna be fine" />
          </Typography>
        </div>
        <S.ButtonContainer>
          {onBack ? (
            <>
              <Button color="secondary" variant="contained" onClick={onBack}>
                <FormattedMessage
                  defaultMessage="Back to home"
                  description="error page home button"
                />
              </Button>
              <S.Conjunction>
                <FormattedMessage
                  defaultMessage="or"
                  description="error page between two buttons"
                />
              </S.Conjunction>
            </>
          ) : null}
          <Button color="primary" variant="contained" onClick={onRefresh}>
            <FormattedMessage
              defaultMessage="Refresh page"
              description="error page refresh button"
            />
          </Button>
        </S.ButtonContainer>
      </S.InnerContainer>
      <div>
        <S.NotFoundImage />
      </div>
    </S.Container>
  </S.Root>
)
ErrorPage.displayName = 'ErrorPage'
export default ErrorPage
