import { CompleteIcon } from '@icons/CompleteIcon'
import { InfoIcon } from '@icons/InfoIcon'
import { NotAllowedIcon } from '@icons/NotAllowedIcon'
import { WarningIcon } from '@icons/WarningIcon'
import React from 'react'

import { NotificationType } from '.'

interface IconProps extends React.SVGProps<SVGSVGElement> {
  type: NotificationType
}

export const Icon: React.FC<IconProps> = ({ type, ...props }) => {
  switch (type) {
    case 'error':
      return <NotAllowedIcon {...props} />
    case 'warning':
      return <WarningIcon {...props} />
    case 'success':
      return <CompleteIcon {...props} />
    default:
      return <InfoIcon {...props} />
  }
}

export default Icon
