import { contentMaxWidth } from '@components/AppLayout/consts'
import { styled } from '@mui/material'

export const Root = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 3),
  },
  maxWidth: contentMaxWidth,
  padding: theme.spacing(0, 1),
}))
