import { ButtonBaseProps } from '@mui/material/ButtonBase'
import React from 'react'

import * as S from './styles'

export const SquareButton: React.FC<ButtonBaseProps> = React.forwardRef(
  ({ disabled, className, ...rest }, ref) => (
    <S.ButtonBase
      disabled={disabled}
      className={className}
      disableRipple
      ref={ref}
      {...rest}
    />
  ),
)

SquareButton.displayName = 'SquareButton'
