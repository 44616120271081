import { ApolloError } from '@apollo/client'
import { UserFragment } from '@dolpheen/apollo'
import React from 'react'

export interface IUserContext {
  login?: (username: string, password: string) => Promise<unknown>
  loginByToken?: (auth: string, refresh: string, user: UserFragment) => void
  logout?: () => void
  isOnLogin?: boolean
  isTokenAuthLoading: boolean
  isTokenVerifyLoading: boolean
  setOnLogIn?: (callback: (() => void) | undefined) => void
  tokenAuthError?: ApolloError
  tokenVerifyError?: ApolloError
  user?: UserFragment | null
}

export const UserContext = React.createContext<IUserContext>({
  isOnLogin: undefined,
  isTokenAuthLoading: false,
  isTokenVerifyLoading: false,
  login: undefined,
  loginByToken: undefined,
  logout: undefined,
  setOnLogIn: undefined,
  tokenAuthError: undefined,
  tokenVerifyError: undefined,
  user: undefined,
})
