import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

const NotFound404: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    name="NotFound404"
    width="487"
    height="257"
    viewBox="0 0 487 257"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 186.283L27.7022 215.527H63.4453L69.3415 185.298L0 186.283ZM91.8596 79.5938L76.8475 152.211H46.4659L91.8596 79.5938ZM144.402 0L111.518 152.211H125.101L117.237 187.444L105.799 185.298L95.0764 228.224H60.0483L88.6427 256.842H122.956L134.394 213.916H144.402L152.994 179.795L142.06 168.288L171.747 27.0091L144.402 0ZM315.253 186.283L342.956 215.527H378.699L384.595 185.298L315.253 186.283ZM407.113 79.5938L392.101 152.211H361.719L407.113 79.5938ZM459.655 0L426.772 152.211H440.354L432.491 187.444L421.053 185.298L410.33 228.224H375.302L403.896 256.842H438.209L449.647 213.916H459.655L468.248 179.795L457.313 168.288L487 27.0091L459.655 0ZM264.767 47.1731C260.209 27.0979 231.658 31.5679 223.751 51.7347L197.928 168.932C194.173 195.226 213.54 198.788 228.309 189.099L252.972 75.8821L261.281 67.2968L264.767 47.1731ZM284.068 9.61264C297.41 23.4606 301.314 38.1871 298.277 55.2689L272.184 179.795C267.538 202.649 221.964 248.614 176.751 218.031L202.843 247.006C239.167 273.343 289.493 244.041 301.136 200.86L326.514 86.7481C334.02 53.569 318.828 36.2183 284.068 9.61264Z"
      fill="#B0B0B0"
    />
  </SvgIcon>
)

export default NotFound404
