import { ListSettings, ListViews, Pagination } from '@common/types'
import { SearchVariables } from '@hooks/makeSearch'

export const DEFAULT_NOTIFICATION_SHOW_TIME = 3000

export const PAGINATE_BY = 20
export const VALUES_PAGINATE_BY = 10

export const DEFAULT_INITIAL_SEARCH_DATA: SearchVariables = {
  after: undefined,
  first: PAGINATE_BY,
  query: '',
}

export const DEFAULT_INITIAL_PAGINATION_DATA: Pagination = {
  after: undefined,
  before: undefined,
}

export interface AppListViewSettings {
  [ListViews.APP_LIST]: ListSettings
  [ListViews.CASE_LIST]: ListSettings
  [ListViews.CASE_SETTINGS_LIST]: ListSettings
  [ListViews.CUSTOMER_LIST]: ListSettings
  [ListViews.SERVICE_PROVIDER_LIST]: ListSettings
  [ListViews.USER_LIST]: ListSettings
}

export const defaultListSettings: AppListViewSettings = {
  [ListViews.APP_LIST]: {
    rowNumber: PAGINATE_BY,
  },
  [ListViews.CASE_LIST]: {
    rowNumber: PAGINATE_BY,
  },
  [ListViews.CASE_SETTINGS_LIST]: {
    rowNumber: PAGINATE_BY,
  },
  [ListViews.CUSTOMER_LIST]: {
    rowNumber: PAGINATE_BY,
  },
  [ListViews.SERVICE_PROVIDER_LIST]: {
    rowNumber: PAGINATE_BY,
  },
  [ListViews.USER_LIST]: {
    rowNumber: PAGINATE_BY,
  },
}
