import { ApolloError, useApolloClient } from '@apollo/client'
import {
  UserFragment,
  useTokenAuthMutation,
  useVerifyTokenMutation,
} from '@dolpheen/apollo'
import { IUserContext } from '@dolpheen/auth/context'
import useAppState from '@hooks/useAppState'
import { useEffect, useRef, useState } from 'react'

import {
  isSupported as isCredentialsManagementAPISupported,
  login as loginWithCredentialsManagementAPI,
  saveCredentials,
} from '../credentialsManagement'
import { getTokens, removeTokens, setTokens } from '../utils'

export const shouldPersistToken = true

export function useAuthProvider(): IUserContext {
  const [user, setUser] = useState<UserFragment | undefined | null>(undefined)
  const [onLogIn, setOnLogIn] = useState<(() => void) | undefined>(undefined)
  const isOnLogin = !!onLogIn
  const isAuthenticated = !!user
  const permitCredentialsAPI = useRef(true)
  const verifyingToken = useRef(false)

  const client = useApolloClient()

  const [, dispatchAppState] = useAppState()

  useEffect(() => {
    if (isAuthenticated) {
      permitCredentialsAPI.current = true
    }
  }, [isAuthenticated])

  const [tokenAuth, tokenAuthResult] = useTokenAuthMutation({
    onError: (_c: ApolloError) => {
      logout()
      setUser(null)
    },
  })

  const [tokenVerify, tokenVerifyResult] = useVerifyTokenMutation({
    context: {
      tokenInVariable: 'token',
    },
    onCompleted: (result) => {
      if (result.tokenVerifyUser === null) {
        logout()
      } else {
        const user = result.tokenVerifyUser?.user

        if (!!user) {
          setUser(user)
        }
      }
    },
    onError: () => {
      dispatchAppState({
        payload: {
          error: 'unhandled',
        },
        type: 'displayError',
      })
    },
  })

  useEffect(() => {
    if (!verifyingToken.current) {
      const token = getTokens().auth
      if (!!token && !user) {
        verifyingToken.current = true
        tokenVerify({ variables: { token } }).finally(() => {
          verifyingToken.current = false
        })
      }
    }
  }, [])

  useEffect(() => {
    const token = getTokens().auth
    if (!(!!token && !user) && isOnLogin && permitCredentialsAPI.current) {
      permitCredentialsAPI.current = false
      loginWithCredentialsManagementAPI(login)
    }
  }, [isOnLogin])

  const logout = () => {
    setUser(undefined)
    if (isCredentialsManagementAPISupported) {
      navigator.credentials.preventSilentAccess()
    }

    client.clearStore()
    removeTokens()
  }

  const login = async (email: string, password: string): Promise<unknown> =>
    tokenAuth({
      onCompleted: (data) => {
        const user = data.tokenCreate.user

        setUser(user)
        setTokens(
          data.tokenCreate.accessToken,
          data.tokenCreate.refreshToken,
          shouldPersistToken,
        )

        saveCredentials(data.tokenCreate.user, password)
        onLogIn?.()
      },
      variables: { email, password },
    })

  const loginByToken = (auth: string, refresh: string, user: UserFragment) => {
    setUser(user)
    setTokens(auth, refresh, shouldPersistToken)
  }

  return {
    isOnLogin,
    isTokenAuthLoading: tokenAuthResult.loading,
    isTokenVerifyLoading: tokenVerifyResult.loading,
    login,
    loginByToken,
    logout,
    setOnLogIn,
    tokenAuthError: tokenAuthResult.error,
    tokenVerifyError: tokenVerifyResult.error,
    user,
  }
}
