import Typography from '@mui/material/Typography'
import React from 'react'

import { CustomLinkComponent, SidebarMenuItem } from '../Sidebar/types'
import { getLinkComponent, getLinkProps } from '../Sidebar/utils'
import * as S from './styles'

export interface MenuItemBtnProps {
  menuItem: SidebarMenuItem
  onClick: (menuItem: SidebarMenuItem) => void
  linkComponent?: CustomLinkComponent
}

export const MenuItemBtn: React.FC<MenuItemBtnProps> = ({
  menuItem,
  onClick,
  linkComponent,
}) => {
  const linkProps = getLinkProps(menuItem)
  const Component = S.StyledLinkComponent(
    menuItem.url ? getLinkComponent(linkComponent) : 'button',
  )

  return (
    <Component
      data-test="menu-item-label"
      data-test-id={menuItem.id}
      onClick={() => onClick(menuItem)}
      {...linkProps}
    >
      {menuItem.icon}
      <Typography
        component="span"
        aria-label={menuItem.ariaLabel}
        sx={{ fontWeight: '500' }}
      >
        {menuItem.label}
      </Typography>
    </Component>
  )
}

MenuItemBtn.displayName = 'MenuItemBtn'
