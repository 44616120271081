import { useAppHeaderAnchor } from '@components/AppLayout/AppHeaderContext'
import { SidebarLink } from '@components/AppLayout/SidebarLink'
import useMenuStructure from '@components/AppLayout/useMenuStructure'
import { Container } from '@components/Container'
import ErrorPage from '@components/ErrorPage'
import { Sidebar } from '@components/Sidebar'
import { SidebarDrawer } from '@components/SidebarDrawer'
import { AppTheme } from '@components/Theme/createTheme'
import useAppState from '@hooks/useAppState'
import useNavigator from '@hooks/useNavigator'
import { useMediaQuery } from '@mui/material'
import React from 'react'

import { AppActionContext, useAppActionAnchor } from './AppActionContext'
import * as S from './styles'

interface AppLayoutProps {
  children: React.ReactNode
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const appActionAnchor = useAppActionAnchor()
  const appHeaderAnchor = useAppHeaderAnchor()
  const navigate = useNavigator()
  const [appState, dispatchAppState] = useAppState()
  const isMdUp = useMediaQuery((theme: AppTheme) => theme.breakpoints.up('md'))

  const menuStructure = useMenuStructure()

  const handleErrorBack = () => {
    navigate('/')
    dispatchAppState({
      payload: {
        error: null,
      },
      type: 'displayError',
    })
  }

  return (
    <>
      <AppActionContext.Provider value={{ anchor: appActionAnchor }}>
        <S.Root>
          {isMdUp ? (
            <Sidebar
              menuItems={menuStructure}
              logoHref="/"
              linkComponent={SidebarLink}
            />
          ) : null}
          <S.Content>
            {appState.loading ? (
              <S.AppLoader color="primary" />
            ) : (
              <S.AppLoaderPlaceholder />
            )}
            <S.ViewContainer>
              <S.HeaderWrapper>
                <Container>
                  <S.Header ref={appHeaderAnchor}>
                    {!isMdUp ? (
                      <SidebarDrawer
                        menuItems={menuStructure}
                        logoHref="/"
                        linkComponent={SidebarLink}
                      />
                    ) : null}
                  </S.Header>
                </Container>
              </S.HeaderWrapper>
              <S.MainView>
                {appState.error
                  ? appState.error === 'unhandled' && (
                      <ErrorPage
                        onBack={handleErrorBack}
                        onRefresh={() => window.location.reload()}
                      />
                    )
                  : children}
              </S.MainView>
            </S.ViewContainer>
            <S.AppAction ref={appActionAnchor} />
          </S.Content>
        </S.Root>
      </AppActionContext.Provider>
    </>
  )
}

export default AppLayout
