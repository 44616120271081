import './init'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import App from './App'

const router = createBrowserRouter([
  // match everything with "*"
  {
    element: <App />,
    path: '*',
  },
])

createRoot(document.getElementById('app')!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
)
