import { ThemeType } from '@components/Theme/createTheme'
import { UserFragment } from '@dolpheen/apollo'
import { formatUserInitials, formatUserName } from '@dolpheen/users/format'
import { userDetailMeUrl } from '@dolpheen/users/urls'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { FormControlLabel } from '@mui/material'
import Switch from '@mui/material/Switch'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import { UserChipMenu } from './UserChipMenu'
import { UserChipMenuItem } from './UserChipMenuItem'

export interface UserChipProps {
  themeType: ThemeType
  user: UserFragment
  onLogout: () => void
  onThemeToggle: () => void
  onMenuShrunkToggle?: () => void
  isShrunk: boolean
}

const UserChip: React.FC<UserChipProps> = ({
  themeType,
  user,
  onLogout,
  onThemeToggle,
  isShrunk,
  onMenuShrunkToggle,
}) => {
  const intl = useIntl()
  const isDarkTheme = themeType === 'dark'

  return (
    <UserChipMenu
      initials={formatUserInitials(user)}
      name={formatUserName(user, true)}
      avatar={user?.avatar?.url}
      isShrunk={isShrunk}
    >
      {onMenuShrunkToggle !== undefined ? (
        <UserChipMenuItem
          onClick={onMenuShrunkToggle}
          data-test-id="menu-shrunk-button"
        >
          {isShrunk ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          {isShrunk ? (
            <FormattedMessage
              defaultMessage="Expand menu"
              description="button"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Shrink menu"
              description="button"
            />
          )}
        </UserChipMenuItem>
      ) : null}

      <UserChipMenuItem data-test-id="account-settings-button">
        <Link to={userDetailMeUrl()}>
          <FormattedMessage
            defaultMessage="Account Settings"
            description="button"
          />
        </Link>
      </UserChipMenuItem>
      <UserChipMenuItem
        leaveOpen
        data-test-id="theme-switch"
        data-test-is-dark={isDarkTheme}
      >
        <FormControlLabel
          control={
            <Switch
              sx={{
                switchBase: {
                  '&&:hover': {
                    background: 'transparent',
                  },
                },
              }}
              checked={isDarkTheme}
              color="primary"
              disableRipple
            />
          }
          label={intl.formatMessage({
            defaultMessage: 'Dark Mode',
            description: 'button',
          })}
          onChange={onThemeToggle}
        />
      </UserChipMenuItem>
      <UserChipMenuItem onClick={onLogout} data-test-id="log-out-button">
        <FormattedMessage defaultMessage="Log out" description="button" />
      </UserChipMenuItem>
    </UserChipMenu>
  )
}
UserChip.displayName = 'UserChip'
export default UserChip
