import { AppTheme } from '@components/Theme/createTheme'
import { ExpandIcon as IconExpandIcon } from '@icons/ExpandIcon'
import {
  Button,
  IconButton,
  SnackbarContent as MuiSnackbarContent,
  SnackbarContentProps as MuiSnackbarContentProps,
  styled,
  Typography,
} from '@mui/material'

import { NotificationType } from './'
import CompNotification from './Notification'

const iconWidth = 40

export const Container = styled('div')({
  display: 'grid',
  justifyContent: 'end',
  maxHeight: '100vh',
  overflowY: 'auto',
  pointerEvents: 'auto',
  position: 'fixed',
  right: 0,
  top: 0,
  width: 'auto',
  zIndex: 10000,
})

export const Notification = styled(CompNotification)(({ theme }) => ({
  margin: theme.spacing(2),
  pointerEvents: 'all',
}))

export const SnackbarContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  maxWidth: 450,
  position: 'relative',
}))

interface SnackbarContentProps extends MuiSnackbarContentProps {
  type: NotificationType
  hasActions: boolean
}

export const SnackbarContent = styled(MuiSnackbarContent, {
  shouldForwardProp: (propName: PropertyKey) =>
    propName !== 'status' && propName !== 'hasActions',
})<SnackbarContentProps>(
  ({
    theme,
    type,
    hasActions,
  }: SnackbarContentProps & { theme: AppTheme }) => ({
    borderRadius: 4,
    padding: theme.spacing(0, 6, 1, 2),
    position: 'relative',

    ...(type === 'error'
      ? {
          '& > div:first-of-type svg': {
            color: theme.palette.alert.icon.error,
          },
          backgroundColor: theme.palette.alert.paper.error,
        }
      : type === 'success'
      ? {
          '& > div:first-of-type svg': {
            color: theme.palette.alert.icon.success,
          },
          backgroundColor: theme.palette.alert.paper.success,
        }
      : type === 'warning'
      ? {
          '& > div:first-of-type svg': {
            color: theme.palette.alert.icon.warning,
          },
          backgroundColor: theme.palette.alert.paper.warning,
        }
      : {}),

    '.MuiSnackbarContent-action': {
      paddingLeft: `calc(${iconWidth}px + ${theme.spacing(2)})`,
      ...(hasActions
        ? {
            paddingBottom: '5rem',
          }
        : {}),
    },
    '.MuiSnackbarContent-message': {
      paddingTop: theme.spacing(2),
      position: 'relative',
    },
  }),
)
export const NotificationContainer = styled('div')(({ theme }) => ({
  columnGap: theme.spacing(2),
  display: 'grid',
  gridTemplateColumns: `${iconWidth}px 1fr`,
}))

export const NotificationTitle = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  marginTop: 6,
}))

export const ApiMessage = styled(Typography)({
  margin: '6px 0 4px 0',
})

export const ActionButton = styled(Button)({
  bottom: '20px',
  minWidth: 'unset',
  position: 'absolute',
  right: '24px',
})

export const ApiMessageAction = styled('div')({
  alignItems: 'center',
  display: 'flex',
  marginTop: '-10px',
})

export const ExpandIcon = styled(IconExpandIcon)<{ isRotated: boolean }>(
  ({ isRotated }) => ({
    ...(isRotated
      ? {
          transform: 'rotate(180deg)',
        }
      : {}),
  }),
)

export const CloseButton = styled(IconButton)(
  ({ theme }: { theme: AppTheme }) => ({
    '& svg': {
      maxHeight: 20,
      maxWidth: 20,
    },
    '&:disabled': {
      color: theme.palette.app.disabled,
    },
    '&:hover, &.Mui-focusVisible, &.Mui-hover, &.Mui-active': {
      borderColor: 'transparent',
      color: theme.palette.app.active[1],
    },
    background: 'transparent',
    borderColor: 'transparent',
    borderRadius: 4,
    color: theme.palette.text.secondary,
    padding: 10,
    position: 'absolute',
    right: 5,
    top: 7,
    transition: '200ms',
  }),
)
