import { CheckboxProps } from '@components/Checkbox'
import {
  Checkbox,
  FormControl,
  FormControlLabelProps,
  FormHelperText,
  TextFieldProps,
} from '@mui/material'
import React from 'react'
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form'

import * as S from './styles'

export type FormCheckboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = CheckboxProps &
  UseControllerProps<TFieldValues, TFieldName> &
  Pick<FormControlLabelProps, 'label'> &
  Pick<TextFieldProps, 'helperText' | 'fullWidth'> & {
    controlLabelProps?: Omit<FormControlLabelProps, 'control' | 'label'>
  }

export function FormCheckbox<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  disabled,
  label,
  helperText,
  controlLabelProps,
  fullWidth,
  onChange,
  ...props
}: FormCheckboxProps<TFieldValues, TFieldName>) {
  const form = useFormContext()
  return (
    <Controller
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      control={control! || form.control}
      render={({ field: { ref, value, ...field } }) => (
        <FormControl disabled={disabled} fullWidth={fullWidth}>
          <S.Label
            {...controlLabelProps}
            control={
              <Checkbox
                {...props}
                {...field}
                onChange={(event, checked) => {
                  field.onChange(event)
                  onChange?.(event, checked)
                }}
                inputRef={ref}
                checked={value}
              />
            }
            label={label}
          />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

FormCheckbox.displayName = 'FormCheckbox'
export default FormCheckbox
