import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, MenuList, Popover } from '@mui/material'
import React, { PropsWithChildren } from 'react'

import { UserChipMenuContext } from './context'
import * as S from './styles'

export interface UserChipProps {
  avatar: string | null | undefined
  initials: string | undefined
  name: string | undefined
  subtext?: string
  open?: boolean
  isShrunk: boolean
}

export const UserChipMenu: React.FC<PropsWithChildren<UserChipProps>> = ({
  avatar,
  initials,
  name,
  subtext,
  children,
  isShrunk,
  open = false,
  ...props
}) => {
  const [isMenuOpened, setMenuState] = React.useState(open)

  const anchor = React.useRef<HTMLButtonElement>(null)

  const closeMenu = () => setMenuState(false)

  return (
    <>
      <S.UserChip
        ref={anchor}
        onClick={() => setMenuState(!isMenuOpened)}
        data-test="userMenu"
        state={isMenuOpened ? 'active' : 'default'}
        isExpanded={!isShrunk}
        {...props}
      >
        {avatar ? (
          <S.Avatar alt="user" src={avatar} />
        ) : (
          <S.AvatarPlaceholder>
            <S.AvatarInitials>{initials}</S.AvatarInitials>
          </S.AvatarPlaceholder>
        )}
        <S.LabelContainer>
          <Box component="div">
            <S.Label variant="body2">{name}</S.Label>
            {subtext && (
              <S.Label variant="body2" color="textSecondary">
                {subtext}
              </S.Label>
            )}
          </Box>
          <ChevronRightIcon />
        </S.LabelContainer>
      </S.UserChip>
      <Popover
        open={isMenuOpened}
        anchorEl={anchor.current}
        onClose={closeMenu}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
      >
        <MenuList disablePadding>
          <UserChipMenuContext.Provider value={closeMenu}>
            {children}
          </UserChipMenuContext.Provider>
        </MenuList>
      </Popover>
    </>
  )
}
UserChipMenu.displayName = 'UserChip'
