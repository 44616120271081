import { CaseCustomStatus } from '@dolpheen/apollo'

export const FORM_OPTION_VALUE_NEW = 'new'

export const CUSTOM_STATUSES = [
  CaseCustomStatus.WITHOUT_CONTACT,
  CaseCustomStatus.CALL_AGAIN,
  CaseCustomStatus.STILL_THINKING,
  CaseCustomStatus.WAITING_FOR_INITIAL_DOCS,
  CaseCustomStatus.WAITING_FOR_PAYMENT_FEE,
  CaseCustomStatus.WAITING_FOR_SIGNED_POA_PTP_LETTER,
  CaseCustomStatus.NEGOTIATIONS,
  CaseCustomStatus.WAITING_FOR_PAYMENT_DEBT,
  CaseCustomStatus.CLOSING_MEDIATION_LETTER_SENT,
]
