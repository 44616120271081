import { sectionNames } from '@common/intl'
import { SidebarMenuItem } from '@components/Sidebar'
import { Permission } from '@dolpheen/apollo'
import { appSection } from '@dolpheen/apps/urls'
import {
  caseListAllUrl,
  caseListAssignedUrl,
  caseSection,
} from '@dolpheen/cases/urls'
import { caseSettingsSection } from '@dolpheen/caseSettings/urls'
import { configurationMenuUrl } from '@dolpheen/configuration'
import { getConfigMenuItemsPermissions } from '@dolpheen/configuration/utils'
import { customerListUrl } from '@dolpheen/customers/urls'
import {
  reportAssignedLeadsUrl,
  reportNewLeadsUrl,
  reportSection,
} from '@dolpheen/reports/urls'
import { serviceProviderListUrl } from '@dolpheen/serviceProviders/urls'
import { userSection } from '@dolpheen/users/urls'
import useUser from '@hooks/useUser'
import MenuCaseIcon from '@icons/MenuCaseIcon'
import MenuConfigureIcon from '@icons/MenuConfigureIcon'
import MenuCustomerIcon from '@icons/MenuCustomerIcon'
import MenuHomeIcon from '@icons/MenuHomeIcon'
import MenuServiceProvidersIcon from '@icons/MenuServiceProvidersIcon'
import MenuReportIcon from '@mui/icons-material/Summarize'
import { pick } from 'lodash-es'
import React from 'react'
import { useIntl } from 'react-intl'

export interface FilterableMenuItem extends Omit<SidebarMenuItem, 'children'> {
  children?: FilterableMenuItem[]
  permissions?: Permission[]
}

function useMenuStructure(): SidebarMenuItem[] {
  const { user } = useUser()
  const intl = useIntl()

  const menuItems: FilterableMenuItem[] = [
    {
      ariaLabel: 'home',
      icon: <MenuHomeIcon />,
      id: 'home',
      label: intl.formatMessage(sectionNames.home),
      url: '/',
    },
    {
      activeMatchUrls: [caseSection],
      ariaLabel: 'cases',
      children: [
        {
          ariaLabel: 'casesAssigned',
          id: 'casesAssigned',
          label: intl.formatMessage(sectionNames.casesAssigned),
          url: caseListAssignedUrl(),
        },
        {
          ariaLabel: 'casesAll',
          id: 'casesAll',
          label: intl.formatMessage(sectionNames.casesAll),
          permissions: [Permission.MANAGE_CASES],
          url: caseListAllUrl(),
        },
      ],
      icon: <MenuCaseIcon />,
      id: 'cases',
      label: intl.formatMessage(sectionNames.cases),
    },
    {
      activeMatchUrls: [reportSection],
      ariaLabel: 'report',
      children: [
        {
          ariaLabel: 'newLeads',
          id: 'newLeads',
          label: intl.formatMessage(sectionNames.reportNewLeads),
          permissions: [Permission.MANAGE_CASES],
          url: reportNewLeadsUrl(),
        },
        {
          ariaLabel: 'assignedLeads',
          id: 'assignedLeads',
          label: intl.formatMessage(sectionNames.reportAssignedLeads),
          permissions: [Permission.MANAGE_CASES],
          url: reportAssignedLeadsUrl(),
        },
      ],
      icon: <MenuReportIcon />,
      id: 'reports',
      label: intl.formatMessage(sectionNames.reports),
    },
    {
      ariaLabel: 'customers',
      icon: <MenuCustomerIcon />,
      id: 'customers',
      label: intl.formatMessage(sectionNames.customers),
      url: customerListUrl(),
    },
    {
      ariaLabel: 'serviceProviders',
      icon: <MenuServiceProvidersIcon />,
      id: 'serviceProviders',
      label: intl.formatMessage(sectionNames.serviceProviders),
      permissions: [Permission.MANAGE_SERVICE_PROVIDERS],
      url: serviceProviderListUrl(),
    },
    {
      activeMatchUrls: [caseSettingsSection, userSection, appSection],
      ariaLabel: 'configure',
      icon: <MenuConfigureIcon />,
      id: 'configure',
      label: intl.formatMessage(sectionNames.configuration),
      permissions: getConfigMenuItemsPermissions(intl),
      url: configurationMenuUrl,
    },
  ]

  const isMenuItemPermitted = (menuItem: FilterableMenuItem) => {
    const userPermissions = (user?.accountPermissions || []).map(
      (permission) => permission.code,
    )
    if (!menuItem?.permissions) {
      return true
    }
    return menuItem.permissions.some((permission) =>
      userPermissions.includes(permission),
    )
  }

  const getFilteredMenuItems = (menuItems: FilterableMenuItem[]) =>
    menuItems.filter(isMenuItemPermitted)

  return menuItems.reduce(
    (resultItems: FilterableMenuItem[], menuItem: FilterableMenuItem) => {
      if (!isMenuItemPermitted(menuItem)) {
        return resultItems
      }
      const { children } = menuItem
      const filteredChildren = children
        ? getFilteredMenuItems(children)
        : undefined

      return [
        ...resultItems,
        {
          ...menuItem,
          // If only one filtered record, pass its properties to root
          ...(filteredChildren?.length === 1
            ? {
                children: undefined,
                // For now only pick url
                ...pick(filteredChildren[0], 'url'),
              }
            : {
                children: filteredChildren,
              }),
        },
      ]
    },
    [] as FilterableMenuItem[],
  )
}

export default useMenuStructure
