import useAppState from '@hooks/useAppState'
import { useEffect } from 'react'

function useAppLoader(isLoading: boolean = true) {
  const [, dispatchAppState] = useAppState()

  const setLoaderState = (isLoading: boolean) => {
    dispatchAppState({
      payload: {
        value: isLoading,
      },
      type: 'displayLoader',
    })
  }

  useEffect(() => {
    if (isLoading) {
      setLoaderState(true)
      return () => setLoaderState(false)
    }
  }, [isLoading])
}

export default useAppLoader
