import { ThemeColors } from '@components/Theme/createTheme'

export const dark: ThemeColors = {
  active: {
    1: '#3EA3FF',
    2: '#388AD6',
    3: '#3371AD',
    4: '#2D5783',
    5: '#283E5A',
  },
  alert: {
    icon: {
      error: '#FE6E76',
      info: '#FAFAFA',
      success: '#A4E8BA',
      warning: '#FFE6AB',
    },
    paper: {
      error: '#B65757',
      info: 'rgba(250, 250, 250, 1)',
      success: 'rgba(67, 156, 123, 1)',
      warning: 'rgba(183, 138, 72, 1)',
    },
  },
  autofill: '#5D5881',
  background: {
    default: '#222431',
    paper: '#34384B',
  },
  checkbox: {
    default: '#FFFFFF',
  },
  disabled: '#5D6D83',
  divider: '#34384B',
  errorAction: {
    1: '#EB2B59',
    2: 'rgba(235, 43, 89, 0.8)',
    3: 'rgba(235, 43, 89, 0.6)',
    4: 'rgba(235, 43, 89, 0.4)',
    5: 'rgba(235, 43, 89, 0.2)',
  },
  fail: {
    dark: '#B65757',
    light: '#FEDEDE',
    mid: '#FEADAD',
    verydark: '#9F2E2E',
  },
  gray: {
    default: '#202124',
    disabled: 'rgba(32, 33, 36, 0.6)',
  },
  highlightInactive: {
    default: '#78797A',
  },
  info: {
    dark: '#105CB3',
    light: '#D2F6FE',
    mid: '#99E6FD',
    verydark: '#004697',
  },
  main: {
    1: '#FAFAFA',
    2: 'rgba(250, 250, 250, 0.8)',
    3: 'rgba(250, 250, 250, 0.6)',
    4: 'rgba(250, 250, 250, 0.4)',
    5: 'rgba(250, 250, 250, 0.1)',
  },
  paperBorder: '#252728',
  success: {
    dark: '#439C7B',
    light: '#E6F9F0',
    mid: '#A4E8BA',
    verydark: '#22805D',
  },
  theme: 'dark',
  warning: {
    dark: '#B78A48',
    light: '#FFF4D2',
    mid: '#FFE6AB',
    verydark: '#97661F',
  },
}
export const light: ThemeColors = {
  active: {
    1: '#056DFF',
    2: '#378AFF',
    3: '#68A7FF',
    4: '#C1DBFF',
    5: '#E6F0FF',
  },
  alert: {
    icon: {
      error: '#FE6E76',
      info: '#28234A',
      success: '#5DC292',
      warning: '#FFB84E',
    },
    paper: {
      error: '#FFD6D9',
      info: '#FFFFFF',
      success: '#DFF3E9',
      warning: '#FFF4E4',
    },
  },
  autofill: '#f4f6c5',
  background: {
    default: '#EFF5F8',
    paper: '#FFFFFF',
  },
  checkbox: {
    default: '#616161',
  },
  disabled: '#C2D1E4',
  divider: '#D1D1D1',
  errorAction: {
    1: '#B63755',
    2: '#D36474',
    3: '#E9878B',
    4: '#F7B6B2',
    5: '#FBDDD8',
  },
  fail: {
    dark: '#B65757',
    light: '#FEDEDE',
    mid: '#FEADAD',
    verydark: '#9F2E2E',
  },
  gray: {
    default: '#C8C8C8',
    disabled: '#C0CFE2',
  },
  highlightInactive: {
    default: '#C8C8C8',
  },
  info: {
    dark: '#105CB3',
    light: '#D2F6FE',
    mid: '#99E6FD',
    verydark: '#004697',
  },
  main: {
    1: '#28234A',
    2: 'rgba(40, 35, 74, 0.8)',
    3: 'rgba(40, 35, 74, 0.6)',
    4: 'rgba(40, 35, 74, 0.4)',
    5: 'rgba(40, 35, 74, 0.1)',
  },
  paperBorder: '#EAEAEA',
  success: {
    dark: '#439C7B',
    light: '#E6F9F0',
    mid: '#A4E8BA',
    verydark: '#22805D',
  },
  theme: 'light',
  warning: {
    dark: '#B78A48',
    light: '#FFF4D2',
    mid: '#FFE6AB',
    verydark: '#97661F',
  },
}
