import type { ThemeOptions } from '@mui/material/styles'

import { ThemeColors } from '../types'

export const buttonComponents = (
  colors: ThemeColors,
): ThemeOptions['components'] => {
  const containedPrimaryHover = {
    backgroundColor: colors.background.paper,
    color: colors.active[1],
  }

  return {
    MuiButton: {
      defaultProps: {
        color: 'inherit',
        disableRipple: true,
        variant: 'outlined',
      },
      styleOverrides: {
        contained: {
          '&.Mui-disabled': {
            '&.MuiButton-containedPrimary': {
              backgroundColor: colors.disabled,
              color: colors.background.paper,
            },
            '&MuiButton-containedSecondary': {
              background: colors.background.paper,
              color: colors.disabled,
            },
          },
          '&.Mui-focusVisible': {
            ...containedPrimaryHover,
            boxShadow: undefined,
          },
          '&:active': {
            boxShadow: 'none',
          },
          '&:hover': {
            '@media(hover: none)': {
              boxShadow: 'none',
            },
            boxShadow: 'none',
          },
          boxShadow: 'none',
        },
        containedPrimary: {
          '&&:active': {
            backgroundColor: colors.active[5],
          },
          '&&:hover': containedPrimaryHover,
        },
        outlined: {
          '& svg': {
            marginRight: 8,
          },
          '&.Mui-disabled': {
            border: undefined,
            borderColor: colors.disabled,
            color: colors.disabled,
          },
          '&:active': {
            backgroundColor: colors.main[5],
          },
          '&:hover': {
            // Unsets border as it will require us to override borderWidth and
            // borderStyle over and over
            border: undefined,
          },
          '&:hover, &.Mui-focusVisible': {
            borderColor: colors.main[1],
          },
          background: colors.background.paper,
          borderColor: colors.main[5],
          borderStyle: 'solid',
          borderWidth: 1,
          // 1px smaller because of border
          padding: '7px 12px',
        },
        outlinedPrimary: {
          '&:active': {
            backgroundColor: colors.active[5],
          },
          '&:hover': {
            // Unsets border as it will require us to override borderWidth and
            // borderStyle over and over
            backgroundColor: undefined,
            border: undefined,
          },
          '&:hover, &.Mui-focusVisible': {
            borderColor: colors.active[1],
            color: colors.active[1],
          },
          border: undefined,
          color: undefined,
        },
        outlinedSizeSmall: {
          fontSize: '1.6rem',
          padding: '2px 12px',
        },
        root: {
          '&.Mui-disabled': {
            borderColor: colors.disabled,
          },
          '&:hover': {
            backgroundColor: undefined,
          },
          border: `1px solid ${colors.active[1]}`,
          borderRadius: 4,
          fontSize: '1.6rem',
          fontWeight: 500,
          lineHeight: 1.55,
          padding: '7px 16px',
          textTransform: 'none',
        },
        text: {
          '&&.Mui-disabled': {
            color: colors.disabled,
          },
          '&:active': {
            background: colors.main[5],
          },
          '&:hover, &.Mui-focusVisible': {
            borderColor: colors.main[1],
          },
        },
        textPrimary: {
          '&:active': {
            background: colors.active[4],
          },
          '&:hover, &.Mui-focusVisible': {
            background: colors.active[5],
          },
        },
        textSizeSmall: {
          fontSize: '1.3rem',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: colors.disabled,
          },
          '&:active': {
            backgroundColor: colors.active[5],
          },
          '&:hover': {
            // Unsets border as it will require us to override borderWidth and
            // borderStyle over and over
            backgroundColor: undefined,
          },
          '&:hover, &.Mui-focusVisible': {
            color: colors.active[1],
          },
          color: colors.main[1],
          transition: '200ms',
        },
      },
    },
  }
}
