import {
  ApolloError,
  MutationFunction,
  MutationHookOptions as BaseMutationHookOptions,
  MutationResult,
  useMutation as useBaseMutation,
} from '@apollo/client'
import { commonMessages } from '@common/intl'
import { getMutationStatus } from '@common/misc'
import { MutationResultAdditionalProps } from '@common/types'
import { getErrorMessage, isJwtError } from '@dolpheen/auth/errors'
import useUser from '@hooks/useUser'
import { DocumentNode } from 'graphql'
import { useIntl } from 'react-intl'

import useNotifier from './useNotifier'

export type MutationResultWithOpts<TData> = MutationResult<TData> &
  MutationResultAdditionalProps

export type UseMutation<TData, TVariables> = [
  MutationFunction<TData, TVariables>,
  MutationResultWithOpts<TData>,
]
export type UseMutationHook<TData, TVariables> = (
  cbs: MutationHookOptions<TData, TVariables>,
) => UseMutation<TData, TVariables>

export type MutationHookOptions<TData, TVariables> = BaseMutationHookOptions<
  TData,
  TVariables
>

export function useMutation<TData, TVariables>(
  mutation: DocumentNode,
  { onCompleted, onError, ...opts }: MutationHookOptions<TData, TVariables>,
): UseMutation<TData, TVariables> {
  const notify = useNotifier()
  const intl = useIntl()
  const user = useUser()

  const [mutateFn, result] = useBaseMutation<TData, TVariables>(mutation, {
    ...opts,
    onCompleted,
    onError: (err: ApolloError) => {
      if (err.graphQLErrors) {
        if (err.graphQLErrors.some(isJwtError)) {
          user.logout?.()
          notify({
            status: 'error',
            text: intl.formatMessage(commonMessages.sessionExpired),
          })
          return
        }

        const isHandled = onError?.(err) ?? false

        if (!isHandled) {
          notify({
            autohide: 10_000,
            status: 'error',
            text: getErrorMessage(intl, err),
          })
        }
      } else {
        onError?.(err)
      }
    },
  })

  return [
    mutateFn,
    {
      ...result,
      status: getMutationStatus(result),
    },
  ]
}

function makeMutation<TData, TVariables>(
  mutation: DocumentNode,
): UseMutationHook<TData, TVariables> {
  return (opts: MutationHookOptions<TData, TVariables>) =>
    useMutation<TData, TVariables>(mutation, opts)
}

export default makeMutation
