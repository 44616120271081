import {
  ActiveTab,
  BulkAction,
  Dialog,
  Filters,
  Pagination,
  SingleAction,
  Sort,
  TabActionDialog,
} from '@common/types'
import { stringify as stringifyQs } from 'qs'
import urlJoin from 'url-join'

export const serviceProviderSection = '/service-providers/'

export enum ServiceProviderListUrlFiltersEnum {
  contactEmail = 'contactEmail',
  contactPhone = 'contactPhone',
  contactName = 'contactName',
  name = 'name',
  query = 'query',
}
export type ServiceProviderListUrlFilters =
  Filters<ServiceProviderListUrlFiltersEnum>
export type ServiceProviderListUrlDialog = 'add' | 'remove' | TabActionDialog
export enum ServiceProviderListUrlSortField {
  name = 'name',
  createdAt = 'createdAt',
}
export type ServiceProviderListUrlSort = Sort<ServiceProviderListUrlSortField>
export type ServiceProviderListUrlQueryParams = ActiveTab &
  BulkAction &
  Dialog<ServiceProviderListUrlDialog> &
  Pagination &
  ServiceProviderListUrlFilters &
  ServiceProviderListUrlSort

export const serviceProviderListUrl = (
  params?: ServiceProviderListUrlQueryParams,
): string => {
  const serviceProviderList = serviceProviderSection
  if (params === undefined) {
    return serviceProviderList
  } else {
    return urlJoin(serviceProviderList, '?' + stringifyQs(params))
  }
}

export const serviceProviderDetailPath = (id: string) =>
  urlJoin(serviceProviderSection, id)

export type ServiceProviderDetailUrlDialog =
  | 'change-password'
  | 'remove'
  | 'add-contact'

export enum ServiceProviderDetailContactSortField {
  name = 'name',
  role = 'role',
}

export type ServiceProviderDetailContactSort =
  Sort<ServiceProviderDetailContactSortField>

export type ServiceProviderDetailUrlQueryParams =
  Dialog<ServiceProviderDetailUrlDialog> &
    SingleAction &
    Pagination &
    ServiceProviderDetailContactSort

export const serviceProviderDetailUrl = (
  id: string,
  params?: ServiceProviderDetailUrlQueryParams,
) =>
  serviceProviderDetailPath(encodeURIComponent(id)) + '?' + stringifyQs(params)

export const serviceProviderDetailUrlWithId =
  (id: string) => (params?: ServiceProviderDetailUrlQueryParams) =>
    serviceProviderDetailPath(encodeURIComponent(id)) +
    '?' +
    stringifyQs(params)
