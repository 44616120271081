import { TypePolicy } from '@apollo/client/cache/inmemory/policies'
import { relayStylePagination } from '@apollo/client/utilities'

function relayStylePaginationOptional(fields: string[] = ['filter', 'sort']) {
  return relayStylePagination((_, context) => {
    if (
      context.variables &&
      'USE_FETCH_MORE' in context.variables &&
      context.variables.USE_FETCH_MORE
    ) {
      return fields
    } else {
      // Add 'after', 'before' fields to have proper pagination
      return [...fields, 'after', 'before']
    }
  })
}

const QueryPolicies: TypePolicy = {
  fields: {
    customers: relayStylePaginationOptional(),
    users: relayStylePaginationOptional(),
  },
}

export default QueryPolicies
