import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

const What: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    name="What"
    width="488"
    height="359"
    viewBox="0 0 488 359"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M186.62 211.896H227.139L272.972 0L306.039 33.7306L253.045 238.466H216.123L186.62 211.896ZM170.678 298.931H211.197L219.832 259.74L246.235 285.944L237.103 325.501H200.181L170.678 298.931ZM318.141 328.822H358.66L367.296 289.632L393.698 315.835L384.566 355.392H347.645L318.141 328.822ZM468.031 64.7883C479.185 98.6093 467.483 129.579 432.905 157.677C392.561 177.947 377.642 200.848 371.77 245.169L340.471 245.773L370.612 259.748L400.253 261.165L405.769 230.574C419.118 196.387 459.156 192.667 476.599 159.502C487.498 141.701 490.586 124.17 485.848 106.886L468.031 64.7883ZM429.669 97.3605C431.638 75.985 422.397 64.6821 401.927 63.4306C367.716 62.7717 346.204 79.928 337.378 114.881L303.894 116.754L335.699 144.15L365.933 144.023C374.549 110.04 396.119 93.8932 429.669 97.3605ZM14.2468 331.554H54.7661L63.4013 292.363L89.804 318.566L80.6718 358.124H43.7502L14.2468 331.554ZM164.136 67.5171C175.29 101.338 163.589 132.308 129.011 160.406C88.6667 180.676 73.7478 203.577 67.8758 247.898L36.5762 248.501L66.7172 262.48L96.3587 263.893L101.875 233.303C115.223 199.116 155.262 195.396 172.705 162.231C183.604 144.429 186.692 126.898 181.954 109.617L164.136 67.5171ZM125.774 100.092C127.743 78.7163 118.502 67.4134 98.0327 66.162C63.8211 65.5004 42.31 82.6566 33.4835 117.609L0 119.485L31.8044 146.879L62.0383 146.751C70.6549 112.771 92.2244 96.6218 125.774 100.092Z"
      fill="#C4C4C4"
    />
  </SvgIcon>
)

export default What
