import { alpha, TypographyProps } from '@mui/material'
import { createTheme as muiCreateTheme } from '@mui/material/styles'

import { components } from './components'
import { createPalette } from './palette'
import { shadows } from './shadows'
import { AppTheme, ThemeColors } from './types'

function getSecondaryButtonStyles(colors: ThemeColors) {
  return {
    '&:disabled': {
      color: colors.disabled,
    },
    '&:hover, &.Mui-focusVisible, &:hover, &.Mui-active': {
      color: colors.active[1],
    },
    background: 'transparent',
    borderRadius: 4,
    color: colors.main[3],
    padding: 8,
    transition: '200ms',
  }
}

export const createTheme = (colors: ThemeColors): AppTheme =>
  muiCreateTheme({
    breakpoints: {
      values: {
        lg: 1280,
        md: 900,
        sm: 600,
        xl: 1536,
        xs: 0,
      },
    },
    components: {
      ...components(colors),
      MuiAccordion: {
        defaultProps: {
          elevation: 0,
        },
        styleOverrides: {
          root: {
            '&&.Mui-expanded:before': {
              opacity: 1,
            },
            background: colors.background.default,
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiAccordionSummary: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          content: {
            '&.Mui-expanded': {
              margin: '12px 0',
            },
          },
          expandIconWrapper: {
            ...getSecondaryButtonStyles(colors),
            border: 'none',
          },
          root: {
            '&&&': {
              paddingBottom: 0,
              paddingTop: 0,
            },
            '&.Mui-expanded': {
              minHeight: 'inherit',
            },
            '&:hover': {
              backgroundColor: colors.active['5'],
            },
          },
        },
      },
      MuiCard: {
        defaultProps: {
          elevation: 0,
        },
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            borderRadius: 8,
            borderWidth: 0,
            overflow: 'visible',
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            '.MuiCardContent-root + &': {
              paddingTop: 0,
            },
            padding: '3.2rem 3.2rem 3.2rem',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            '.MuiCardHeader-root + &': {
              paddingTop: 0,
            },
            padding: '2.4rem 3.2rem',
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          action: {
            position: 'relative',
            top: 4,
          },
          root: {
            padding: '2.4rem 3.2rem',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          avatar: {
            fontSize: '1.2rem',
            height: 32,
            left: -5,
            position: 'relative',
            width: 32,
          },
          label: {
            alignItems: 'center',
            display: 'flex',
          },
          root: {
            '& .MuiChip-avatar	': {
              fontSize: '1.2rem',
            },
            fontSize: '1.3rem',
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          ':root': {
            '--background-default': colors.background.default,
            '--background-paper': colors.background.paper,
            '--default-font': "'Inter', sans-serif",
          },

          '@keyframes hoverControl': {
            from: {
              transform: 'scale(1.4)',
            },
            to: {
              transform: 'scale(1.6)',
            },
          },

          '@keyframes hoverControlStart': {
            from: {
              transform: 'scale(1)',
            },
            to: {
              transform: 'scale(1.4)',
            },
          },

          '@keyframes hoverSwitch': {
            from: {
              boxShadow: `0 0 0 3px ${colors.active[5]}`,
            },
            to: {
              boxShadow: `0 0 0 5px ${colors.active[5]}`,
            },
          },
          '@keyframes hoverSwitchStart': {
            from: {
              boxShadow: `0 0 0 0 ${colors.active[5]}`,
            },
            to: {
              boxShadow: `0 0 0 3px ${colors.active[5]}`,
            },
          },

          '@supports (font-variation-settings: normal)': {
            ':root': {
              '--default-font': "'Inter var', sans-serif",
            },
          },

          // For some reason @import clause must be put on top
          // eslint-disable-next-line sort-keys
          '::selection': {
            background: alpha(colors.active[1], 0.2),
          },
          a: {
            color: colors.active[1],
            textDecoration: 'none',
          },
          form: {
            margin: 0,
          },
          html: {
            fontSize: '50.782%',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundImage: 'none',
            overflowY: undefined,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            borderTopColor: alpha(colors.divider, 0.5),
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            padding: '16px 24px',
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            margin: '0px 24px',
            overflowX: undefined,
            overflowY: undefined,
            padding: '24px 0px',
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            '&:last-child': {
              marginBottom: 0,
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          light: {
            backgroundColor: colors.background.paper,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            background: colors.background.default,
            borderBottomRightRadius: 32,
            borderTopRightRadius: 32,
            padding: 24,
            width: 260,
          },
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: 'filled',
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            lineHeight: 1.2,
            marginLeft: 4,
          },
          root: {
            marginLeft: -14,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            fontSize: '1.3rem',
            verticalAlign: 'top',
          },
          filled: {
            '&&:not(.Mui-error)': {
              color: colors.active[1],
            },
          },
          root: {
            '&&.Mui-focused:not(.Mui-error)': {
              color: colors.main[3],
            },
          },
        },
      },
      MuiList: {
        defaultProps: {
          disablePadding: true,
        },
      },
      MuiListItem: {
        styleOverrides: {
          button: {
            '&:focus': {
              background: colors.active[5],
              color: colors.main[1],
            },
          },
          root: {
            '&.Mui-selected': {
              '&:hover': {
                backgroundColor: colors.main[1],
              },
              backgroundColor: colors.main[1],
              outline: 0,
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              '&:hover': {
                backgroundColor: colors.main[1],
              },
              backgroundColor: colors.main[1],
              outline: 0,
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            borderRadius: 0,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected, &.Mui-selected:focus, &.Mui-selected:hover': {
              backgroundColor: [colors.active[5], '!important'] as any,
              color: colors.active[1],
            },
            '&:hover': {
              backgroundColor: [colors.active[5], '!important'] as any,
            },
            '@media(min-width: 600px)': {
              minHeight: 48,
            },
            cursor: 'pointer',
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            background: colors.background.paper,
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          MenuProps: {
            anchorOrigin: {
              horizontal: 'left',
              vertical: 'bottom',
            },
            transformOrigin: {
              horizontal: 'left',
              vertical: 'top',
            },
          },
        },
        styleOverrides: {
          select: {
            '&.Mui-disabled': {
              backgroundColor: colors.background.default,
            },
          },
        },
      },
      MuiSnackbarContent: {
        styleOverrides: {
          action: {
            '& .Mui-IconButton': {
              '& svg': {
                color: colors.main[1],
              },
            },
            display: 'block',
            paddingBottom: 10,
            paddingLeft: 0,
            paddingRight: 45,
          },
          message: {
            fontSize: 16,
          },
          root: {
            backgroundColor: colors.background.paper,
            boxShadow:
              '0 6px 10px 0px rgba(0, 0, 0, 0.15), 0 1px 18px 0px rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.10)',
            color: colors.main[1],
            display: 'block',
            maxWidth: 480,
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: '2.4rem',
            height: '1em',
            width: '1em',
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'outlined',
        },
      },
      MuiTooltip: {
        defaultProps: {
          placement: 'right-end',
        },
        styleOverrides: {
          arrow: {
            color: colors.alert.icon.info,
          },
          tooltip: {
            backgroundColor: colors.alert.icon.info,
            color: colors.active[4],
            fontSize: '0.8em',
            padding: 16,
          },
        },
      },
      MuiTouchRipple: {
        styleOverrides: {
          child: {
            backgroundColor: alpha(colors.active[1], 1),
          },
          childLeaving: {
            backgroundColor: alpha(colors.active[1], 1),
          },
          ripple: {
            '&$rippleVisible': {
              backgroundColor: alpha(colors.active[1], 1),
            },
            borderRadius: '100%',
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          component: 'div',
        } as TypographyProps,
      },
    },
    palette: createPalette(colors),
    shadows,
    spacing: (value: number = 1) => `${(value * 8) / 10}rem`,
    typography: {
      allVariants: {
        fontFamily: 'var(--default-font)',
        letterSpacing: '0.02rem',
      },
      body1: {
        color: colors.main[1],
        fontSize: '1.6rem',
      },
      body2: {
        fontSize: '1.4rem',
        lineHeight: 1.56,
      },
      button: {
        fontSize: '1.4rem',
      },
      caption: {
        fontSize: '1.2rem',
        letterSpacing: 0,
      },
      fontFamily: 'var(--default-font)',
      h1: {
        fontSize: '4rem',
        fontWeight: 700,
      },
      h4: {
        color: colors.main[1],
        fontSize: '3.4rem',
      },
      h5: {
        fontSize: '2.1rem',
        fontWeight: 500,
      },
      h6: {
        fontSize: '2rem',
        fontWeight: 500,
      },
      subtitle1: {
        fontSize: '1.6rem',
      },
      subtitle2: {
        fontSize: '1.4rem',
      },
    },
  }) as unknown as AppTheme
