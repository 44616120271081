import { styled } from '@mui/material'
import { Link } from 'react-router-dom'

export const ConfigurationCategory = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(2),
    gridTemplateColumns: '1fr',
  },
  '&:not(:last-of-type)': {
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  display: 'grid',
  gap: theme.spacing(4),
  gridTemplateColumns: '1fr 4.5fr',
  padding: theme.spacing(4, 0),
}))

export const ConfigurationLabel = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5),
}))

export const ConfigurationItem = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
  display: 'grid',
  gap: theme.spacing(4),
  gridTemplateColumns: '1fr 1fr 1fr',
}))

export const Icon = styled('div')(({ theme }) => ({
  '& path': {
    fill: theme.palette.primary.main,
  },
  fontSize: 48,
}))

export const ItemLink = styled(Link)(({ theme }) => ({
  display: 'contents',
  marginBottom: theme.spacing(4),
}))
