import { LocalizedStatus, StatusType } from '@common/types'
import { Money } from '@components/Money'
import {
  CaseCustomStatus,
  CaseDocumentType,
  CaseEventFragment,
  CaseEventType,
  CaseScopeCode,
  CaseStatus,
  CaseType,
  CustomerDocumentType,
  CustomerEducationLevel,
  CustomerMaritalStatus,
  Gender,
  PaymentStatus,
  Permission,
  ServiceDocumentType,
  ServiceStatus,
  UserFragment,
  UserRole,
} from '@dolpheen/apollo'
import { hasPermissions } from '@dolpheen/auth/permission'
import { formatAddress } from '@dolpheen/cases/components/CaseAddressUpdateDialog'
import { userDetailUrl } from '@dolpheen/users/urls'
import { Tooltip } from '@mui/material'
import { round } from 'lodash-es'
import React, { ReactNode } from 'react'
import { defineMessages, IntlShape } from 'react-intl'
import { Link } from 'react-router-dom'

export const commonMessages = defineMessages({
  add: {
    defaultMessage: 'Add',
  },
  dateOfBirth: {
    defaultMessage: 'Date of birth',
  },
  email: {
    defaultMessage: 'E-mail Address',
  },
  firstName: {
    defaultMessage: 'First Name',
  },
  gender: {
    defaultMessage: 'Gender',
  },
  generalInformation: {
    defaultMessage: 'General Information',
  },
  homeTitle: {
    defaultMessage: 'Dashboard',
  },
  internalServerError: {
    defaultMessage:
      'Dolpheen has some issues on servers. Please contact support.',
  },
  lastName: {
    defaultMessage: 'Last Name',
  },
  middleName: {
    defaultMessage: 'Middle Name',
  },
  name: {
    defaultMessage: 'Name',
  },
  no: {
    defaultMessage: 'No',
  },
  noConnectionToServerError: {
    defaultMessage:
      'Cannot connect to server. Please check your connection or contact support.',
  },
  optionalField: {
    defaultMessage: 'Optional',
    description: 'field is optional',
  },
  role: {
    defaultMessage: 'Role',
  },
  savedChanges: {
    defaultMessage: 'Saved changes',
  },
  sessionExpired: {
    defaultMessage:
      'Your session has expired. Please log in again to continue.',
  },
  somethingWentWrong: {
    defaultMessage: 'Dolpheen ran into an unexpected problem',
  },
  status: {
    defaultMessage: 'Status',
  },
  summary: {
    defaultMessage: 'Summary',
  },
  title: {
    defaultMessage: 'Title',
  },
  uploadImage: {
    defaultMessage: 'Upload image',
    description: 'button',
  },
  webTitle: {
    defaultMessage: 'Dolpheen CRM',
  },
  yes: {
    defaultMessage: 'Yes',
  },
})

export const buttonMessages = defineMessages({
  accept: {
    defaultMessage: 'Accept',
    description: 'button',
  },
  assign: {
    defaultMessage: 'Assign',
    description: 'button',
  },
  back: {
    defaultMessage: 'Back',
    description: 'button',
  },
  cancel: {
    defaultMessage: 'Cancel',
    description: 'button',
  },
  change: {
    defaultMessage: 'Change',
    description: 'button',
  },
  clear: {
    defaultMessage: 'Clear',
    description: 'button',
  },
  confirm: {
    defaultMessage: 'Confirm',
    description: 'button',
  },
  create: {
    defaultMessage: 'Create',
    description: 'button',
  },
  delete: {
    defaultMessage: 'Delete',
    description: 'button',
  },
  done: {
    defaultMessage: 'Done',
    description: 'button',
  },
  edit: {
    defaultMessage: 'Edit',
    description: 'button',
  },
  manage: {
    defaultMessage: 'Manage',
    description: 'button',
  },
  nextStep: {
    defaultMessage: 'Next',
    description: 'go to next step, button',
  },
  ok: {
    defaultMessage: 'OK',
    description: 'button',
  },
  remove: {
    defaultMessage: 'Remove',
    description: 'button',
  },
  save: {
    defaultMessage: 'Save',
    description: 'button',
  },
  selectAll: {
    defaultMessage: 'Select All',
    description: 'select all options, button',
  },
  send: {
    defaultMessage: 'Send',
    description: 'button',
  },
  show: {
    defaultMessage: 'Show',
    description: 'button',
  },
  undo: {
    defaultMessage: 'Undo',
    description: 'button',
  },
})

export const errorMessages = defineMessages({
  requiredField: {
    defaultMessage: 'This field is required.',
    description: 'error',
  },
})

export const sectionNames = defineMessages({
  apps: {
    defaultMessage: 'Apps',
    description: 'apps section name',
  },
  caseActionResults: {
    defaultMessage: 'Case action results',
    description: 'apps section name',
  },
  caseActionTypes: {
    defaultMessage: 'Case action types',
    description: 'apps section name',
  },
  caseCloseReasons: {
    defaultMessage: 'Case close reasons',
    description: 'apps section name',
  },
  cases: {
    defaultMessage: 'Cases',
    description: 'cases section name',
  },
  casesAll: {
    defaultMessage: 'All Cases',
    description: 'cases section name',
  },
  casesAssigned: {
    defaultMessage: 'Assigned Cases',
    description: 'cases section name',
  },
  casesUnassigned: {
    defaultMessage: 'Unassigned Cases',
    description: 'cases section name',
  },
  configuration: {
    defaultMessage: 'Configuration',
    description: 'configuration section name',
  },
  customers: {
    defaultMessage: 'Customers',
    description: 'customers section name',
  },
  home: {
    defaultMessage: 'Home',
    description: 'home section name',
  },
  reportAssignedLeads: {
    defaultMessage: 'Assigned leads',
    description: 'cases section name',
  },
  reportNewLeads: {
    defaultMessage: 'New leads',
    description: 'cases section name',
  },
  reports: {
    defaultMessage: 'Reports',
    description: 'users section name',
  },
  serviceProviders: {
    defaultMessage: 'Service providers',
    description: 'cases section name',
  },
  serviceTypes: {
    defaultMessage: 'Service types',
    description: 'apps section name',
  },
  users: {
    defaultMessage: 'Users',
    description: 'users section name',
  },
})

export function translateBoolean(value: boolean, intl: IntlShape): string {
  return value
    ? intl.formatMessage(commonMessages.yes)
    : intl.formatMessage(commonMessages.no)
}

export const serviceTypes = defineMessages({
  multiple: {
    defaultMessage: 'Various',
  },
  none: {
    defaultMessage: 'None',
  },
  single: {
    defaultMessage: 'Single',
  },
  unknown: {
    defaultMessage: 'Unknown',
  },
})

export function translateCaseType(intl: IntlShape, type: CaseType): string {
  switch (type) {
    case CaseType.SINGLE:
      return intl.formatMessage(serviceTypes.single)
    case CaseType.NONE:
      return intl.formatMessage(serviceTypes.none)
    case CaseType.MULTIPLE:
      return intl.formatMessage(serviceTypes.multiple)
    default:
      return intl.formatMessage(serviceTypes.unknown)
  }
}

export const activeMessages = defineMessages({
  active: {
    defaultMessage: 'Active',
    description: 'active name',
  },
  inactive: {
    defaultMessage: 'Inactive',
    description: 'active name',
  },
})

export const serviceStatusMessages = defineMessages({
  active: {
    defaultMessage: 'Currently active',
    description: 'service status name',
  },
  proposed: {
    defaultMessage: 'Proposed',
    description: 'service status name',
  },
  requested: {
    defaultMessage: 'Requested',
    description: 'service status name',
  },
  terminated: {
    defaultMessage: 'Terminated',
    description: 'service status name',
  },
  unknown: {
    defaultMessage: 'Unknown',
  },
})

export function translateServiceStatus(
  intl: IntlShape,
  status: ServiceStatus,
): string {
  switch (status) {
    case ServiceStatus.ACTIVE:
      return intl.formatMessage(serviceStatusMessages.active)
    case ServiceStatus.PROPOSED:
      return intl.formatMessage(serviceStatusMessages.proposed)
    case ServiceStatus.REQUESTED:
      return intl.formatMessage(serviceStatusMessages.requested)
    case ServiceStatus.TERMINATED:
      return intl.formatMessage(serviceStatusMessages.terminated)
    default:
      return intl.formatMessage(serviceStatusMessages.unknown)
  }
}

export function translateCaseEventMessage(
  intl: IntlShape,
  event: CaseEventFragment,
  user: UserFragment | null | undefined,
): string | ReactNode {
  switch (event.type) {
    case CaseEventType.CLOSED:
      return intl.formatMessage({
        defaultMessage: 'Case was closed',
        description: 'case history message',
      })
    case CaseEventType.ASSIGNED:
      return intl.formatMessage(
        {
          defaultMessage: 'Case was assigned to user {userName}',
          description: 'case history message',
        },
        {
          userName: event.user ? (
            hasPermissions([Permission.MANAGE_USERS], user) ? (
              <Link to={userDetailUrl(event.user.id)}>
                <Tooltip disableInteractive title={event.user.email}>
                  <b>{event.user.displayName}</b>
                </Tooltip>
              </Link>
            ) : (
              <b>{event.user.displayName}</b>
            )
          ) : (
            'unknown'
          ),
        },
      )
    case CaseEventType.DOCUMENT_UPLOADED: {
      if (event.parameters.serviceId) {
        return intl.formatMessage(
          {
            defaultMessage:
              'Document {type} was uploaded for service {serviceName}.',
            description: 'case history message',
          },
          {
            serviceName: event.service?.title ?? 'unknown',
            type: (
              <b>
                {transformServiceDocumentType(
                  intl,
                  event.parameters.type,
                  event.parameters.otherType,
                )}
              </b>
            ),
          },
        )
      } else {
        return intl.formatMessage(
          {
            defaultMessage: 'Document {type} was uploaded.',
            description: 'case history message',
          },
          {
            type: (
              <b>
                {transformCaseDocumentType(
                  intl,
                  event.parameters.type,
                  event.parameters.otherType,
                )}
              </b>
            ),
          },
        )
      }
    }
    case CaseEventType.DOCUMENT_EDITED: {
      if (event.parameters.serviceId) {
        return intl.formatMessage(
          {
            defaultMessage:
              'Document {type} for service {serviceName} was edited.',
            description: 'case history message',
          },
          {
            serviceName: event.service?.title ?? 'unknown',
            type: (
              <b>
                {transformServiceDocumentType(
                  intl,
                  event.parameters.type,
                  event.parameters.otherType,
                )}
              </b>
            ),
          },
        )
      } else {
        return intl.formatMessage(
          {
            defaultMessage: 'Document {type} was edited.',
            description: 'case history message',
          },
          {
            type: (
              <b>
                {transformCaseDocumentType(
                  intl,
                  event.parameters.type,
                  event.parameters.otherType,
                )}
              </b>
            ),
          },
        )
      }
    }
    case CaseEventType.DOCUMENT_DELETED: {
      if (event.parameters.serviceId) {
        return intl.formatMessage(
          {
            defaultMessage:
              'Document {type} for service {serviceName} was deleted.',
            description: 'case history message',
          },
          {
            serviceName: event.service?.title ?? 'unknown',
            type: (
              <b>
                {transformServiceDocumentType(
                  intl,
                  event.parameters.type,
                  event.parameters.otherType,
                )}
              </b>
            ),
          },
        )
      } else {
        return intl.formatMessage(
          {
            defaultMessage: 'Document {type} was deleted.',
            description: 'case history message',
          },
          {
            type: (
              <b>
                {transformCaseDocumentType(
                  intl,
                  event.parameters.type,
                  event.parameters.otherType,
                )}
              </b>
            ),
          },
        )
      }
    }
    case CaseEventType.CREATED:
      return intl.formatMessage({
        defaultMessage: 'Case was created',
        description: 'case history message',
      })
    case CaseEventType.NOTE_ADDED:
      return intl.formatMessage({
        defaultMessage: 'Note added',
        description: 'case history message',
      })
    case CaseEventType.SERVICE_ADDED:
      return intl.formatMessage(
        {
          defaultMessage: 'Service {serviceName} created',
          description: 'case history message',
        },
        {
          serviceName: event.parameters.serviceName,
        },
      )
    case CaseEventType.SERVICE_EDITED:
      return intl.formatMessage(
        {
          defaultMessage: 'Service {serviceName} edited',
          description: 'case history message',
        },
        {
          serviceName: event.parameters.serviceName,
        },
      )
    case CaseEventType.SERVICE_DELETED:
      return intl.formatMessage(
        {
          defaultMessage: 'Service {serviceName} has been deleted',
          description: 'case history message',
        },
        {
          serviceName: event.parameters.serviceName,
        },
      )
    case CaseEventType.ACTION_ADDED:
      return intl.formatMessage(
        {
          defaultMessage: '{type} action with result {result} created',
          description: 'case history message',
        },
        {
          result: event.actionResult?.title,
          type: event.actionType?.title,
        },
      )
    case CaseEventType.ACTION_EDITED:
      return intl.formatMessage(
        {
          defaultMessage: '{type} action with result {result} edited',
          description: 'case history message',
        },
        {
          result: event.actionResult?.title,
          type: event.actionType?.title,
        },
      )
    case CaseEventType.ACTION_DELETED:
      return intl.formatMessage(
        {
          defaultMessage: '{type} action with result {result} has been deleted',
          description: 'case history message',
        },
        {
          result: event.actionResult?.title,
          type: event.actionType?.title,
        },
      )

    case CaseEventType.ADDRESS_ASSIGNED:
      return intl.formatMessage(
        {
          defaultMessage: 'Address {address} has been set for this case.',
          description: 'case history message',
        },
        {
          address: formatAddress(event.parameters),
        },
      )
    case CaseEventType.EMAIL_ASSIGNED:
      return intl.formatMessage(
        {
          defaultMessage:
            'Email {emailAddress} has been set for this case<isDefault> and set to default</isDefault>.',
          description: 'case history message',
        },
        {
          emailAddress: event.parameters.emailAddress,
          isDefault: (...chunks: any[]) =>
            event.parameters.isDefault ? chunks : null,
        },
      )
    case CaseEventType.PHONE_ASSIGNED:
      return intl.formatMessage(
        {
          defaultMessage:
            'Phone {phoneNumber} has been set for this case<isDefault> and set to default</isDefault>.',
          description: 'case history message',
        },
        {
          isDefault: (...chunks: any[]) =>
            event.parameters.isDefault ? chunks : null,
          phoneNumber: event.parameters.phoneNumber,
        },
      )
    case CaseEventType.CUSTOM_STATUS_UPDATED:
      return intl.formatMessage(
        {
          defaultMessage:
            'Custom status has been updated to <b>{customStatus}</b>.',
          description: 'case history message',
        },
        {
          b: (...chunks: any[]) => <b>{chunks}</b>,
          customStatus: transformCaseCustomStatus(
            intl,
            event.parameters.customStatus,
          ),
        },
      )
    case CaseEventType.FEE_CREATED:
      return intl.formatMessage(
        {
          defaultMessage: 'Fee {money} (at {rate} rate) was created.',
          description: 'case history message',
        },
        {
          money: (
            <Money
              money={{
                amount: event.parameters.requestedAmount,
                currency: event.parameters.requestedCurrency,
              }}
            />
          ),
          rate: `${round(event.parameters.feeRate * 100, 2)}%`,
        },
      )
    case CaseEventType.FEE_UPDATED:
      return intl.formatMessage(
        {
          defaultMessage: 'Fee was updated to {money} (at {rate} rate).',
          description: 'case history message',
        },
        {
          money: (
            <Money
              money={{
                amount: event.parameters.requestedAmount,
                currency: event.parameters.requestedCurrency,
              }}
            />
          ),
          rate: `${round(event.parameters.feeRate * 100, 2)}%`,
        },
      )
    case CaseEventType.FEE_PAYMENT_CREATED:
      return intl.formatMessage(
        {
          defaultMessage: 'Fee payment in amount of {money} was recorded.',
          description: 'case history message',
        },
        {
          money: (
            <Money
              money={{
                amount: event.parameters.paidAmount,
                currency: event.parameters.paidCurrency,
              }}
            />
          ),
        },
      )
    case CaseEventType.FEE_PAYMENT_UPDATED:
      return intl.formatMessage(
        {
          defaultMessage: 'Fee payment was changed from {from} to {to}.',
          description: 'case history message',
        },
        {
          from: (
            <Money
              money={{
                amount: event.parameters.previousAmount,
                currency: event.parameters.paidCurrency,
              }}
            />
          ),
          to: (
            <Money
              money={{
                amount: event.parameters.newAmount,
                currency: event.parameters.paidCurrency,
              }}
            />
          ),
        },
      )
    case CaseEventType.FEE_PAYMENT_DELETED:
      return intl.formatMessage(
        {
          defaultMessage: 'Fee payment for amount {money} was deleted.',
          description: 'case history message',
        },
        {
          money: (
            <Money
              money={{
                amount: event.parameters.previousAmount,
                currency: event.parameters.paidCurrency,
              }}
            />
          ),
        },
      )
    case CaseEventType.REOPENED:
      return intl.formatMessage({
        defaultMessage: 'Case was reopened.',
        description: 'case history message',
      })
  }
}

export const caseStatusMessages = defineMessages({
  closed: {
    defaultMessage: 'Closed',
    description: 'case status title',
  },
  open: {
    defaultMessage: 'Open',
    description: 'case status title',
  },
  pending: {
    defaultMessage: 'Pending',
    description: 'case status title',
  },
})

export function transformCaseStatus(
  intl: IntlShape,
  status: CaseStatus,
  hasCloseReason: boolean,
): LocalizedStatus {
  switch (status) {
    case CaseStatus.PENDING:
      return {
        localized: intl.formatMessage(caseStatusMessages.pending),
        status: StatusType.WARNING,
      }
    case CaseStatus.OPEN:
      return {
        localized: intl.formatMessage(caseStatusMessages.open),
        status: StatusType.INFO,
      }
    case CaseStatus.CLOSED:
      return {
        localized: intl.formatMessage(caseStatusMessages.closed),
        status: hasCloseReason ? StatusType.ERROR : StatusType.SUCCESS,
      }
  }
}

export function transformServiceStatus(
  intl: IntlShape,
  status: ServiceStatus,
): LocalizedStatus {
  switch (status) {
    case ServiceStatus.REQUESTED:
      return {
        localized: intl.formatMessage(serviceStatusMessages.requested),
        status: StatusType.INFO,
      }
    case ServiceStatus.PROPOSED:
      return {
        localized: intl.formatMessage(serviceStatusMessages.proposed),
        status: StatusType.INFO,
      }
    case ServiceStatus.ACTIVE:
      return {
        localized: intl.formatMessage(serviceStatusMessages.active),
        status: StatusType.SUCCESS,
      }
    case ServiceStatus.TERMINATED:
      return {
        localized: intl.formatMessage(serviceStatusMessages.terminated),
        status: StatusType.WARNING,
      }
  }
}

export const caseCustomStatusMessages = defineMessages({
  callAgain: {
    defaultMessage: 'Call again',
    description: 'case status title',
  },
  closingMediationLetterSent: {
    defaultMessage: 'Closing Mediation Letter sent',
    description: 'case status title',
  },
  negotiations: {
    defaultMessage: 'Negotiations',
    description: 'case status title',
  },
  stillThinking: {
    defaultMessage: 'Still thinking',
    description: 'case status title',
  },
  waitingForInitialDocs: {
    defaultMessage: 'Waiting for initial docs',
    description: 'case status title',
  },
  waitingForPaymentDebt: {
    defaultMessage: 'Waiting for payment – debt',
    description: 'case status title',
  },
  waitingForPaymentFee: {
    defaultMessage: 'Waiting for payment – fee',
    description: 'case status title',
  },
  waitingForSignedPoAPTPLetter: {
    defaultMessage: 'Waiting for signed PoA & PTP Letter',
    description: 'case status title',
  },
  withoutContact: {
    defaultMessage: 'Without contact',
    description: 'case status title',
  },
})

export function transformCaseCustomStatus(
  intl: IntlShape,
  status: CaseCustomStatus,
): string {
  switch (status) {
    case CaseCustomStatus.CALL_AGAIN:
      return intl.formatMessage(caseCustomStatusMessages.callAgain)
    case CaseCustomStatus.CLOSING_MEDIATION_LETTER_SENT:
      return intl.formatMessage(
        caseCustomStatusMessages.closingMediationLetterSent,
      )
    case CaseCustomStatus.NEGOTIATIONS:
      return intl.formatMessage(caseCustomStatusMessages.negotiations)
    case CaseCustomStatus.STILL_THINKING:
      return intl.formatMessage(caseCustomStatusMessages.stillThinking)
    case CaseCustomStatus.WAITING_FOR_INITIAL_DOCS:
      return intl.formatMessage(caseCustomStatusMessages.waitingForInitialDocs)
    case CaseCustomStatus.WAITING_FOR_PAYMENT_DEBT:
      return intl.formatMessage(caseCustomStatusMessages.waitingForPaymentDebt)
    case CaseCustomStatus.WAITING_FOR_PAYMENT_FEE:
      return intl.formatMessage(caseCustomStatusMessages.waitingForPaymentFee)
    case CaseCustomStatus.WAITING_FOR_SIGNED_POA_PTP_LETTER:
      return intl.formatMessage(
        caseCustomStatusMessages.waitingForSignedPoAPTPLetter,
      )
    case CaseCustomStatus.WITHOUT_CONTACT:
      return intl.formatMessage(caseCustomStatusMessages.withoutContact)
  }
}

export const paymentStatusMessage = defineMessages({
  fullyPaid: {
    defaultMessage: 'Fully paid',
    description: 'case fee status title',
  },
  overpaid: {
    defaultMessage: 'Overpaid',
    description: 'case fee status title',
  },
  partiallyPaid: {
    defaultMessage: 'Partially paid',
    description: 'case fee status title',
  },
  unpaid: {
    defaultMessage: 'Unpaid',
    description: 'case fee status title',
  },
})

export function transformCaseFeeStatus(
  intl: IntlShape,
  status: PaymentStatus,
): LocalizedStatus {
  switch (status) {
    case PaymentStatus.UNPAID:
      return {
        localized: intl.formatMessage(paymentStatusMessage.unpaid),
        status: StatusType.WARNING,
      }
    case PaymentStatus.PARTIALLY_PAID:
      return {
        localized: intl.formatMessage(paymentStatusMessage.partiallyPaid),
        status: StatusType.INFO,
      }
    case PaymentStatus.FULLY_PAID:
      return {
        localized: intl.formatMessage(paymentStatusMessage.fullyPaid),
        status: StatusType.SUCCESS,
      }
    case PaymentStatus.OVERPAID:
      return {
        localized: intl.formatMessage(paymentStatusMessage.overpaid),
        status: StatusType.WARNING,
      }
  }
}

export const caseDocumentTypeMessages = defineMessages({
  closingMediationLetter: {
    defaultMessage: 'Closing mediation letter',
    description: 'case document type title',
  },
  invoiceForFee: {
    defaultMessage: 'Invoice for fee',
    description: 'case document type title',
  },
  other: {
    defaultMessage: 'Other ({otherType})',
    description: 'case document type title',
  },
  otherWithOtherType: {
    defaultMessage: 'Other ({otherType})',
    description: 'customer document type title',
  },
  paymentProofFee: {
    defaultMessage: 'Payment proof fee',
    description: 'case document type title',
  },
  powerOfAttorneyForCase: {
    defaultMessage: 'Power of attorney (for case)',
    description: 'case document type title',
  },
  powerOfAttorneyUnsigned: {
    defaultMessage: 'Power of attorney - unsigned',
    description: 'case document type title',
  },
  ptpLetter: {
    defaultMessage: 'PTP letter',
    description: 'case document type title',
  },
  screenshots: {
    defaultMessage: 'Screenshots',
    description: 'case document type title',
  },
})

export function transformCaseDocumentType(
  intl: IntlShape,
  type: CaseDocumentType,
  otherType: string | null,
): string {
  switch (type) {
    case CaseDocumentType.CLOSING_MEDIATION_LETTER:
      return intl.formatMessage(caseDocumentTypeMessages.closingMediationLetter)
    case CaseDocumentType.INVOICE_FOR_FEE:
      return intl.formatMessage(caseDocumentTypeMessages.invoiceForFee)
    case CaseDocumentType.PAYMENT_PROOF_FEE:
      return intl.formatMessage(caseDocumentTypeMessages.paymentProofFee)
    case CaseDocumentType.POWER_OF_ATTORNEY_CASE:
      return intl.formatMessage(caseDocumentTypeMessages.powerOfAttorneyForCase)
    case CaseDocumentType.POWER_OF_ATTORNEY_CASE_GENERATED:
      return intl.formatMessage(
        caseDocumentTypeMessages.powerOfAttorneyUnsigned,
      )
    case CaseDocumentType.SCREENSHOTS:
      return intl.formatMessage(caseDocumentTypeMessages.screenshots)
    case CaseDocumentType.PTP_LETTER:
      return intl.formatMessage(caseDocumentTypeMessages.ptpLetter)
    case CaseDocumentType.OTHER:
      if (otherType) {
        return intl.formatMessage(
          customerDocumentTypeMessages.otherWithOtherType,
          {
            otherType,
          },
        )
      } else {
        return intl.formatMessage(customerDocumentTypeMessages.other)
      }
  }
}

export const customerDocumentTypeMessages = defineMessages({
  idCard: {
    defaultMessage: 'ID card',
    description: 'customer document type title',
  },
  other: {
    defaultMessage: 'Other',
    description: 'customer document type title',
  },
  otherWithOtherType: {
    defaultMessage: 'Other ({otherType})',
    description: 'customer document type title',
  },
  powerOfAttorney: {
    defaultMessage: 'Power of attorney',
    description: 'customer document type title',
  },
})

export function transformCustomerDocumentType(
  intl: IntlShape,
  type: CustomerDocumentType,
  otherType: string | null,
): string {
  switch (type) {
    case CustomerDocumentType.ID_CARD:
      return intl.formatMessage(customerDocumentTypeMessages.idCard)
    case CustomerDocumentType.POWER_OF_ATTORNEY:
      return intl.formatMessage(customerDocumentTypeMessages.powerOfAttorney)
    case CustomerDocumentType.OTHER:
      if (otherType) {
        return intl.formatMessage(
          customerDocumentTypeMessages.otherWithOtherType,
          {
            otherType,
          },
        )
      } else {
        return intl.formatMessage(customerDocumentTypeMessages.other)
      }
  }
}

export const serviceDocumentTypeMessages = defineMessages({
  other: {
    defaultMessage: 'Other ({otherType})',
    description: 'service document type title',
  },
  otherWithOtherType: {
    defaultMessage: 'Other ({otherType})',
    description: 'customer document type title',
  },
  paymentProofDebt: {
    defaultMessage: 'Payment proof debt',
    description: 'service document type title',
  },
})

export function transformServiceDocumentType(
  intl: IntlShape,
  type: ServiceDocumentType,
  otherType: string | null,
): string {
  switch (type) {
    case ServiceDocumentType.PAYMENT_PROOF_DEBT:
      return intl.formatMessage(serviceDocumentTypeMessages.paymentProofDebt)
    case ServiceDocumentType.OTHER:
      if (otherType) {
        return intl.formatMessage(
          customerDocumentTypeMessages.otherWithOtherType,
          {
            otherType,
          },
        )
      } else {
        return intl.formatMessage(customerDocumentTypeMessages.other)
      }
  }
}

export const userRoleTitleMessages = defineMessages({
  admin: {
    defaultMessage: 'Admin',
    description: 'user role title',
  },
  manager: {
    defaultMessage: 'Manager',
    description: 'user role title',
  },
  negotiator: {
    defaultMessage: 'Negotiator',
    description: 'user role title',
  },
  operator: {
    defaultMessage: 'Operator',
    description: 'user role title',
  },
  operatorInactive: {
    defaultMessage: 'Operator Inactive',
    description: 'user role title',
  },
  teamLeader: {
    defaultMessage: 'Team Leader',
    description: 'user role title',
  },
})

export function transformUserRole(intl: IntlShape, type: UserRole): string {
  switch (type) {
    case UserRole.ADMIN:
      return intl.formatMessage(userRoleTitleMessages.admin)
    case UserRole.MANAGER:
      return intl.formatMessage(userRoleTitleMessages.manager)
    case UserRole.NEGOTIATOR:
      return intl.formatMessage(userRoleTitleMessages.negotiator)
    case UserRole.TEAMLEADER:
      return intl.formatMessage(userRoleTitleMessages.teamLeader)
    case UserRole.OPERATOR:
      return intl.formatMessage(userRoleTitleMessages.operator)
    case UserRole.OPERATOR_INACTIVE:
      return intl.formatMessage(userRoleTitleMessages.operatorInactive)
  }
}

export const genderTitleMessages = defineMessages({
  female: {
    defaultMessage: 'Female',
    description: 'gender title',
  },
  male: {
    defaultMessage: 'Male',
    description: 'gender title',
  },
})

export function transformGender(intl: IntlShape, gender: Gender): string {
  switch (gender) {
    case Gender.FEMALE:
      return intl.formatMessage(genderTitleMessages.female)
    case Gender.MALE:
      return intl.formatMessage(genderTitleMessages.male)
  }
}

export const maritalStatusTitleMessages = defineMessages({
  divorced: {
    defaultMessage: 'Divorced',
    description: 'marital status title',
  },
  married: {
    defaultMessage: 'Married',
    description: 'marital status title',
  },
  single: {
    defaultMessage: 'Single',
    description: 'marital status title',
  },
  widowed: {
    defaultMessage: 'Widowed',
    description: 'marital status title',
  },
})

export function transformMaritalStatus(
  intl: IntlShape,
  status: CustomerMaritalStatus,
): string {
  switch (status) {
    case CustomerMaritalStatus.SINGLE:
      return intl.formatMessage(maritalStatusTitleMessages.single)
    case CustomerMaritalStatus.MARRIED:
      return intl.formatMessage(maritalStatusTitleMessages.married)
    case CustomerMaritalStatus.DIVORCED:
      return intl.formatMessage(maritalStatusTitleMessages.divorced)
    case CustomerMaritalStatus.WIDOWED:
      return intl.formatMessage(maritalStatusTitleMessages.widowed)
  }
}

export const educationLevelTitleMessages = defineMessages({
  diploma: {
    defaultMessage: 'Diploma',
    description: 'education level title',
  },
  juniorHighSchool: {
    defaultMessage: 'Junior High School',
    description: 'education level title',
  },
  none: {
    defaultMessage: 'None',
    description: 'education level title',
  },
  postgraduate: {
    defaultMessage: 'Postgraduate',
    description: 'education level title',
  },
  primarySchool: {
    defaultMessage: 'Primary school',
    description: 'education level title',
  },
  seniorHighSchool: {
    defaultMessage: 'Senior High School',
    description: 'education level title',
  },
  university: {
    defaultMessage: 'University',
    description: 'education level title',
  },
})

export function transformEducationLevel(
  intl: IntlShape,
  level: CustomerEducationLevel,
): string {
  switch (level) {
    case CustomerEducationLevel.NONE:
      return intl.formatMessage(educationLevelTitleMessages.none)
    case CustomerEducationLevel.PRIMARY_SCHOOL:
      return intl.formatMessage(educationLevelTitleMessages.primarySchool)
    case CustomerEducationLevel.JUNIOR_HIGH_SCHOOL:
      return intl.formatMessage(educationLevelTitleMessages.juniorHighSchool)
    case CustomerEducationLevel.SENIOR_HIGH_SCHOOL:
      return intl.formatMessage(educationLevelTitleMessages.seniorHighSchool)
    case CustomerEducationLevel.DIPLOMA:
      return intl.formatMessage(educationLevelTitleMessages.diploma)
    case CustomerEducationLevel.UNIVERSITY:
      return intl.formatMessage(educationLevelTitleMessages.university)
    case CustomerEducationLevel.POSTGRADUATE:
      return intl.formatMessage(educationLevelTitleMessages.postgraduate)
  }
}

export const periodMessages = defineMessages({
  daily: {
    defaultMessage: 'Daily',
    description: 'periods',
  },
  hourly: {
    defaultMessage: 'Hourly',
    description: 'periods',
  },
})

export const caseScopeErrorMessages = defineMessages({
  invalidDocumentConfiguration: {
    defaultMessage:
      'Invalid document configuration. Please contact administrator.',
    description: 'case scope error',
  },
  missingAddress: {
    defaultMessage:
      'No address assigned to current case. Please assign it and try again.',
    description: 'case scope error',
  },
  missingContactPhone: {
    defaultMessage:
      'No contact phone assigned to current case. Please assign it and try again.',
    description: 'case scope error',
  },
  missingDocumentConfiguration: {
    defaultMessage:
      'Missing document configuration. Please contact administrator.',
    description: 'case scope error',
  },
  missingGovernmentId: {
    defaultMessage:
      'Missing customer government ID. Please update it and try again.',
    description: 'case scope error',
  },
  missingServiceDebtAmount: {
    defaultMessage: 'Please make sure all services have set debt amount.',
    description: 'case scope error',
  },
  missingServiceLocalization: {
    defaultMessage:
      'Service type does not have localized title. Please contact administrator.',
    description: 'case scope error',
  },
  noServices: {
    defaultMessage:
      'Case has no active services. Please add more services before retrying.',
    description: 'case scope error',
  },
})

export function transformCaseScopeError(
  intl: IntlShape,
  code: CaseScopeCode,
): string | null {
  switch (code) {
    case CaseScopeCode.MISSING_DOCUMENT_CONFIGURATION:
      return intl.formatMessage(
        caseScopeErrorMessages.missingDocumentConfiguration,
      )
    case CaseScopeCode.INVALID_DOCUMENT_CONFIGURATION:
      return intl.formatMessage(
        caseScopeErrorMessages.invalidDocumentConfiguration,
      )
    case CaseScopeCode.MISSING_CONTACT_PHONE:
      return intl.formatMessage(caseScopeErrorMessages.missingContactPhone)
    case CaseScopeCode.MISSING_GOVERNMENT_ID:
      return intl.formatMessage(caseScopeErrorMessages.missingGovernmentId)
    case CaseScopeCode.MISSING_ADDRESS:
      return intl.formatMessage(caseScopeErrorMessages.missingAddress)
    case CaseScopeCode.NO_SERVICES:
      return intl.formatMessage(caseScopeErrorMessages.noServices)
    case CaseScopeCode.MISSING_SERVICE_LOCALIZATION:
      return intl.formatMessage(
        caseScopeErrorMessages.missingServiceLocalization,
      )
    case CaseScopeCode.MISSING_SERVICE_DEBT:
      return intl.formatMessage(caseScopeErrorMessages.missingServiceDebtAmount)
    default:
      console.warn(`Not implemented case scope code: '${code}}'`)
      return null
  }
}
