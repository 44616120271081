import { CloseIcon } from '@icons/CloseIcon'
import { IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'

import { NotificationProps } from '.'
import Icon from './Icon'
import * as S from './styles'

export const Notification: React.FC<NotificationProps> = ({
  onClose,
  title,
  type,
  action,
  content,
  className: _,
  apiMessage,
  ...rest
}) => {
  const [shouldShowApiMessage, setShouldShowApiMessage] = useState(false)

  return (
    <S.SnackbarContainer
      data-test="notification"
      data-test-type={type}
      {...rest}
      key={title}
    >
      <S.SnackbarContent
        aria-describedby="client-snackbar"
        hasActions={!!action && !apiMessage}
        type={type}
        message={
          <S.NotificationContainer>
            <div>
              <Icon type={type} />
            </div>
            <div>
              <S.NotificationTitle>
                <Typography variant="h5">{title}</Typography>
              </S.NotificationTitle>
              <Typography variant="body1">{content}</Typography>
              {shouldShowApiMessage && (
                <S.ApiMessage variant="body2">
                  {apiMessage?.apiMessageContent}
                </S.ApiMessage>
              )}
            </div>
          </S.NotificationContainer>
        }
        action={[
          <div key="actions">
            {!!action && (
              <S.ActionButton
                key="action"
                size="small"
                onClick={action.onClick}
                data-test="button-action"
              >
                {action.label}
              </S.ActionButton>
            )}
          </div>,
          <S.ApiMessageAction key="api-action">
            {!!apiMessage && (
              <>
                <Typography variant="body1">
                  {shouldShowApiMessage
                    ? apiMessage.hideApiLabel
                    : apiMessage.showApiLabel}
                </Typography>
                <IconButton
                  aria-label={shouldShowApiMessage ? 'shrink' : 'expand'}
                  onClick={() => setShouldShowApiMessage((show) => !show)}
                >
                  <S.ExpandIcon isRotated={shouldShowApiMessage} />
                </IconButton>
              </>
            )}
          </S.ApiMessageAction>,
          <S.CloseButton
            aria-label="Close"
            onClick={onClose}
            data-test="close"
            key="close-action"
          >
            <CloseIcon />
          </S.CloseButton>,
        ]}
      />
    </S.SnackbarContainer>
  )
}
Notification.displayName = 'Notification'
export default Notification
