import type { ThemeOptions } from '@mui/material/styles'

import { ThemeColors } from '../types'
import { buttonComponents } from './buttons'
import { controlComponents } from './controls'
import { inputComponents } from './inputs'
import { tableComponents } from './tables'

export const components = (
  colors: ThemeColors,
): ThemeOptions['components'] => ({
  ...inputComponents(colors),
  ...tableComponents(colors),
  ...buttonComponents(colors),
  ...controlComponents(colors),
})
