import { commonMessages } from '@common/intl'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useIntl } from 'react-intl'

interface WindowTitleProps {
  title: string
}

export const WindowTitle: React.FC<WindowTitleProps> = ({ title }) => {
  const intl = useIntl()
  return !title ? null : (
    <Helmet
      title={`${title} | ${intl.formatMessage(commonMessages.webTitle)}`}
    />
  )
}
