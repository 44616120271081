import { alpha } from '@mui/material'
import { ThemeOptions } from '@mui/material/styles'

import { ThemeColors } from '../types'

export const tableComponents = (
  colors: ThemeColors,
): ThemeOptions['components'] => ({
  MuiTable: {
    styleOverrides: {
      root: {
        fontFamily: 'var(--default-font)',
        fontFeatureSettings: '"tnum"',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      body: {
        fontSize: '1.6rem',
        paddingBottom: 8,
        paddingTop: 8,
      },
      footer: {
        borderBottomWidth: 0,
      },
      head: {
        color: colors.main[3],
        fontSize: '1.4rem',
        fontWeight: 400,
      },
      paddingCheckbox: {
        '&:first-of-type': {
          padding: '0 18px',
          width: 72,
        },
        '&:last-of-type': {
          paddingRight: 20,
        },
        '&:not(first-of-type)': {
          padding: 0,
          width: 52,
        },
      },
      paddingNone: {
        '&&': {
          padding: 4,
        },
      },
      root: {
        '&:first-of-type': {
          '&.MuiTableCell-paddingCheckbox': {
            textOverflow: 'clip',
          },
          '&:not(.MuiTableCell-paddingCheckbox)': {
            paddingLeft: '24px',
            paddingRight: '24px',
            textAlign: 'left',
          },
        },
        borderBottomColor: alpha(colors.divider, 0.4),
        height: 56,
        overflow: 'hidden',
        padding: '8px 24px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      input: {
        color: colors.active[1],
        fontSize: '1.4rem',
      },
    },
  },
  MuiTableRow: {
    defaultProps: {
      hover: true,
    },
    styleOverrides: {
      footer: {
        '.MuiTableRow-root.MuiTableRow-hover&:hover': {
          background: 'none',
        },
      },
      head: {
        '.MuiTableRow-root.MuiTableRow-hover&:hover': {
          background: 'none',
        },
        color: colors.main[3],
      },
      root: {
        '&.MuiTableRow-hover': {
          transition: '200ms',
        },
        '&.MuiTableRow-hover:hover': {
          backgroundColor: colors.active[5],
        },
        position: 'relative',
      },
    },
  },
})
