import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

const MenuHomeIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    name="MenuHomeIcon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 2C2.89543 2 2 2.89543 2 4V11C2 12.1046 2.89543 13 4 13H9C10.1046 13 11 12.1046 11 11V4C11 2.89543 10.1046 2 9 2H4ZM4 4H9V11H4V4ZM15 11C13.8954 11 13 11.8954 13 13V20C13 21.1046 13.8954 22 15 22H20C21.1046 22 22 21.1046 22 20V13C22 11.8954 21.1046 11 20 11H15ZM15 13H20V20H15V13ZM13 4C13 2.89543 13.8954 2 15 2H20C21.1046 2 22 2.89543 22 4V7C22 8.10457 21.1046 9 20 9H15C13.8954 9 13 8.10457 13 7V4ZM20 4H15V7H20V4ZM4 15C2.89543 15 2 15.8954 2 17V20C2 21.1046 2.89543 22 4 22H9C10.1046 22 11 21.1046 11 20V17C11 15.8954 10.1046 15 9 15H4ZM4 17H9V20H4V17Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default MenuHomeIcon
