import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  styled,
  Typography,
} from '@mui/material'

export const Card = styled(MuiCard)(({ theme }) => ({
  '&:hover': {
    boxShadow: theme.shadows[16],
    color: theme.palette.primary.main,
  },
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: theme.shadows[0],
  textDecoration: 'none',
  transition: theme.transitions.create(['color', 'box-shadow'], {
    duration: theme.transitions.duration.shorter,
  }),
}))

export const CardContent = styled(MuiCardContent)(({ theme }) => ({
  '&&': {
    padding: theme.spacing(4),
  },
}))

export const Content = styled('div')(({ theme }) => ({
  columnGap: theme.spacing(2),
  display: 'flex',
}))

export const Icon = styled('div')(({ theme }) => ({
  '& svg': {
    height: 32,
    width: 32,
  },
  transition: theme.transitions.create('color', {
    duration: theme.transitions.duration.shorter,
  }),
}))

export const BoxLinkTitle = styled(Typography)({
  fontWeight: 500,
  transition: 'color .2s ease',
})

export const BoxLinkText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
}))
