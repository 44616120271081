import { Permission, UserFragment } from '@dolpheen/apollo'

export type MatchPermissionType = 'all' | 'any'

export const hasPermission = (
  permission: Permission,
  user: UserFragment | null | undefined,
) => user?.accountPermissions.map((perm) => perm.code).includes(permission)

export const hasAnyPermissions = (
  permissions: readonly Permission[],
  user: UserFragment | null | undefined,
) => permissions?.some((permission) => hasPermission(permission, user)) || false

export const hasAllPermissions = (
  permissions: readonly Permission[],
  user: UserFragment | null | undefined,
) =>
  permissions?.every((permission) => hasPermission(permission, user)) || false

export function hasPermissions(
  permissions: readonly Permission[],
  user: UserFragment | null | undefined,
  matchPermission: MatchPermissionType = 'all',
) {
  if (matchPermission === 'all') {
    return hasAllPermissions(permissions, user)
  }

  return hasAnyPermissions(permissions, user)
}
