export type AppError = 'unhandled'

interface IAppState {
  error: AppError | undefined | null
  loading: boolean | undefined | null
}

export interface IInternalAppState extends IAppState {
  loadingCount: number
}

export const initialAppState: IInternalAppState = {
  error: null,
  loading: false,
  loadingCount: 0,
}

export default IAppState
