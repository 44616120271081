import { AppTheme } from '@components/Theme/createTheme'
import { ButtonBase as MuiButtonBase, styled } from '@mui/material'

export const ButtonBase = styled(MuiButtonBase)(
  ({
    theme,
    disabled,
  }: {
    theme: AppTheme
    disabled: boolean | undefined
  }) => ({
    '&:active': {
      background: theme.palette.app.active[4],
    },
    '&:hover, &:focus-visible': {
      background: theme.palette.app.active[5],
    },
    background: theme.palette.background.paper,
    borderRadius: 4,
    color: disabled ? theme.palette.grey[500] : theme.palette.primary.main,
    height: 48,
    transition: theme.transitions.duration.shortest + 'ms',
    width: 48,
  }),
)
