import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import authLink from '@dolpheen/auth/link'
// @ts-ignore
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'

import introspectionQueryResultData from './fragmentTypes.generated'
import QueryPolicies from './queryPolicies'

const linkOptions = {
  credentials: 'include',
  uri: import.meta.env.VITE_API_URI,
}

const uploadLink = createUploadLink(linkOptions)

const apolloRefreshClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink(linkOptions),
})

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: introspectionQueryResultData.possibleTypes,
    typePolicies: {
      Query: QueryPolicies,
    },
  }),
  link: authLink(apolloRefreshClient).concat(uploadLink),
})
