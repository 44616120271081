import {
  alpha,
  MenuItem as MuiMenuItem,
  styled,
  Theme,
  Typography,
} from '@mui/material'
import React, { HTMLProps } from 'react'

export const StyledRootNavComponent = (component: any) =>
  styled(component, {
    shouldForwardProp: (propName: PropertyKey) =>
      !(['isActive', 'isExtendable'] as PropertyKey[]).includes(propName),
  })<{
    isActive: boolean
  }>(({ theme, isActive, isExtendable }) => ({
    '&:hover, &:focus-visible, &:hover span': {
      color: theme.palette.primary.main,
      outline: 0,
    },
    ':not(:last-child)': {
      marginBottom: isExtendable ? 0 : theme.spacing(0.8),
    },
    borderRadius: 4,
    color: alpha(theme.palette.text.primary, 0.6),

    cursor: 'pointer',
    overflow: 'hidden',
    padding: theme.spacing(1.2, 2, 1.2, 2),
    transition: 'width 0.5s ease',

    width: '100%',

    ...(isActive
      ? {
          '&:hover, &:focus-visible': {
            color: theme.palette.primary.main,
          },
          background: theme.palette.background.paper,
          color: theme.palette.text.primary,
        }
      : {}),
  }))

export const MenuItemBtn = styled('span')(({ theme }) => ({
  '& svg': {
    height: 20,
    transition: theme.transitions.duration.shortest + 'ms',
    width: 20,
  },
  '&:focus': {
    color: theme.palette.primary.main,
    outline: 0,
  },
  background: 'none',
  border: 'none',
  color: 'inherit',
  cursor: 'pointer',
  display: 'inline-flex',
  gap: theme.spacing(1),
  margin: 0,
  padding: 0,
  width: '100%',
}))

const BaseLabel = (theme: Theme) => ({
  cursor: 'pointer',
  display: 'block',
  fontSize: 16,
  fontWeight: '500',
  opacity: 1,
  transition: theme.transitions.duration.shortest + 'ms',
})

export const Label = styled(
  (props) => <Typography {...props} component="span" />,
  {
    shouldForwardProp: (propName: PropertyKey) =>
      propName !== 'shouldHideLabel',
  },
)<HTMLProps<HTMLSpanElement>>(({ theme }) => ({
  ...BaseLabel(theme),
  flexGrow: 1,
  fontSize: 13,
  pointerEvents: 'none',
  textAlign: 'left',
  whiteSpace: 'nowrap',
}))

export const ExtendedLabel = styled(Typography, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'shouldHideLabel',
})<{
  shouldHideLabel: boolean
}>(({ shouldHideLabel }) => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'space-between',
  overflow: 'hidden',
  width: shouldHideLabel ? 0 : 'auto',
}))

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  ...BaseLabel(theme),
  '&.Mui-selected': {
    background: 'unset !important',
  },
  background: 'none',
  border: 'none',
  color: theme.palette.text.primary,
  fontWeight: 500,
  lineHeight: 36 + 'px',
  textAlign: 'left',
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  width: '100%',
}))

export const SubMenuListWrapper = styled('div', {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'isOpen',
})<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  display: 'grid',
  gridTemplateRows: isOpen ? '1fr' : '0fr',
  marginBottom: theme.spacing(0.8),
  transition: 'grid-template-rows 0.2s ease',
}))

export const SubMenuList = styled('div')(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 23,
  overflow: 'hidden',
  paddingLeft: theme.spacing(2),
}))
