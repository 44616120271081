import React from 'react'
import { FormProvider } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import {
  SubmitErrorHandler,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form/dist/types/form'

export interface FormProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
  TSubmitFieldValues extends FieldValues = TFieldValues,
> {
  form: UseFormReturn<TFieldValues, TContext>
  onSubmit?: SubmitHandler<TSubmitFieldValues>
  onInvalidSubmit?: SubmitErrorHandler<TSubmitFieldValues>
  resetOnSubmit?: boolean
  children: React.ReactNode | React.ReactNode[]
}

export function Form<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
>({
  form,
  onSubmit: _onSubmit,
  onInvalidSubmit,
  children,
  resetOnSubmit,
}: FormProps<TFieldValues, TContext>) {
  const onSubmit: SubmitHandler<TFieldValues> = (...params) => {
    _onSubmit?.(...params)
    if (resetOnSubmit) {
      form.reset()
    }
  }

  return (
    <FormProvider {...form}>
      <form
        onSubmit={
          onSubmit ? form.handleSubmit(onSubmit, onInvalidSubmit) : undefined
        }
      >
        {children}
      </form>
    </FormProvider>
  )
}

Form.displayName = 'Form'
export default Form
