import { ApolloError } from '@apollo/client'
import { commonMessages } from '@common/intl'
import { IMessageContext } from '@components/messages'
import { IntlShape } from 'react-intl'

import { isJwtError } from './errors'

export enum TOKEN_STORAGE_KEY {
  AUTH = 'auth',
  REFRESH = 'refresh',
}

export const getTokens = () => ({
  auth:
    localStorage.getItem(TOKEN_STORAGE_KEY.AUTH) ||
    sessionStorage.getItem(TOKEN_STORAGE_KEY.AUTH),
  refresh:
    localStorage.getItem(TOKEN_STORAGE_KEY.REFRESH) ||
    sessionStorage.getItem(TOKEN_STORAGE_KEY.REFRESH),
})

export const setTokens = (auth: string, refresh: string, persist: boolean) => {
  if (persist) {
    localStorage.setItem(TOKEN_STORAGE_KEY.AUTH, auth)
    localStorage.setItem(TOKEN_STORAGE_KEY.REFRESH, refresh)
  } else {
    sessionStorage.setItem(TOKEN_STORAGE_KEY.AUTH, auth)
    sessionStorage.setItem(TOKEN_STORAGE_KEY.REFRESH, refresh)
  }
}

export const setAuthToken = (auth: string, persist: boolean) => {
  if (persist) {
    localStorage.setItem(TOKEN_STORAGE_KEY.AUTH, auth)
  } else {
    sessionStorage.setItem(TOKEN_STORAGE_KEY.AUTH, auth)
  }
}

export const removeTokens = () => {
  localStorage.removeItem(TOKEN_STORAGE_KEY.AUTH)
  localStorage.removeItem(TOKEN_STORAGE_KEY.REFRESH)
  sessionStorage.removeItem(TOKEN_STORAGE_KEY.AUTH)
  sessionStorage.removeItem(TOKEN_STORAGE_KEY.REFRESH)
}

export function handleQueryAuthError(
  error: ApolloError,
  notify: IMessageContext,
  logout: () => void,
  intl: IntlShape,
) {
  // Expired token is handled via Apollo Link
  if (error.graphQLErrors.some(isJwtError)) {
    logout()
    notify({
      status: 'error',
      text: intl.formatMessage(commonMessages.somethingWentWrong),
    })
  } else if (
    !error.graphQLErrors.every(
      (err) => (err.extensions?.exception as any)?.code === 'PermissionDenied',
    )
  ) {
    notify({
      status: 'error',
      text: intl.formatMessage(commonMessages.somethingWentWrong),
    })
  }
}
