import CheckIcon from '@mui/icons-material/Check'
import { Button as MuiButton, CircularProgress, styled } from '@mui/material'

export const Button = styled(MuiButton, {
  shouldForwardProp: (propName: PropertyKey) =>
    propName !== 'success' && propName !== 'error',
})<{ success: boolean; error: boolean }>(({ success, error, theme }) => ({
  '&:hover': {
    backgroundColor: success
      ? theme.palette.primary.main
      : error
      ? theme.palette.error.main
      : undefined,
  },
  backgroundColor: success
    ? theme.palette.primary.main
    : error
    ? theme.palette.error.main
    : undefined,
  color: success
    ? theme.palette.primary.contrastText
    : error
    ? theme.palette.error.contrastText
    : undefined,
}))

export const Progress = styled(CircularProgress, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'invisible',
})<{ invisible: boolean }>(({ theme, invisible }) => ({
  '& svg': {
    color: theme.palette.common.white,
    margin: 0,
  },
  position: 'absolute',
  transitionDuration: theme.transitions.duration.standard + 'ms',
  ...(invisible ? { opacity: 0 } : {}),
}))

export const IconCheck = styled(CheckIcon, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'invisible',
})<{ invisible: boolean }>(({ theme, invisible }) => ({
  marginLeft: '0 !important',
  position: 'absolute',
  transitionDuration: theme.transitions.duration.standard + 'ms',

  ...(invisible ? { opacity: 0 } : {}),
}))

export const Label = styled('span', {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'invisible',
})<{ invisible: boolean }>(({ theme, invisible }) => ({
  alignItems: 'center',
  display: 'flex',
  transitionDuration: theme.transitions.duration.standard + 'ms',

  ...(invisible ? { opacity: 0 } : {}),
}))
