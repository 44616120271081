import {
  appLoaderHeight,
  navigationBarHeight,
} from '@components/AppLayout/consts'
import { Chip, LinearProgress, styled } from '@mui/material'

export const Root = styled('div')(({ theme }) => ({
  isolation: 'isolate',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  width: `100%`,
}))

export const Content = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: 0,
  },
  flex: 1,
}))

export const AppLoader = styled(LinearProgress)(({ theme }) => ({
  height: appLoaderHeight,
  marginBottom: theme.spacing(2),
  zIndex: 1201,
}))

export const AppLoaderPlaceholder = styled('div')(({ theme }) => ({
  height: appLoaderHeight,
  marginBottom: theme.spacing(2),
}))

export const ViewContainer = styled('div')(({ theme }) => ({
  minHeight: `calc(100vh - ${
    appLoaderHeight + navigationBarHeight
  }px - ${theme.spacing(2)})`,
}))

export const HeaderWrapper = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  minHeight: 54,
}))
export const Header = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'grid',
  gridGap: theme.spacing(3),
  gridTemplateAreas: `"headerBacklink headerContent headerMenu"`,
  gridTemplateColumns: 'auto 1fr auto',
  [theme.breakpoints.down('sm')]: {
    gridGap: theme.spacing(2),
    gridTemplateAreas: `"headerDrawerMenu headerBacklink headerMenu" 
    "headerContent headerContent headerContent"`,
  },

  marginBottom: theme.spacing(2),
  minHeight: 36,
}))

export const MainView = styled('main')({
  marginLeft: 0,
})

export const AppAction = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    left: 0,
    width: '100%',
  },
  bottom: 0,
  gridColumn: 2,
  position: 'sticky',
  zIndex: 10,
}))

export const SideBar = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
  background: theme.palette.background.paper,
  padding: `0 ${theme.spacing(4)}px`,
}))

export const UserChip = styled(Chip)(({ theme }) => ({
  '& .MuiAvatar-root': {
    height: 32,
    width: 32,
  },
  backgroundColor: theme.palette.background.paper,
  borderRadius: 24,
  color: theme.palette.text.primary,
  height: 40,
  padding: theme.spacing(0.5),
}))

export const UserBar = styled('div')({
  alignItems: 'center',
  display: 'flex',
})
