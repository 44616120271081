import useServerFormHooks from '@hooks/userServerFormHooks'
import { GraphQLFormattedError } from 'graphql'
import { useForm as useFormHook } from 'react-hook-form'
import {
  FieldValues,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form/dist/types'

type FormProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TScopeCode extends Record<string, any> = FieldValues,
> = UseFormProps<TFieldValues, TContext> &
  Partial<{
    errors: readonly GraphQLFormattedError[]
    errorCodes: TScopeCode
  }>

function parseProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TScopeCode extends Record<string, any> = FieldValues,
>(
  props?: FormProps<TFieldValues, TContext, TScopeCode>,
): {
  formHookProps: UseFormProps<TFieldValues, TContext> | undefined
  errors: readonly GraphQLFormattedError[] | undefined
  errorCodes: TScopeCode | undefined
} {
  if (!props) {
    return {
      errorCodes: undefined,
      errors: undefined,
      formHookProps: undefined,
    }
  }

  const { errors, errorCodes, ...formHookProps } = props
  return {
    errorCodes,
    errors,
    formHookProps,
  }
}

function useForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
  TScopeCode extends Record<string, any> = FieldValues,
>(
  props?: FormProps<TFieldValues, TContext, TScopeCode>,
): UseFormReturn<TFieldValues, TContext> {
  const { errors, errorCodes, formHookProps } = parseProps(props)
  const formMethods = useFormHook(formHookProps)
  useServerFormHooks(errorCodes ?? null, errors ?? null, formMethods)
  return formMethods
}

export default useForm
