import { TrackerMethods, TrackerPermission, UserData } from './types'

type ErrorTrackerFactory = (
  ExtensionFactory: TrackerMethods,
  permissions?: TrackerPermission[],
) => TrackerMethods

// eslint-disable-next-line no-redeclare
export const ErrorTrackerFactory: ErrorTrackerFactory = (
  extension,
  permissions = [],
) => {
  let isEnabled = false

  const safelyInvoke = <T extends () => any>(
    fn: T,
    permission?: TrackerPermission,
  ): ReturnType<T> | undefined => {
    const hasPermission =
      permission !== undefined ? permissions.includes(permission) : true

    if (isEnabled && hasPermission) {
      try {
        return fn()
      } catch (e) {
        throw new Error(`Tracking Extension Error: ${e}`)
      }
    }
  }

  const init: TrackerMethods['init'] = () => {
    if (!isEnabled) {
      isEnabled = extension.init()
    }

    return isEnabled
  }

  const setUserData: TrackerMethods['setUserData'] = (userData: UserData) =>
    safelyInvoke(
      () => extension.setUserData(userData),
      TrackerPermission.USER_DATA,
    )

  const captureException: TrackerMethods['captureException'] = (e: Error) =>
    safelyInvoke(() => extension.captureException(e))

  return {
    captureException,
    init,
    setUserData,
  }
}
