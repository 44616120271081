import { Permission } from '@dolpheen/apollo'
import { IntlShape } from 'react-intl'

import { createConfigurationMenu } from './'

export const getConfigMenuItemsPermissions = (intl: IntlShape): Permission[] =>
  createConfigurationMenu(intl)
    .reduce(
      (prev, { menuItems }) => [
        ...prev,
        ...menuItems.map(({ permissions }) => permissions),
      ],
      [],
    )
    .flat()
