import UserChip from '@components/UserChip'
import useTheme from '@hooks/useTheme'
import useUser from '@hooks/useUser'
import { Logo } from '@icons/Logo'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import MenuIcon from '@mui/icons-material/Menu'
import { Drawer } from '@mui/material'
import Typography from '@mui/material/Typography'
import React from 'react'

import { BaseSidebarProps, SidebarMenuItem } from '../Sidebar/types'
import { SquareButton } from '../SquareButton'
import { MenuItemBtn } from './MenuItemBtn'
import * as S from './styles'

export type SideBarDrawerProps = BaseSidebarProps

export const SidebarDrawer: React.FC<SideBarDrawerProps> = ({
  menuItems,
  linkComponent,
  logoHref,
}) => {
  const [isOpened, setOpened] = React.useState(false)
  const [activeMenu, setActiveMenu] = React.useState<SidebarMenuItem | null>(
    null,
  )
  const [isShowingSubmenu, setShowingSubmenu] = React.useState(false)
  const container = React.useRef<HTMLDivElement>(null)

  const handleMenuItemClick = () => {
    setOpened(false)
    setShowingSubmenu(false)
  }

  const handleMenuItemWithChildrenClick = (menuItem: SidebarMenuItem) => {
    setActiveMenu(menuItem)
    setShowingSubmenu(true)
    container.current?.scrollTo({
      top: 0,
    })
  }

  const Link = S.StyledLogoLink(linkComponent ?? 'a')

  const { themeType, setTheme } = useTheme()

  const { logout, user } = useUser()

  return (
    <>
      <S.SquareButton onClick={() => setOpened(true)}>
        <MenuIcon />
      </S.SquareButton>
      <Drawer
        open={isOpened}
        onClose={() => setOpened(false)}
        PaperProps={{
          sx: {
            paddingBottom: 0,
          },
        }}
      >
        <S.Container shouldShowSubmenu={isShowingSubmenu} ref={container}>
          <S.InnerContainer secondaryContentActive={isShowingSubmenu}>
            <S.Content>
              <Link href={logoHref}>
                <Logo />
              </Link>
              {menuItems.map((menuItem) => (
                <MenuItemBtn
                  menuItem={menuItem}
                  onClick={
                    menuItem.children
                      ? () => handleMenuItemWithChildrenClick(menuItem)
                      : handleMenuItemClick
                  }
                  linkComponent={linkComponent}
                  key={menuItem.ariaLabel}
                />
              ))}
            </S.Content>
            {activeMenu && (
              <S.Content>
                <S.SubMenuTopBar>
                  <S.ActiveMenuLabel>
                    {activeMenu.icon}
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {activeMenu.label}
                    </Typography>
                  </S.ActiveMenuLabel>
                  <SquareButton onClick={() => setShowingSubmenu(false)}>
                    <ArrowLeftIcon />
                  </SquareButton>
                </S.SubMenuTopBar>
                {activeMenu.children?.map((subMenuItem) => {
                  if (subMenuItem.url || subMenuItem.children) {
                    return (
                      <MenuItemBtn
                        menuItem={subMenuItem}
                        onClick={handleMenuItemClick}
                        key={subMenuItem.ariaLabel}
                        linkComponent={linkComponent}
                      />
                    )
                  }

                  return (
                    <S.SubMenuHeader variant="caption">
                      {subMenuItem.label}
                    </S.SubMenuHeader>
                  )
                })}
              </S.Content>
            )}
          </S.InnerContainer>
          <UserChip
            themeType={themeType}
            user={user!}
            isShrunk={false}
            onLogout={logout!}
            onThemeToggle={() =>
              setTheme(themeType === 'dark' ? 'light' : 'dark')
            }
          />
        </S.Container>
      </Drawer>
    </>
  )
}

SidebarDrawer.displayName = 'SideBarDrawer'
