import { isMenuActive } from '@components/AppLayout/utils'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import * as S from './MenuItem.styles'
import { CustomLinkComponent, SidebarMenuItem } from './types'
import { getLinkProps } from './utils'

export interface MenuItemProps {
  isMenuShrunk: boolean
  setMenuShrunk: (shrunk: boolean) => void
  menuItem: SidebarMenuItem
  linkComponent: CustomLinkComponent
}

export const MenuItem: React.FC<MenuItemProps> = ({
  menuItem,
  isMenuShrunk,
  setMenuShrunk,
  linkComponent,
}) => {
  const location = useLocation()
  const [isOpen, setIsOpen] = React.useState(false)
  const anchor = React.useRef<any>(null)

  const isActive = menuItem.children
    ? menuItem.children.some((subItem) =>
        isMenuActive(location.pathname, subItem),
      )
    : isMenuActive(location.pathname, menuItem)

  useEffect(() => {
    setIsOpen(false)
  }, [isActive])

  const handleClick = (event: React.MouseEvent, menuItem: SidebarMenuItem) => {
    event.stopPropagation()
    if (menuItem.children) {
      // Always open clicked menu if menu is shrunk, otherwise toggle
      setIsOpen((o) => (isMenuShrunk ? true : !o))
      // Always extend menu when has multiple items
      setMenuShrunk(false)
    } else {
      menuItem.onClick?.()
      setIsOpen(false)
    }
  }

  const RootNavComponent = S.StyledRootNavComponent(
    menuItem.children ? 'div' : linkComponent ?? 'div',
  )

  return (
    <>
      <RootNavComponent
        ref={anchor}
        isActive={menuItem.children && !isMenuShrunk ? false : isActive}
        isExtendable={!!menuItem.children?.length}
        onClick={(event: React.MouseEvent) => handleClick(event, menuItem)}
        {...getLinkProps(menuItem)}
      >
        <S.MenuItemBtn data-test="menu-item-label" data-test-id={menuItem.id}>
          {menuItem.icon}
          <S.ExtendedLabel shouldHideLabel={isMenuShrunk}>
            <S.Label aria-label={menuItem.ariaLabel}>{menuItem.label}</S.Label>
            {menuItem.children && <ExpandMoreIcon />}
          </S.ExtendedLabel>
        </S.MenuItemBtn>
      </RootNavComponent>
      {menuItem.children && (
        <S.SubMenuListWrapper isOpen={(isOpen || isActive) && !isMenuShrunk}>
          <S.SubMenuList>
            {menuItem.children?.map((subMenuItem) => (
              <MenuItem
                isMenuShrunk={isMenuShrunk}
                menuItem={subMenuItem}
                key={subMenuItem.ariaLabel}
                linkComponent={linkComponent}
                setMenuShrunk={setMenuShrunk}
              />
            ))}
          </S.SubMenuList>
        </S.SubMenuListWrapper>
      )}
    </>
  )
}

MenuItem.displayName = 'MenuItem'
