import { sectionNames } from '@common/intl'
import Container from '@components/Container'
import { NavigationCard } from '@components/NavigationCard'
import PageHeader from '@components/PageHeader'
import { Permission, UserFragment } from '@dolpheen/apollo'
import { hasAnyPermissions } from '@dolpheen/auth/permission'
import { IconProps, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

import * as S from './styles'

export interface MenuItem {
  description: string
  icon: React.ReactElement<IconProps>
  permissions: Permission[]
  title: string
  url: string
  testId: string
}

export interface MenuSection {
  label: string
  menuItems: MenuItem[]
}

export interface ConfigurationPageProps {
  menu: MenuSection[]
  user: UserFragment
}

export const ConfigurationPage: React.FC<ConfigurationPageProps> = ({
  menu: menus,
  user,
}) => {
  const intl = useIntl()
  return (
    <Container>
      <PageHeader title={intl.formatMessage(sectionNames.configuration)} />
      {menus
        .filter((menu) =>
          menu.menuItems.some((menuItem) =>
            hasAnyPermissions(menuItem.permissions, user),
          ),
        )
        .map((menu, menuIndex) => (
          <S.ConfigurationCategory key={menuIndex}>
            <S.ConfigurationLabel>
              <Typography>{menu.label}</Typography>
            </S.ConfigurationLabel>
            <S.ConfigurationItem>
              {menu.menuItems
                .filter((menuItem) =>
                  hasAnyPermissions(menuItem.permissions, user),
                )
                .map((item, itemIndex) => (
                  <S.ItemLink to={item.url} key={itemIndex}>
                    <NavigationCard
                      icon={item.icon}
                      title={item.title}
                      description={item.description}
                      data-test-id={
                        item.testId +
                        '-settings-subsection-' +
                        item.title.toLowerCase()
                      }
                    />
                  </S.ItemLink>
                ))}
            </S.ConfigurationItem>
          </S.ConfigurationCategory>
        ))}
    </Container>
  )
}
ConfigurationPage.displayName = 'ConfigurationPage'
export default ConfigurationPage
