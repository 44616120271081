import { buttonMessages } from '@common/intl'
import AddressEdit from '@components/AddressEdit'
import ConfirmButton, {
  ConfirmButtonTransitionState,
} from '@components/ConfirmButton'
import { Form } from '@components/Form'
import { FormSpacer } from '@components/FormSpacer'
import FormTextField from '@components/FormTextField'
import Skeleton from '@components/Skeleton'
import {
  CaseDetailQuery,
  CaseScopeCode,
  CustomerAddressFragment,
} from '@dolpheen/apollo'
import { FORM_OPTION_VALUE_NEW } from '@dolpheen/cases/constants'
import ContactOption from '@dolpheen/components/ContactOption'
import useForm from '@hooks/useForm'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material'
import { GraphQLFormattedError } from 'graphql'
import React, { ReactNode, useEffect, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

export function formatAddress(option: CustomerAddressFragment | undefined) {
  if (!option) {
    return undefined
  }

  return [
    option.streetAddress1,
    option.streetAddress2,
    [option.city, option.cityArea].filter((i) => !!i).join(' - '),
    option.postalCode,
    option.countryArea?.name,
    option.country?.name,
  ]
    .map((i) => i?.trim())
    .filter(Boolean)
    .join(', ')
}

export interface FormData {
  newAddress: {
    city: string
    cityArea?: string
    country: string | null
    countryArea?: string
    isDefault: boolean
    isPermanentResidency: boolean
    note: string
    postalCode: string
    streetAddress1: string
    streetAddress2?: string
  }
  selectedAddress: string
}

export interface CaseAddressUpdateDialogProps {
  confirmButtonState: ConfirmButtonTransitionState
  contactAddresses: readonly CustomerAddressFragment[] | undefined
  countries: CaseDetailQuery['countries'] | undefined
  initial: CustomerAddressFragment | undefined | null
  errors: readonly GraphQLFormattedError[]
  open: boolean
  onClose: () => void
  onSubmit: (data: FormData) => void
}

const CaseAddressUpdateDialog: React.FC<CaseAddressUpdateDialogProps> = ({
  confirmButtonState,
  contactAddresses,
  countries,
  initial,
  errors,
  open,
  onClose,
  onSubmit,
}) => {
  const intl = useIntl()

  const options: Array<{ value: string; label: ReactNode }> = useMemo(
    () => [
      ...(contactAddresses?.map((address) => ({
        label: (
          <ContactOption
            label={formatAddress(address)!}
            note={address.note}
            isDefault={address.isDefault}
          />
        ),
        value: address.id,
      })) ?? []),
      {
        label: intl.formatMessage({
          defaultMessage: 'New address',
          description: 'case address update dialog',
        }),
        value: FORM_OPTION_VALUE_NEW,
      },
    ],
    [contactAddresses],
  )

  const defaultValues: FormData = useMemo(
    () => ({
      newAddress: {
        city: '',
        cityArea: '',
        country: countries?.find((i) => i.isDefault)?.code ?? null,
        countryArea: '',
        isDefault: false,
        isPermanentResidency: false,
        note: '',
        postalCode: '',
        streetAddress1: '',
        streetAddress2: '',
      },
      selectedAddress: initial?.id ?? '',
    }),
    [initial, countries],
  )

  const formMethods = useForm({
    defaultValues,
    errorCodes: CaseScopeCode,
    errors,
  })

  const { watch, reset, setValue, setFocus } = formMethods

  useEffect(() => {
    reset(defaultValues)
  }, [reset, defaultValues])

  const isNewSelected = watch('selectedAddress') === FORM_OPTION_VALUE_NEW
  useEffect(() => {
    if (!isNewSelected) {
      const defaultCountry = countries?.find((c) => c.isDefault)

      setValue('newAddress.city', '')
      setValue('newAddress.cityArea', '')
      setValue(
        'newAddress.country',
        defaultCountry ? defaultCountry.code : null,
      )
      setValue('newAddress.countryArea', '')
      setValue('newAddress.isDefault', false)
      setValue('newAddress.note', '')
      setValue('newAddress.postalCode', '')
      setValue('newAddress.streetAddress1', '')
      setValue('newAddress.streetAddress2', '')
    } else {
      setFocus('newAddress.streetAddress1')
    }
  }, [isNewSelected])

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
      <Form form={formMethods} onSubmit={onSubmit}>
        <>
          <DialogTitle>
            <FormattedMessage
              defaultMessage="Change contact address"
              description="dialog header"
            />
          </DialogTitle>
          <DialogContent>
            <div>
              {initial !== undefined && contactAddresses !== undefined ? (
                <FormTextField
                  select
                  fullWidth
                  label={intl.formatMessage({
                    defaultMessage: 'Address',
                    description: 'case address update dialog address label',
                  })}
                  name="selectedAddress"
                >
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </FormTextField>
              ) : (
                <Skeleton />
              )}
            </div>
            {isNewSelected ? (
              <>
                <FormSpacer />
                <AddressEdit
                  countries={countries ?? []}
                  countryAreasLookup={(code) =>
                    countries?.find((c) => c.code === code)?.areas ?? []
                  }
                  errors={[]}
                  disabled={!isNewSelected}
                  inputPrefix="newAddress."
                />
              </>
            ) : null}
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={onClose}>
              <FormattedMessage {...buttonMessages.back} />
            </Button>
            <ConfirmButton
              transitionState={confirmButtonState}
              color="primary"
              variant="contained"
              type="submit"
            >
              <FormattedMessage {...buttonMessages.confirm} />
            </ConfirmButton>
          </DialogActions>
        </>
      </Form>
    </Dialog>
  )
}
CaseAddressUpdateDialog.displayName = 'CaseAddressUpdateDialog'
export default CaseAddressUpdateDialog
