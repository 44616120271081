import { SidebarMenuItem } from '@components/Sidebar'
import { matchPath } from 'react-router'

export function isMenuActive(
  location: string,
  menuItem: SidebarMenuItem,
): boolean {
  const activeUrl = location.split('?')[0]
  if (menuItem.activeMatchUrls) {
    for (const activeMatchUrl of menuItem.activeMatchUrls) {
      const menuActiveMatchUrl = activeMatchUrl.split('?')[0]
      const isMatch = !!matchPath(menuActiveMatchUrl + '/*', activeUrl)
      if (isMatch) {
        return isMatch
      }
    }
  }

  if (!menuItem.url) {
    return false
  }

  const menuItemUrl = menuItem.url.split('?')[0]
  return !!matchPath(menuItemUrl + '/*', activeUrl)
}
