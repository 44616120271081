import { SquareButton as AppSquareButton } from '@components/SquareButton'
import { AppTheme } from '@components/Theme/createTheme'
import { styled, Typography } from '@mui/material'

export const StyledLinkComponent = (component: any) =>
  styled(component)(({ theme }) => ({
    '& svg': {
      marginRight: theme.spacing(2),
    },

    alignItems: 'center',
    background: 'none',
    border: 'none',
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    display: 'flex',
    marginBottom: theme.spacing(3),
    padding: 0,
    textDecoration: 'none',
  }))

export const StyledLogoLink = (component: any) =>
  styled(component)(({ theme }) => ({
    color: 'inherit',
    display: 'block',
    marginBottom: theme.spacing(4),
  }))

export const Content = styled('div')({
  width: '50%',
})

export const Container = styled('div', {
  shouldForwardProp: (propName: PropertyKey) =>
    propName !== 'shouldShowSubmenu',
})<{
  shouldShowSubmenu: boolean
}>(({ shouldShowSubmenu }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflowX: 'hidden',
  width: '100%',

  ...(shouldShowSubmenu
    ? {
        overflow: 'hidden',
      }
    : {}),
}))

export const InnerContainer = styled('div', {
  shouldForwardProp: (propName: PropertyKey) =>
    propName !== 'secondaryContentActive',
})<{
  secondaryContentActive: boolean
}>(({ theme, secondaryContentActive }) => ({
  display: 'flex',
  flexGrow: 1,
  position: 'relative',
  right: 0,
  transition: theme.transitions.duration.short + 'ms',
  width: '200%',

  ...(secondaryContentActive
    ? {
        right: '100%',
      }
    : {}),
}))

export const SubMenuTopBar = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(3),
}))

export const ActiveMenuLabel = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
}))

export const SubMenuHeader = styled(Typography)(
  ({ theme }: { theme: AppTheme }) => ({
    color: theme.palette.textHint,
    padding: theme.spacing(1, 0, 2.5, 0),
    textTransform: 'uppercase',
  }),
)

export const SquareButton = styled(AppSquareButton)({
  alignSelf: 'flex-start',
  gridArea: 'headerDrawerMenu',
})
