import {
  ActiveTab,
  Dialog,
  Pagination,
  SingleAction,
  Sort,
  TabActionDialog,
} from '@common/types'
import { stringify as stringifyQs } from 'qs'
import urlJoin from 'url-join'

export const caseSettingsSection = '/case-settings/'

export const caseActionTypeListPath = 'action-types'
export const caseActionResultListPath = 'action-results'
export const caseCloseReasonListPath = 'close-reasons'
export const serviceTypeListPath = 'service-types'

export enum CaseSettingsUrlSortField {
  active = 'active',
  title = 'title',
}

export type CaseSettingsUrlDialog = 'cancel' | 'new' | TabActionDialog

export type CaseSettingsUrlSort = Sort<CaseSettingsUrlSortField>
export type CaseSettingsUrlQueryParams = Dialog<CaseSettingsUrlDialog> &
  CaseSettingsUrlSort &
  Pagination &
  ActiveTab

export const caseSettingsListUrl =
  (typePath: string) =>
  (params?: CaseSettingsUrlQueryParams): string => {
    const caseList = urlJoin(caseSettingsSection, typePath)
    if (params === undefined) {
      return caseList
    } else {
      return urlJoin(caseList, '?' + stringifyQs(params))
    }
  }

export const caseActionTypeDetailPath = 'action-type'
export const caseActionResultDetailPath = 'action-result'
export const caseCloseReasonDetailPath = 'close-reason'
export const serviceTypeDetailPath = 'service-type'

export const caseSettingsPath = (typePath: string, id: string) =>
  urlJoin(caseSettingsSection, typePath, id)

export type CaseSettingsItemDetailUrlDialog = 'cancel' | 'delete'

export type CaseSettingsItemDetailUrlQueryParams =
  Dialog<CaseSettingsItemDetailUrlDialog> & SingleAction

export const caseSettingsDetailsUrl =
  (typePath: string) =>
  (id: string, params?: CaseSettingsItemDetailUrlQueryParams) =>
    caseSettingsPath(typePath, encodeURIComponent(id)) +
    '?' +
    stringifyQs(params)
