import gql from 'graphql-tag'

export const tokenAuthMutation = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenCreate(email: $email, password: $password) {
      accessToken
      refreshToken
      user {
        ...User
      }
    }
  }
`

export const tokenVerifyMutation = gql`
  mutation VerifyToken($token: String!) {
    tokenVerifyUser(token: $token) {
      user {
        ...User
      }
    }
  }
`

export const tokenRefreshMutation = gql`
  mutation RefreshToken($token: String!) {
    tokenRefresh(refreshToken: $token) {
      token
    }
  }
`

export const requestPasswordResetMutation = gql`
  mutation RequestPasswordReset($email: String!, $redirectUrl: String!) {
    requestPasswordReset(email: $email, redirectUrl: $redirectUrl)
  }
`

export const requestedPasswordSetMutation = gql`
  mutation RequestedPasswordSet(
    $email: String!
    $password: String!
    $token: String!
  ) {
    requestedPasswordSet(email: $email, password: $password, token: $token) {
      refreshToken
      accessToken
      user {
        ...User
      }
    }
  }
`

export const confirmAccountMutation = gql`
  mutation ConfirmAccount(
    $email: String!
    $password: String!
    $token: String!
  ) {
    confirmAccount(email: $email, password: $password, token: $token) {
      refreshToken
      accessToken
      user {
        ...User
      }
    }
  }
`
