import { FormCheckbox } from '@components/FormCheckbox'
import FormSpacer from '@components/FormSpacer'
import { FormTextField } from '@components/FormTextField'
import { MenuItem, Typography } from '@mui/material'
import { GraphQLFormattedError } from 'graphql'
import { get, orderBy } from 'lodash-es'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

import * as S from './styles'

function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397),
        )
    : isoCode
}

interface CountryAreaOption {
  id: string
  name: string
}

interface CountryOption {
  code: string
  name: string
  isDefault?: boolean
}

interface AddressEditProps {
  countries: CountryOption[]
  countryAreasLookup: (countryCode: string) => CountryAreaOption[]
  inputPrefix?: string
  disabled?: boolean
  errors: readonly GraphQLFormattedError[]
}

const AddressEdit: React.FC<AddressEditProps> = ({
  countries,
  countryAreasLookup,
  inputPrefix,
  disabled,
}) => {
  const intl = useIntl()

  const inputName = useCallback(
    (name: string) => `${inputPrefix ?? ''}${name}`,
    [inputPrefix],
  )

  const sortedCountries = useMemo(
    () => orderBy(countries, ['isDefault', 'name'], ['desc', 'asc']),
    [countries],
  )

  const form = useFormContext()

  const [countryAreas, setCountryAreas] = useState<CountryAreaOption[]>(
    countryAreasLookup(form.getValues(inputName('country'))),
  )

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (name === inputName('country')) {
        const countryValue = get(value, name)
        const areas = countryAreasLookup(countryValue)
        form.setValue(inputName('countryArea'), undefined)
        form.clearErrors(inputName('countryArea'))
        setCountryAreas(areas)
      }
    })
    return () => subscription.unsubscribe()
  }, [form.watch, inputName, countryAreasLookup])

  return (
    <>
      <FormTextField
        disabled={disabled}
        label={intl.formatMessage({
          defaultMessage: 'Address line 1',
        })}
        name={inputName('streetAddress1')}
        fullWidth
        required
      />
      <FormSpacer />
      <FormTextField
        disabled={disabled}
        label={intl.formatMessage({
          defaultMessage: 'Address line 2',
        })}
        name={inputName('streetAddress2')}
        fullWidth
      />
      <FormSpacer />
      <S.Row>
        <div>
          <FormTextField
            disabled={disabled}
            label={intl.formatMessage({
              defaultMessage: 'City',
            })}
            name={inputName('city')}
            fullWidth
            required
          />
        </div>
        <div>
          <FormTextField
            disabled={disabled}
            label={intl.formatMessage({
              defaultMessage: 'ZIP / Postal code',
            })}
            name={inputName('postalCode')}
            fullWidth
          />
        </div>
      </S.Row>

      <FormSpacer />
      <S.Row>
        <div>
          <FormTextField
            select
            fullWidth
            disabled={disabled}
            label={intl.formatMessage({
              defaultMessage: 'Country',
            })}
            name={inputName('country')}
            required
          >
            {sortedCountries.map((option) => (
              <MenuItem key={option.code} value={option.code}>
                <span>{countryToFlag(option.code)}</span>&nbsp;
                <Typography component="span">{option.name}</Typography>
              </MenuItem>
            ))}
          </FormTextField>
        </div>
        <div>
          <FormTextField
            select
            fullWidth
            disabled={disabled || !countryAreas.length}
            label={intl.formatMessage({
              defaultMessage: 'Country area',
            })}
            name={inputName('countryArea')}
            required={(value) => countryAreas.length > 0 && !value}
          >
            {countryAreas.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </FormTextField>
        </div>
      </S.Row>
      <FormSpacer />
      <S.Row>
        <div>
          <FormTextField
            disabled={disabled}
            label={intl.formatMessage({
              defaultMessage: 'Note',
            })}
            name={inputName('note')}
            fullWidth
          />
        </div>
      </S.Row>
      <FormSpacer />
      <S.Row>
        <div>
          <FormCheckbox
            label={intl.formatMessage({
              defaultMessage: 'Permanent residency',
              description: 'address edit isPermanentResidency label',
            })}
            name={inputName('isPermanentResidency')}
          />
        </div>
        <div>
          <FormCheckbox
            label={intl.formatMessage({
              defaultMessage: 'Set as default',
              description: 'address edit isDefault label',
            })}
            name={inputName('isDefault')}
          />
        </div>
      </S.Row>
    </>
  )
}

AddressEdit.displayName = 'AddressEdit'
export default AddressEdit
