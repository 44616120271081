import React, { CSSProperties, useRef } from 'react'
import { Transition as MessageManagerTransition } from 'react-transition-group'

const duration = 250

const defaultStyle = {
  opacity: 0,
  transition: `opacity ${duration}ms ease`,
}

const transitionStyles: Record<string, CSSProperties> = {
  entered: { opacity: 1 },
  entering: { opacity: 0 },
}

const Transition: React.FC<any> = ({ children, ...props }) => {
  const nodeRef = useRef(null)
  return (
    <MessageManagerTransition {...props} timeout={duration} nodeRef={nodeRef}>
      {(state) => (
        <div
          ref={nodeRef}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </MessageManagerTransition>
  )
}

export default Transition
