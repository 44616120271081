import { ApolloError, MutationFunction, MutationResult } from '@apollo/client'
import {
  MutationResultAdditionalProps,
  PartialMutationProviderOutput,
} from '@common/types'
import { ConfirmButtonTransitionState } from '@components/ConfirmButton'
import {
  AccountScopeCode,
  AuthScopeCode,
  CommonScopeCode,
  Scope,
} from '@dolpheen/apollo'
import { GraphQLFormattedError } from 'graphql/index'

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  { [K in Keys]-?: Required<Pick<T, K>> }[Keys]

export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> &
      Partial<Record<Exclude<Keys, K>, undefined>>
  }[Keys]

export function getMutationState(
  called: boolean,
  loading: boolean,
  error: ApolloError | undefined,
): ConfirmButtonTransitionState {
  if (loading) {
    return 'loading'
  }
  if (called) {
    return error &&
      (error.networkError ||
        !!error.graphQLErrors.length ||
        !!error.clientErrors.length)
      ? 'error'
      : 'success'
  }
  return 'default'
}

export function getMutationStatus(
  opts: MutationResult,
): ConfirmButtonTransitionState {
  return getMutationState(opts.called, opts.loading, opts.error)
}

type ErrorScopeCode = AuthScopeCode | AccountScopeCode | CommonScopeCode
export function apolloHasError(
  error: ApolloError,
  scope: Scope,
  errorCode: ErrorScopeCode,
) {
  return error.graphQLErrors.some((i: GraphQLFormattedError) =>
    isGraphQLErrorCode(i, scope, errorCode),
  )
}

export function isGraphQLErrorCode(
  error: GraphQLFormattedError,
  scope: Scope,
  errorCode: ErrorScopeCode,
) {
  return (
    error.extensions?.scope === scope &&
    error.extensions?.scopeCode === errorCode
  )
}

export function getMutationProviderData<
  TData extends Record<string, any>,
  TVariables extends Record<string, any>,
>(
  mutateFn: MutationFunction<TData, TVariables>,
  opts: MutationResult<TData> & MutationResultAdditionalProps,
): PartialMutationProviderOutput<TData, TVariables> {
  return {
    mutate: (variables) => mutateFn({ variables }),
    opts,
  }
}

interface AnyEvent {
  stopPropagation: () => void
}
export function stopPropagation(cb: (event?: AnyEvent) => void) {
  return (event: AnyEvent) => {
    event.stopPropagation()
    cb(event)
  }
}
